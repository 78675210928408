export enum ExprType {
  Eq = 'eq',
  Gt = 'gt',
  Lt = 'lt',
  Gte = 'gte',
  Lte = 'lte',
  And = 'and',
  Or = 'or',
  Get = 'get',
  isNull = 'is_null',
  isNotNull = 'is_not_null',
}

class Node_ {
  children: Node_[];

  constructor() {
    this.children = [];
  }

  addChild = (node: Node_) => this.children.push(node);

  evaluate = (context: any): any => {
    throw new Error('Missing implementation');
  };
}

class ExprNode extends Node_ {
  expr: string;

  constructor(expr: string) {
    super();

    this.throwIfInvalidExpr(expr);
    this.expr = expr.toLowerCase();
  }

  throwIfInvalidExpr(expr: string) {
    switch (expr.toLowerCase()) {
      case ExprType.Eq:
      case ExprType.Gt:
      case ExprType.Lt:
      case ExprType.Gte:
      case ExprType.Lte:
      case ExprType.And:
      case ExprType.Or:
      case ExprType.Get:
      case ExprType.isNull:
      case ExprType.isNotNull:
        break;
      default:
        throw new Error(`Unexpected expression: ${this.expr}`);
    }
  }

  evaluate = (context: any) => {
    switch (this.expr) {
      case ExprType.Get:
        return this.evaluateAccess(context);
      case ExprType.isNull:
        return this.evaluateIsNull(context);
      case ExprType.isNotNull:
        return this.evaluateIsNotNull(context);
      default:
        return this.evaluateCmp(context);
    }
  };

  getValue = (obj: any, path: any): any => {
    if (!path || !obj) return obj;
    const properties = path.split('.');
    return this.getValue(obj[properties.shift()], properties.join('.'));
  };

  evaluateAccess = (context: any) => {
    this.throwIfInvalidAccessOperands();

    const prop = this.children[0].evaluate(context) as string;

    /*const newContext = context[prop];
    const child = this.children[1];
    if (child) {
      return child.evaluate(newContext);
    } else {
      return newContext;
    }*/

    const child = this.children[1];
    if (child) {
      return child.evaluate(context[prop]);
    } else {
      return this.getValue(context, prop);
    }
  };

  evaluateCmp = (context: any) => {
    this.throwIfInvalidCmpOperands();
    const left = this.children[0].evaluate(context);
    const right = this.children[1].evaluate(context);

    switch (this.expr) {
      case ExprType.Eq:
        return left === right;
      case ExprType.Gt:
        return left > right;
      case ExprType.Gte:
        return left >= right;
      case ExprType.Lt:
        return left < right;
      case ExprType.Lte:
        return left <= right;
      case ExprType.And:
        return left && right;
      case ExprType.Or:
        return left || right;
      default:
        return false;
    }
  };

  evaluateIsNotNull = (context: any) => {
    this.throwIfInvalidOperands(1, ExprType.isNotNull);
    const value = this.children[0].evaluate(context);

    return !!value;
  };

  evaluateIsNull = (context: any) => {
    this.throwIfInvalidOperands(1, ExprType.isNull);
    return !this.evaluateIsNotNull(context);
  };

  throwIfInvalidCmpOperands = () => {
    if (this.children.length !== 2) {
      throw new Error(`Invalid compare operand count ${this.children.length}`);
    }
  };

  throwIfInvalidAccessOperands = () => {
    if (this.children.length !== 1) {
      throw new Error(`Invalid access operand count ${this.children.length}`);
    }
  };

  throwIfInvalidOperands = (operandCount: number, expr: string) => {
    if (this.children.length !== operandCount) {
      throw new Error(
        `Invalid operand count on ${expr}: ${this.children.length}`
      );
    }
  };
}

class ValueNode extends Node_ {
  value: any;

  constructor(value: string) {
    //, str?: boolean) {
    super();

    /*if (str) {
      this.value = value as string;
    } else {
      const num = parseInt(value);
      if (Number.isNaN(num)) {
        throw new Error(`Invalid number: ${value}`);
      }
      this.value = num;
    }*/
    this.value = JSON.parse(value);
  }

  evaluate = (_: any) => {
    return this.value;
  };
}

/*function consumeString(value: string, index: number) {
  const delimiter = value[index++];
  let ret = '';
  while (value[index] !== delimiter) {
    ret += value[index];
    index++;
  }

  return ret;
}*/

function addToParent(nodeStack: Node_[]) {
  if (nodeStack.length > 0) {
    const last = nodeStack.pop() as Node_;
    if (nodeStack.length > 0) {
      const parent = nodeStack.pop() as Node_;
      parent.addChild(last);
      nodeStack.push(parent);
    } else {
      nodeStack.push(last);
    }
  }
}

function tokenize(value: string): Node_ {
  let index = 0;
  const nodeStack = [];
  let token = '';
  while (index < value.length) {
    switch (value[index]) {
      case '(':
        const node = new ExprNode(token);
        nodeStack.push(node);
        token = '';
        break;
      case ')':
        if (token) {
          const node = new ValueNode(token);
          nodeStack.push(node);
          addToParent(nodeStack);
          token = '';
        }
        addToParent(nodeStack);
        break;
      /*case "'":
      case '"':
        const str = consumeString(value, index);
        index += str.length + 1;
        token += str;
        {
          const node = new ValueNode(token, true);
          nodeStack.push(node);
          addToParent(nodeStack);
        }
        token = '';
        break;*/
      case ',':
        if (token) {
          const node = new ValueNode(token);
          nodeStack.push(node);
          addToParent(nodeStack);
          token = '';
        }
        break;
      case ' ':
        break;
      default:
        token += value[index];
    }
    index++;
  }

  return nodeStack[0];
}

const ExpressionHelper = {
  evaluate: function (str: string, context?: any): any {
    const ast = tokenize(str);
    return ast.evaluate(context);
  },
};

export default ExpressionHelper;
