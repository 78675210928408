import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getToken } from 'src/utils/token';
import { useHistory, useParams } from 'react-router-dom';
import {
  APICatalogService,
  APIEntityService,
  APIUserService,
} from 'src/api/api-gc/service';
import { RenderTree } from 'src/interfaces/RenderTree';
import TreeItemsModal from 'src/components/Modal/TreeItemsModal';
import { IconButton } from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import AnotherTreeItemsModal from 'src/components/Modal/AnotherTreeItemsModal';
import {
  CatalogProduct,
  CategoryItem,
  DatecodePattern,
  Entity,
  ListValue,
  Manufacturer,
  User,
} from 'src/api/api-gc/model';
import { TranslationHelper } from 'src/helpers';
import { useTranslation } from 'react-i18next';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import ProductsModal from 'src/components/Modal/ProductsModal';
import {
  ComboBoxComponent,
  OptionType,
  TextFieldComponent,
} from 'src/components/UI';
import GcApiHelper, {
  GetCatalogProductsRequest,
} from 'src/helpers/GcApiHelper';
import UsersModal from 'src/components/Modal/UsersModal';
import CategoryHelper from 'src/helpers/CategoryHelper';
import { Edit } from 'react-feather';
import { ListHelper } from 'src/helpers';
import { ApiContext } from 'src/context';
import MenuHelper from 'src/helpers/MenuHelper';

const useStyles = makeStyles(() => ({
  categorySelector: {
    '& input': {
      textOverflow: 'ellipsis',
      direction: 'rtl',
      textAlign: 'end',
    },
  },
  manufacturerSelector: {
    width: 'calc(100% - 60px)',
    display: 'inline-flex',
    marginRight: '12px',
  },
  ProductSelector: {
    width: 'calc(100% - 60px)',
    marginRight: '12px',
  },
}));

interface EntitiesSelectInterface {
  onChange: (value: number | string | undefined) => void;
  value?: any;
  accessorField?: string;
  label: string;
  list?: Entity[];
  size?: 'small' | 'medium';
  name?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  parentId?: string;
  disabled?: boolean;
  showCode?: boolean;
}

export const EntitiesSelect = ({
  onChange,
  value,
  list,
  size,
  parentId,
  accessorField,
  disabled,
  showCode,
  ...rest
}: EntitiesSelectInterface) => {
  const { orgCode } = useParams<{ orgCode: string }>();
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const [modalOpened, setModalOpened] = useState(false);
  const [componentValue, setComponentValue] = useState(value || undefined);
  const [entitiesTree, setEntitiesTree] = useState<RenderTree[]>([]);

  const getEntitiesTree = (entities: Entity[]): RenderTree[] => {
    const getEntityByParentId = (parentId?: number) => {
      return (entities || []).filter(
        (e) => (e.entityParentId || 0) === parentId
      );
    };

    const buildHierarchy = (entities: Entity[]) => {
      const result: RenderTree[] = (entities || []).map(
        (e: any) =>
          ({
            id: String(e[accessorField || 'entityId']),
            code: e.entityCode,
            label: showCode
              ? `[${e.entityCode}] ${e.entityName}`
              : e.entityName || '',
            //parentId: e.entityParentId,
            children: buildHierarchy(getEntityByParentId(e.entityId)),
          } as RenderTree)
      );

      return result;
    };

    return buildHierarchy(getEntityByParentId(0));
  };

  useEffect(() => {
    if (list === undefined) {
      new APIEntityService()
        .getEntities(getToken(), orgCode, true, undefined, undefined, [
          'MINIMIZE',
        ])
        .then(({ data: { values: entities } }) => {
          if (isMountedRef.current) {
            setEntitiesTree(getEntitiesTree(entities || []));
          }
        });
    } else {
      setEntitiesTree(getEntitiesTree(list || []));
    }
  }, [list]);

  useEffect(() => {
    setComponentValue(value);
  }, [value]);

  const modalOnCancel = () => {
    setModalOpened(false);
  };
  const modalOnSubmit = (items: RenderTree[]) => {
    setModalOpened(false);

    if (items && items.length > 0) {
      onChange(items[0].id);
      setComponentValue(items[0].id);
    } else {
      onChange(undefined);
      setComponentValue(undefined);
    }
  };

  const getValueLabel = useCallback(() => {
    const getValue = (
      items: RenderTree[],
      value: string
    ): RenderTree | undefined => {
      if (value && items) {
        for (const item of items) {
          if (item.id === value) {
            return item;
          } else if (item.children) {
            const result = getValue(item.children, value);

            if (result !== undefined) {
              return result;
            }
          }
        }
      }
    };

    const item = getValue(entitiesTree, String(componentValue));

    if (componentValue && !item) {
      return 'ERROR';
    } else {
      return item ? item.label : '';
    }
  }, [entitiesTree, componentValue]);

  return (
    <>
      <TextFieldComponent
        value={getValueLabel()}
        fullWidth
        InputProps={{
          endAdornment: (
            <>
              <IconButton
                onClick={() => {
                  onChange(undefined);
                  setComponentValue(undefined);
                }}
                aria-label="Remove entity"
                edge="end"
                size={size}
                disabled={disabled}>
                <CloseIcon />
              </IconButton>
              <IconButton
                //className={classes.searchButton}
                aria-label="Search entity"
                onClick={() => {
                  setModalOpened(true);
                }}
                edge="end"
                size={size}
                disabled={disabled}>
                <SearchIcon />
              </IconButton>
            </>
          ),
        }}
        size={size}
        disabled={disabled}
        {...rest}
      />
      <TreeItemsModal
        open={modalOpened}
        onCancel={modalOnCancel}
        onContinue={modalOnSubmit}
        list={entitiesTree}
        value={
          componentValue ? [{ id: String(componentValue) } as RenderTree] : []
        }
        parentId={parentId}
        title={t('modals.entitiesSelection.title')}
      />
    </>
  );
};

interface CustomCategoryItem extends CategoryItem {
  parent?: CategoryItem;
}

interface CategorySelectInterface {
  categoryCode?: string;
  onChange: (value?: string | number) => void;
  value?: string | number;
  values?: CategoryItem[];
  error?: boolean;
  helperText?: string;
  label: string;
  size?: 'small' | 'medium';
  accessorField: string;
  labelWithPath?: boolean;
  parentId?: string;
  required?: boolean;
  disabled?: boolean;
}

export const CategorySelect = ({
  categoryCode,
  onChange,
  label,
  value,
  values,
  error,
  helperText,
  size,
  accessorField,
  labelWithPath,
  parentId,
  required,
  disabled,
}: CategorySelectInterface) => {
  const { orgCode } = useParams<{ orgCode: string }>();
  const { t, i18n } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const [openCategoryDialog, setOpenCategoryDialog] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >(value);
  const [items, setItems] = useState<CategoryItem[] | undefined>(undefined);

  const classes = useStyles();

  useEffect(() => {
    if (isMountedRef.current) {
      setSelectedValue(value);
    }
  }, [value]);

  React.useEffect(() => {
    const getLabel = (item: CategoryItem): string => {
      const translatedLabel = TranslationHelper.getTranslation(
        i18n.language,
        item.itemName,
        item.translations
      );
      return `[${item.itemCode}] - ${translatedLabel}`;
    };
    const prepareData = (categoryItems: CategoryItem[]): CategoryItem[] => {
      const result: CategoryItem[] = [];

      for (const item of categoryItems) {
        const newItem: CategoryItem = item;
        // @ts-ignore
        newItem.label = getLabel(item);

        if (item.itemChildrens) {
          newItem.itemChildrens = prepareData(item.itemChildrens);
        }

        result.push(newItem);
      }

      return result;
    };

    if (values) {
      setItems(prepareData(values));
    } else {
      if (categoryCode) {
        CategoryHelper.getCategoryItemsByCatCode(
          getToken(),
          orgCode,
          categoryCode
        ).then((result) => {
          if (isMountedRef.current && result) {
            setItems(prepareData(result.items || []));
          }
        });
      }
    }
  }, [values, categoryCode]);

  const getValue = useCallback((): string => {
    const getItem = (
      items: CustomCategoryItem[],
      parent?: CustomCategoryItem
    ): CustomCategoryItem | undefined => {
      for (const item of items) {
        if (parent) {
          item.parent = parent;
        }
        // eslint-disable-next-line
        if (selectedValue == item[accessorField as keyof CategoryItem]) {
          return item;
        } else {
          if (item.itemChildrens) {
            const i: CategoryItem | undefined = getItem(
              item.itemChildrens,
              item
            );
            if (i) {
              return i;
            }
          }
        }
      }

      return undefined;
    };

    const getPath = (item: CustomCategoryItem, result?: string): string => {
      if (item.parent) {
        return getPath(
          item.parent,
          TranslationHelper.getTranslation(
            i18n.language,
            item.itemName,
            item.translations
          ) + (result ? ' / ' + result : '')
        );
      } else {
        return (
          TranslationHelper.getTranslation(
            i18n.language,
            item.itemName,
            item.translations
          ) + (result ? ' / ' + result : '')
        );
      }
    };

    if (selectedValue) {
      const item = getItem(items || []);
      if (labelWithPath) {
        return item ? getPath(item) : '';
      } else {
        return item
          ? TranslationHelper.getTranslation(
              i18n.language,
              item.itemName,
              item.translations
            )
          : '';
      }
    } else {
      return '';
    }
  }, [selectedValue, items]);

  const handleDialogClose = (value?: string | number) => {
    setOpenCategoryDialog(false);
    setSelectedValue(value);

    onChange(value);
  };

  return (
    <>
      <TextFieldComponent
        label={label}
        size={size}
        fullWidth
        disabled={disabled || !items || items.length === 0}
        InputProps={{
          endAdornment: (
            <>
              <IconButton
                aria-label="Remove selection"
                onClick={() => {
                  setSelectedValue(undefined);
                  onChange(undefined);
                }}
                edge="end"
                size={size}
                disabled={disabled || !items || items.length === 0}>
                <CloseIcon />
              </IconButton>
              <IconButton
                aria-label="Select a category"
                onClick={() => {
                  setOpenCategoryDialog(true);
                }}
                edge="end"
                size={size}
                disabled={disabled || !items || items.length === 0}>
                <SearchIcon />
              </IconButton>
            </>
          ),
        }}
        value={getValue()}
        error={!!error}
        helperText={helperText}
        title={getValue()}
        className={classes.categorySelector}
        required={required}
      />
      <AnotherTreeItemsModal
        onClose={handleDialogClose}
        onCancel={() => {
          setOpenCategoryDialog(false);
        }}
        open={openCategoryDialog}
        accessorField={accessorField}
        childrenField="itemChildrens"
        items={items}
        value={selectedValue}
        parentId={parentId}
        title={t('modals.categoriesSelection.title')}
      />
    </>
  );
};

interface ProductSelectInterface {
  onChange: (value?: string | number) => void;
  value?: string | number;
  error?: boolean;
  helperText?: string;
  label: string;
  size?: 'small' | 'medium';
  accessorField: string;
  parentId?: string;
  type?: 'MODELS' | 'PRODUCTS' | 'PARTS';
  showLink?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export const ProductSelect = ({
  onChange,
  label,
  value,
  error,
  helperText,
  size,
  accessorField,
  parentId,
  type,
  showLink,
  disabled,
  required,
}: ProductSelectInterface) => {
  const { orgCode } = useParams<{ orgCode: string }>();
  const { push } = useHistory();
  const { i18n } = useTranslation();
  const { menu } = useContext(ApiContext);
  const isMountedRef = useIsMountedRef();
  const [openProductsDialog, setOpenProductsDialog] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >(value);
  const [valueLabel, setValueLabel] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (isMountedRef.current) {
      setSelectedValue(value);
    }
  }, [value]);

  const handleDialogClose = (values: CatalogProduct[]) => {
    setOpenProductsDialog(false);
    const result =
      // @ts-ignore
      values && values[0] ? values[0][accessorField || 'productId'] : undefined;
    setSelectedValue(result);
    onChange(result);
  };

  useEffect(() => {
    if (selectedValue) {
      const request: GetCatalogProductsRequest = {
        token: getToken(),
        orgCode: orgCode,
        [accessorField || 'productId']: selectedValue,
      };

      GcApiHelper.getCatalogProducts(request).then(
        ({ data: { values: products } }) => {
          if (products && products.length === 1 && isMountedRef.current) {
            setValueLabel(
              `[${products[0].productCode}] ${TranslationHelper.getTranslation(
                i18n.language,
                products[0].productName,
                products[0].titleTranslations
              )}`
            );
          } else {
            if (isMountedRef.current) {
              setValueLabel('#ERROR#');
            }
          }
        }
      );
    } else {
      if (isMountedRef.current) {
        setValueLabel('');
      }
    }
  }, [selectedValue]);

  const openModel = (value: number) => {
    const pathname = MenuHelper.getPath(
      MenuHelper.getManagerMenu(menu),
      'catalog-products'
    );

    if (value) {
      push(`/${orgCode}/${pathname}/${value}`, value);
    }
  };

  return (
    <>
      <TextFieldComponent
        required={required}
        disabled={disabled}
        label={label}
        size={size}
        fullWidth={!showLink}
        InputProps={{
          endAdornment: (
            <>
              <IconButton
                aria-label="Remove selection"
                onClick={() => {
                  setSelectedValue(undefined);
                  onChange(undefined);
                }}
                edge="end"
                size={size}
                disabled={disabled}>
                <CloseIcon />
              </IconButton>
              <IconButton
                aria-label="Select a category"
                onClick={() => {
                  setOpenProductsDialog(true);
                }}
                edge="end"
                size={size}
                disabled={disabled}>
                <SearchIcon />
              </IconButton>
            </>
          ),
        }}
        value={valueLabel}
        error={!!error}
        helperText={helperText}
        title={valueLabel}
        className={showLink ? classes.ProductSelector : undefined}
      />
      {showLink && (
        <IconButton
          disabled={value === undefined || value === 0}
          onClick={() => {
            openModel(value as number);
          }}
          size="large">
          <Edit />
        </IconButton>
      )}

      <ProductsModal
        onClose={handleDialogClose}
        onCancel={() => {
          setOpenProductsDialog(false);
        }}
        open={openProductsDialog}
        accessorField={accessorField}
        value={selectedValue}
        parentId={parentId}
        type={type}
      />
    </>
  );
};

interface ManufacturerSelectInterface {
  //onChange: (value?: string | number) => void;
  //value?: string | number;
  onChange: (value?: number | string) => void;
  value?: number;
  values?: Manufacturer[];
  error?: boolean;
  helperText?: string;
  label: string;
  size?: 'small' | 'medium';
  accessorField?: string;
  showLink?: boolean;
  required?: boolean;
  showCode?: boolean;
}

export const ManufacturerSelect = ({
  onChange,
  label,
  value,
  values,
  error,
  helperText,
  size,
  accessorField,
  showLink,
  required,
  showCode,
}: ManufacturerSelectInterface) => {
  const { orgCode } = useParams<{ orgCode: string }>();
  const isMountedRef = useIsMountedRef();
  const [items, setItems] = useState<Manufacturer[]>([]);
  const { push } = useHistory();
  const { menu } = useContext(ApiContext);

  const classes = useStyles();

  useEffect(() => {
    if (!values) {
      new APICatalogService()
        .getManufacturers(getToken(), orgCode)
        .then(({ data: { values } }) => {
          if (isMountedRef.current && values) {
            setItems(values || []);
          }
        });
    } else {
      setItems(values);
    }
  }, [values]);

  const openManufacturer = (value: number) => {
    const pathname = MenuHelper.getPath(
      MenuHelper.getManagerMenu(menu),
      'manufacturers'
    );

    if (value) {
      push(`/${orgCode}/${pathname}/${value}`, value);
    }
  };

  return (
    <>
      <ComboBoxComponent
        required={required}
        options={items.map((m) => {
          const opt: OptionType = {
            key: accessorField
              ? (m[accessorField as keyof Manufacturer] as string)
              : (m.manufacturerId as number),
            label: showCode
              ? `[${m.manufacturerCode as string}] ${
                  m.manufacturerName as string
                }`
              : (m.manufacturerName as string),
          };

          return opt;
        })}
        label={label}
        value={value ? value : 0}
        onChange={(event, value) => {
          if (!accessorField || accessorField === 'manufacturerId') {
            const manufacturerId =
              value !== null ? Number(value.key) : undefined;
            onChange(manufacturerId);
          } else {
            onChange(value ? value.key : undefined);
          }
        }}
        error={!!error}
        helperText={helperText}
        size={size}
        className={showLink ? classes.manufacturerSelector : undefined}
      />
      {showLink && (
        <IconButton
          disabled={value === undefined || value === 0}
          onClick={() => {
            openManufacturer(value as number);
          }}
          size="large">
          <Edit />
        </IconButton>
      )}
    </>
  );
};

interface UserSelectInterface {
  onChange: (value?: string | number) => void;
  value?: number;
  error?: boolean;
  helperText?: string;
  label: string;
  size?: 'small' | 'medium';
  parentId?: string;
}

export const UserSelect = ({
  onChange,
  label,
  value,
  error,
  helperText,
  size,
  parentId,
}: UserSelectInterface) => {
  const { orgCode } = useParams<{ orgCode: string }>();
  const isMountedRef = useIsMountedRef();
  const [openUsersDialog, setOpenUsersDialog] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number | undefined>(value);
  const [valueLabel, setValueLabel] = useState<string>('');

  useEffect(() => {
    if (isMountedRef.current) {
      setSelectedValue(value);
    }
  }, [value]);

  const handleDialogClose = (values: User[]) => {
    setOpenUsersDialog(false);
    const result =
      // @ts-ignore
      values && values[0] ? values[0]['userId'] : undefined;
    setSelectedValue(result);
    onChange(result);
  };

  useEffect(() => {
    if (selectedValue) {
      new APIUserService()
        .getUser(getToken(), orgCode, selectedValue)
        .then(({ data: user }) => {
          if (user) {
            setValueLabel(`${user.userFirstName} ${user.userLastName}`);
          } else {
            setValueLabel('#ERROR#');
          }
        });
    } else {
      setValueLabel('');
    }
  }, [selectedValue]);

  return (
    <>
      <TextFieldComponent
        label={label}
        size={size}
        fullWidth
        InputProps={{
          endAdornment: (
            <>
              <IconButton
                aria-label="Remove selection"
                onClick={() => {
                  setSelectedValue(undefined);
                  onChange(undefined);
                }}
                edge="end"
                size={size}>
                <CloseIcon />
              </IconButton>
              <IconButton
                aria-label="Select a user"
                onClick={() => {
                  setOpenUsersDialog(true);
                }}
                edge="end"
                size={size}>
                <SearchIcon />
              </IconButton>
            </>
          ),
        }}
        value={valueLabel}
        error={!!error}
        helperText={helperText}
        title={valueLabel}
      />
      <UsersModal
        onClose={handleDialogClose}
        onCancel={() => {
          setOpenUsersDialog(false);
        }}
        open={openUsersDialog}
        value={selectedValue}
        parentId={parentId}
      />
    </>
  );
};

interface ListSelectInterface {
  listCode?: string;
  listValues?: ListValue[];
  onChange: (e: any) => void;
  label?: string;
  value?: string | number;
  accessorField?: string;
  error?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  showCode?: boolean;
  disabled?: boolean;
  autoSelectDefaultValue?: boolean;
  required?: boolean;
  showCodeInTitle?: boolean;
}

export const ListSelect = ({
  listCode,
  listValues,
  onChange,
  label,
  value,
  accessorField = 'valueCode',
  error,
  helperText,
  size,
  showCode,
  disabled,
  autoSelectDefaultValue,
  required,
  showCodeInTitle,
}: ListSelectInterface) => {
  const { orgCode } = useParams<{ orgCode: string }>();
  const { i18n } = useTranslation();
  const [values, setValues] = useState<ListValue[]>();

  useEffect(() => {
    if (listCode) {
      ListHelper.getListValuesByCode(
        getToken(),
        orgCode,
        listCode as string
      ).then((result) => {
        setValues(result || []);
      });
    } else {
      setValues(listValues || []);
    }
  }, [orgCode, listCode, listValues]);

  const getDefautTypeValue = () => {
    const delay = (time: number) => {
      return new Promise((resolve) => setTimeout(resolve, time));
    };

    if (values) {
      const value = values.filter((m) => m.valueDefault === true);

      if (value && value.length > 0) {
        delay(100).then(() => {
          // @ts-ignore
          onChange(value[0][accessorField]);
        });
      }

      return value && value.length > 0 ? value[0].valueCode : undefined;
    } else {
      return undefined;
    }
  };

  const getTitle = (key: string | number): string | undefined => {
    const value = (values || []).find(
      (v: any) => v[accessorField as keyof ListValue] === key
    );
    if (value) {
      return `[${value.valueCode}] ${TranslationHelper.getTranslation(
        i18n.language,
        value.valueText,
        value.translations
      )}`;
    } else {
      return undefined;
    }
  };

  return (
    <ComboBoxComponent
      options={(values || [])
        .map((v) => {
          const opt: OptionType = {
            key: v[accessorField as keyof ListValue] as string,
            label: `${
              showCode ? `[${v.valueCode}] ` : ''
            }${TranslationHelper.getTranslation(
              i18n.language,
              v.valueText,
              v.translations
            )}`,
          };

          return opt;
        })
        .sort((a, b) => (a.label! < b.label! ? -1 : 1))}
      label={label}
      disabled={disabled}
      value={
        autoSelectDefaultValue && value === undefined
          ? getDefautTypeValue()
          : value
      }
      onChange={(_, value) => {
        onChange(value ? value.key : null);
      }}
      error={!!error}
      helperText={helperText}
      size={size}
      required={required}
      title={showCodeInTitle && value ? getTitle(value) : undefined}
    />
  );
};

interface EnableSelectInterface {
  onChange: (value?: boolean) => void;
  error?: boolean;
  helperText?: string;
  label: string;
  value?: boolean;
  size?: 'small' | 'medium';
}

export const EnableSelect = ({
  onChange,
  label,
  value,
  error,
  helperText,
  size,
}: EnableSelectInterface) => {
  const { t } = useTranslation();
  return (
    <>
      <ComboBoxComponent
        options={[
          {
            key: 0,
            label: t('main.boolean.false'),
          },
          {
            key: 1,
            label: t('main.boolean.true'),
          },
        ]}
        label={label}
        value={value !== undefined ? (value ? 1 : 0) : undefined}
        onChange={(event, value) => {
          onChange(value ? value.key === 1 : undefined);
        }}
        error={!!error}
        helperText={helperText}
        size={size}
      />
    </>
  );
};

interface DatecodePatternSelectInterface {
  onChange: (value?: number | string) => void;
  value?: number;
  values?: Manufacturer[];
  error?: boolean;
  helperText?: string;
  label: string;
  size?: 'small' | 'medium';
  accessorField?: string;
  required?: boolean;
}

export const DatecodePatternSelect = ({
  onChange,
  label,
  value,
  values,
  error,
  helperText,
  size,
  accessorField,
  required,
}: DatecodePatternSelectInterface) => {
  const { orgCode } = useParams<{ orgCode: string }>();
  const isMountedRef = useIsMountedRef();
  const [items, setItems] = useState<DatecodePattern[]>([]);

  useEffect(() => {
    if (!values) {
      new APICatalogService()
        .getDatecodePatterns(getToken(), orgCode)
        .then(({ data: { values } }) => {
          if (isMountedRef.current && values) {
            setItems(values || []);
          }
        });
    } else {
      setItems(values);
    }
  }, [values]);

  return (
    <>
      <ComboBoxComponent
        options={items.map((m) => {
          const opt: OptionType = {
            key: accessorField
              ? (m[accessorField as keyof DatecodePattern] as string)
              : (m.datecodeId as number),
            label: m.datecodeDescription as string,
          };

          return opt;
        })}
        label={label}
        value={value ? value : 0}
        onChange={(event, value) => {
          if (!accessorField || accessorField === 'manufacturerId') {
            const manufacturerId =
              value !== null ? Number(value.key) : undefined;
            onChange(manufacturerId);
          } else {
            onChange(value ? value.key : undefined);
          }
        }}
        error={!!error}
        helperText={helperText}
        size={size}
        required={required}
      />
    </>
  );
};

interface SelectWithTreeViewInterface {
  promise: Promise<any>;
  getResult: (result: any) => any;
  onChange: (value?: string | number) => void;
  value?: string | number;
  error?: boolean;
  helperText?: string;
  label: string;
  size?: 'small' | 'medium';
  accessorField: string;
  childrenField: string;
  labelField: string;
  labelWithPath?: boolean;
  parentId?: string;
  required?: boolean;
  disabled?: boolean;
  dialogTitle?: string;
}

export const SelectWithTreeView = ({
  promise,
  getResult,
  onChange,
  label,
  value,
  error,
  helperText,
  size,
  accessorField,
  childrenField,
  labelField,
  labelWithPath,
  parentId,
  required,
  disabled,
  dialogTitle,
}: SelectWithTreeViewInterface) => {
  const { i18n } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >(value);
  const [items, setItems] = useState<any[] | undefined>(undefined);

  const classes = useStyles();

  useEffect(() => {
    if (isMountedRef.current) {
      setSelectedValue(value);
    }
  }, [value]);

  React.useEffect(() => {
    /*const getLabel = (item: any): string => {
      const translatedLabel = TranslationHelper.getTranslation(
        i18n.language,
        item[labelField],
        item.translations
      );
      return `[${item.itemCode}] - ${translatedLabel}`;
    };*/
    const prepareData = (items: any[]): any[] => {
      const result: any[] = [];

      for (const item of items) {
        const newItem = JSON.parse(JSON.stringify(item));
        //newItem.label = getLabel(item);

        if (
          item[childrenField] &&
          Array.isArray(item[childrenField]) &&
          item[childrenField].length > 0
        ) {
          newItem[childrenField] = prepareData(item[childrenField]);
        } else {
          delete newItem[childrenField];
        }

        result.push(newItem);
      }

      return result;
    };

    /*if (values) {
      setItems(prepareData(values));
    } else {*/
    if (promise && getResult) {
      promise.then((result: any) => {
        if (isMountedRef.current && result) {
          setItems(prepareData(getResult(result) || []));
        }
      });
    }
    /*}*/
  }, []);

  const getValue = useCallback((): string => {
    const getItem = (items: any[], parent?: any): any | undefined => {
      for (const item of items) {
        if (parent) {
          item.parent = parent;
        }
        // eslint-disable-next-line
        if (selectedValue == item[accessorField]) {
          return item;
        } else {
          if (item[childrenField]) {
            const i: any | undefined = getItem(item[childrenField], item);
            if (i) {
              return i;
            }
          }
        }
      }

      return undefined;
    };

    const getPath = (item: any, result?: string): string => {
      if (item.parent) {
        return getPath(
          item.parent,
          TranslationHelper.getTranslation(
            i18n.language,
            item[labelField],
            item.translations
          ) + (result ? ' / ' + result : '')
        );
      } else {
        return (
          TranslationHelper.getTranslation(
            i18n.language,
            item[labelField],
            item.translations
          ) + (result ? ' / ' + result : '')
        );
      }
    };

    if (selectedValue) {
      const item = getItem(items || []);
      if (labelWithPath) {
        return item ? getPath(item) : '';
      } else {
        return item
          ? TranslationHelper.getTranslation(
              i18n.language,
              item[labelField],
              item.translations
            )
          : '';
      }
    } else {
      return '';
    }
  }, [selectedValue, items]);

  const handleDialogClose = (value?: string | number) => {
    setOpenDialog(false);
    setSelectedValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <TextFieldComponent
        label={label}
        size={size}
        fullWidth
        disabled={disabled || !items || items.length === 0}
        InputProps={{
          endAdornment: (
            <>
              <IconButton
                aria-label="Remove selection"
                onClick={() => {
                  setSelectedValue(undefined);
                  if (onChange) {
                    onChange(undefined);
                  }
                }}
                edge="end"
                size={size}
                disabled={disabled || !items || items.length === 0}>
                <CloseIcon />
              </IconButton>
              <IconButton
                aria-label="Select a category"
                onClick={() => {
                  setOpenDialog(true);
                }}
                edge="end"
                size={size}
                disabled={disabled || !items || items.length === 0}>
                <SearchIcon />
              </IconButton>
            </>
          ),
        }}
        value={getValue()}
        error={!!error}
        helperText={helperText}
        title={getValue()}
        className={classes.categorySelector}
        required={required}
      />
      <AnotherTreeItemsModal
        onClose={handleDialogClose}
        onCancel={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        accessorField={accessorField}
        childrenField={childrenField}
        labelField={labelField}
        items={items}
        value={selectedValue}
        parentId={parentId}
        title={dialogTitle || ''}
      />
    </>
  );
};
