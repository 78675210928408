import React, { useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import AnotherTreeView from 'src/components/UI/TreeView/AnotherTreeView';
import ReactDOM from 'react-dom';
import { TextFieldComponent } from 'src/components/UI';
import LoadingComponent from 'src/components/LoadingComponent';

interface AnotherTreeItemsModalProps {
  open: boolean;
  onClose: (value?: string | number) => void;
  onCancel: () => void;
  accessorField: string;
  childrenField: string;
  labelField?: string;
  value?: string | number;
  items?: any[];
  parentId?: string;
  title: string;
}

const AnotherTreeItemsModal = ({
  onClose,
  onCancel,
  open,
  accessorField,
  childrenField,
  labelField,
  value,
  items,
  parentId,
  title,
}: AnotherTreeItemsModalProps) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >(value);
  const [search, setSearch] = useState<string>('');
  const [values, setValues] = useState<any[]>(items || []);

  const content = React.useRef<HTMLDivElement>(null);

  const isOpened = (item: any): boolean => {
    let result = false;
    if (selectedValue === item[accessorField]) {
      result = true;
    } else if (item[childrenField]) {
      for (const i of item[childrenField]) {
        if (isOpened(i)) {
          result = true;
        }
      }
    }

    item.isOpened = result;
    return result;
  };

  useEffect(() => {
    setSearch('');
    setSelectedValue(value);
    for (const item of items || []) {
      isOpened(item);
    }
    setValues(items || []);
  }, [open, items, value]);

  const handleSelect = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: string | number
  ) => {
    setSelectedValue(value);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  var timer: any;
  useEffect(() => {
    const find = (item: any, search: string): any => {
      if (
        item[labelField || 'label'] &&
        (item[labelField || 'label'] as string)
          .toLowerCase()
          .includes(search.toLowerCase())
      ) {
        return item;
      } else if (item[childrenField]) {
        const children = [];
        for (const i of item[childrenField]) {
          const tmp = find(i, search);
          if (tmp !== undefined) {
            children.push(tmp);
          }
        }

        if (children.length > 0) {
          const tmp: any = { ...item };
          tmp.isOpened = true;
          tmp[childrenField] = children;
          return tmp;
        }
      }
    };

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (search === '') {
        setValues(items || []);
      } else {
        setValues(
          (items || [])
            .map((i) => find(i, search))
            .filter((i) => {
              return i !== undefined;
            })
        );
      }
    }, 400);
  }, [search]);

  const scrollTo = () => {
    const elt = ReactDOM.findDOMNode(content.current);
    if (elt) {
      // @ts-ignore
      const selected = elt.getElementsByClassName('Mui-selected')[0];
      if (selected) {
        // @ts-ignore
        elt.scrollTop = selected.offsetTop;
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
      // @ts-ignore
      parentid={parentId}>
      <DialogTitle>{title}</DialogTitle>
      <div
        style={{
          margin: '15px',
          width: 'calc(100% - 30px)',
        }}>
        <TextFieldComponent
          label={'Search'}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          fullWidth
        />
      </div>
      <DialogContent ref={content}>
        <LoadingComponent loading={items === undefined}>
          <AnotherTreeView
            handleSelect={handleSelect}
            items={values}
            value={selectedValue}
            accessorField={accessorField}
            childrenField={childrenField}
            labelField={labelField}
            onLoad={scrollTo}
          />
        </LoadingComponent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('modals.categoriesSelection.buttons.cancel')}
        </Button>
        <Button onClick={handleClose} color="primary">
          {t('modals.categoriesSelection.buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnotherTreeItemsModal;
