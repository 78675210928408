import React, { useCallback } from 'react';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { ValidationStateSchema } from 'src/interfaces';
import ValidationStateSchemaHelper from 'src/helpers/ValidationStateSchemaHelper';

const codeRegEx = /[^a-zA-Z0-9_.]/gi;

export interface TextFieldComponentProps extends StandardTextFieldProps {
  validationSchema?: ValidationStateSchema;
  isCode?: boolean;
}

export const TextFieldComponent: React.FC<TextFieldComponentProps> = ({
  validationSchema,
  required,
  name,
  isCode,
  onChange,
  ...rest
}) => {
  const onFocus = (event: any) => {
    event.target.setAttribute('autocomplete', 'off');
  };

  const handleChangeForCode = useCallback(
    (e) => {
      e.currentTarget.value = e.currentTarget.value.replace(codeRegEx, '');
      e.currentTarget.value = e.currentTarget.value.toUpperCase();
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  return (
    <TextField
      variant="outlined"
      name={name}
      required={
        validationSchema && name
          ? ValidationStateSchemaHelper.isRequired(validationSchema, name)
          : required
      }
      {...rest}
      autoComplete="new-password"
      onFocus={(e) => {
        onFocus(e);
        if (rest.onFocus) {
          rest.onFocus(e);
        }
      }}
      onChange={isCode ? handleChangeForCode : onChange}
    />
  );
};
