import {
  APICatalogService,
  APIInsuranceService,
  APIRulesService,
  APIUserService,
} from 'src/api/api-gc/service';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

const catalogService = new APICatalogService();
const insuranceService = new APIInsuranceService();
const userService = new APIUserService();
const ruleService = new APIRulesService();

export interface GetCatalogProductsRequest {
  token: string;
  orgCode: string;
  limitCount?: number;
  limitOffset?: number;
  mapLevel?: Array<string>;
  enable?: boolean;
  lastCreationDate?: Date;
  categoryCode?: string;
  searchCategoryHierarchy?: boolean;
  manufacturerId?: number;
  manufacturerCode?: string;
  productCode?: string;
  modelCode?: string;
  referenceType?: string;
  referenceCode?: string;
  referenceName?: string;
  isNew?: boolean;
  isSerialized?: boolean;
  isPublished?: boolean;
  isModel?: boolean;
  catalogGroups?: Array<string>;
  productCategoryItemId?: number;
  productPlatform?: string;
  productName?: string;
  productUuid?: string;
  productId?: number;
  isPart?: boolean;
  hierarchyLevel?: number;
  asc?: Array<string>;
  desc?: Array<string>;
}

export interface GetInsuranceEquipmentsRequest {
  token: string;
  orgCode: string;
  limitCount?: number;
  limitOffset?: number;
  mapLevel?: Array<string>;
  enable?: boolean;
  categoryId?: number;
  manufacturerCode?: string;
  productId?: number;
  maxClaimNumber?: number;
  priceMin?: number;
  priceMax?: number;
  maxAge?: number;
  maxItemAllowed?: number;
  registrationMandatory?: boolean;
  maxAmountPerYear?: number;
  maxAmountPerClaim?: number;
  productCode?: string;
  productName?: string;
}

export interface GetCatalogParamsRequest {
  token: string;
  orgCode: string;
  limitCount?: number;
  limitOffset?: number;
  mapLevel?: Array<string>;
  enable?: boolean;
  text?: string;
}

export interface GetUsersRequest {
  token: string;
  orgCode: string;
  enable?: boolean;
  limitCount?: number;
  limitOffset?: number;
  mapLevel?: Array<string>;
  userName?: string;
  userFirstName?: string;
  userLastName?: string;
  userEmail?: string;
  userLang?: string;
  userCountry?: string;
  userLocale?: string;
  userTimezone?: string;
  userReferenceExt?: string;
  userNoRestriction?: boolean;
  userNeedNewPwd?: boolean;
  userNoConnection?: boolean;
  userHttp?: boolean;
  asc?: Array<string>;
  desc?: Array<string>;
}

const GcApiHelper = {
  getCatalogProducts: (request: GetCatalogProductsRequest) => {
    return catalogService.getCatalogProducts(
      request.token,
      request.orgCode,
      request.limitCount,
      request.limitOffset,
      request.mapLevel,
      request.enable,
      request.lastCreationDate,
      request.categoryCode,
      request.searchCategoryHierarchy,
      request.manufacturerId,
      request.manufacturerCode,
      request.productCode,
      request.modelCode,
      request.referenceType,
      request.referenceCode,
      request.referenceName,
      request.isNew,
      request.isSerialized,
      request.isPublished,
      request.isModel,
      request.catalogGroups,
      request.productCategoryItemId,
      request.productPlatform,
      request.productName,
      request.productUuid,
      request.productId,
      request.isPart,
      request.hierarchyLevel,
      request.asc,
      request.desc
    );
  },
  getInsuranceEquipments: (request: GetInsuranceEquipmentsRequest) => {
    return insuranceService.getInsuranceEquipments(
      request.token,
      request.orgCode,
      request.limitCount,
      request.limitOffset,
      request.mapLevel,
      request.enable,
      request.categoryId,
      request.manufacturerCode,
      request.productId,
      request.maxClaimNumber,
      request.priceMin,
      request.priceMax,
      request.maxAge,
      request.maxItemAllowed,
      request.registrationMandatory,
      request.maxAmountPerYear,
      request.maxAmountPerClaim,
      request.productCode,
      request.productName
    );
  },
  getCatalogParams: (request: GetCatalogParamsRequest) => {
    return catalogService.getCatalogParams(
      request.token,
      request.orgCode,
      request.limitCount,
      request.limitOffset,
      request.mapLevel,
      request.enable,
      request.text
    );
  },
  getUsers: (request: GetUsersRequest) => {
    return userService.getUsers(
      request.token,
      request.orgCode,
      request.enable,
      request.limitCount,
      request.limitOffset,
      request.mapLevel,
      request.userName,
      request.userFirstName,
      request.userLastName,
      request.userEmail,
      request.userLang,
      request.userCountry,
      request.userLocale,
      request.userTimezone,
      request.userReferenceExt,
      request.userNoRestriction,
      request.userNeedNewPwd,
      request.userNoConnection,
      request.userHttp,
      request.asc,
      request.desc
    );
  },
  getRules: (
    token: string,
    orgCode: string,
    ruleType: string,
    request: any
  ): Promise<AxiosResponse<any>> => {
    switch (ruleType) {
      case 'API':
        return ruleService.getApiRules(token, orgCode, request);
      case 'LOGISTIC_DELIVERY':
        return ruleService.getLogisticDeliveryRules(token, orgCode, request);
      case 'PROCESS':
        return ruleService.getProcessRules(token, orgCode, request);
      case 'QUOTE_OPTION':
        return ruleService.getQuoteOptionRules(token, orgCode, request);
      case 'STOCK':
        return ruleService.getStockCodeRules(token, orgCode, request);
      case 'TEMPLATE':
        return ruleService.getTemplateRules(token, orgCode, request);
      case 'TREATMENT_OPERATION':
        return ruleService.getTreatmentOperationRules(token, orgCode, request);
      case 'WARRANTY':
        return ruleService.getWarrantyRules(token, orgCode, request);
      case 'WARRANTY_CALCULATION':
        return ruleService.getWarrantyCalculationRules(token, orgCode, request);
      default:
        return new Promise<AxiosResponse<any>>((resolve, reject) => {
          return {
            data: { values: [] },
          };
        });
    }
  },
};

export default GcApiHelper;
