import React from 'react';
import { Button, ButtonProps, SvgIconProps, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import {
  Add,
  Check,
  Close,
  Delete,
  Save,
  ArrowBack,
  Search,
} from '@mui/icons-material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: (props: {
    deleteButton?: boolean;
    hasNoLabel?: boolean;
    noMargin?: boolean;
    margin?: string;
  }) => {
    const result: {
      color?: string;
      borderColor?: string;
      '&:hover'?: { borderColor?: string };
      '& > .MuiButton-startIcon'?: { margin: number };
      margin?: string;
    } = {};
    if (props.deleteButton) {
      result.color = '#f44336';
      result.borderColor = '#f44336';
      result['&:hover'] = {
        borderColor: '#f44336',
      };
    }
    if (props.hasNoLabel) {
      result['& > .MuiButton-startIcon'] = { margin: 0 };
    }

    result.margin = props.margin
      ? props.margin
      : props.noMargin
      ? '0'
      : '0 10px';
    return result;
  },
  /*test: () => {
    console.log(theme.breakpoints.down('sm'));
    return {};
  },*/
  shortButton: {
    '& > .MuiButton-startIcon': {
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    '& span.button-label': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

interface ButtonCustomInterface extends ButtonProps {
  buttonSize?: 'small' | 'medium' | 'large';
  deleteButton?: boolean;
  icon?: string;
  iconSize?: 'small' | 'large';
  label?: string;
  noMargin?: boolean;
  margin?: string;
  tooltip?: string;
}

interface IconsInterface {
  [key: string]: React.ReactElement<SvgIconProps>;
}

export const ButtonComponent: React.FC<ButtonCustomInterface> = ({
  buttonSize,
  deleteButton,
  icon,
  iconSize,
  label,
  noMargin,
  margin,
  tooltip,
  type,
  onClick,
  className,
  variant = 'outlined',
  ...rest
}) => {
  const styleProps: {
    deleteButton?: boolean;
    hasNoLabel?: boolean;
    noMargin?: boolean;
    margin?: string;
  } = {};
  if (!label || label.length === 0) {
    styleProps.hasNoLabel = true;
  }
  styleProps.deleteButton = deleteButton;
  styleProps.noMargin = noMargin;
  styleProps.margin = margin;

  const classes = useStyles(styleProps);
  const { t } = useTranslation();
  const icons: IconsInterface = {
    save: <Save fontSize={iconSize} />,
    check: <Check fontSize={iconSize} />,
    back: <ArrowBack fontSize={iconSize} />,
    close: <Close fontSize={iconSize} />,
    delete: <Delete fontSize={iconSize} />,
    add: <Add fontSize={iconSize} />,
    search: <Search fontSize={iconSize} />,
  };
  const startIcon = icons[icon || ''];

  return (
    <Tooltip title={tooltip ? (t(tooltip) as string) : ''}>
      <Button
        {...rest}
        variant={variant}
        className={clsx(className, classes.button, {
          [classes.shortButton]: !!startIcon,
        })}
        color="primary"
        onClick={onClick}
        startIcon={startIcon ? startIcon : undefined}
        size={buttonSize}
        type={type || 'button'}>
        <span className="button-label">{label ? t(label) : ''}</span>
      </Button>
    </Tooltip>
  );
};
