import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { ButtonComponent } from 'src/components/UI/Button/ButtonComponent';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      maxWidth: 'none',
    },

    '& .MuiDialogActions-root': {
      display: 'block',
    },
  },
  cancelButton: {
    float: 'left',
  },
  continueButton: {
    float: 'right',
  },
}));

interface LeaveWarningDialogProps {
  nextLocationPathname?: string;
  onContinueWithoutSaving: () => void;
  onSaveAndContinue?: () => void;
  onCancel: () => void;
}

export const LeaveWarningDialog: React.FC<LeaveWarningDialogProps> = ({
  nextLocationPathname,
  onContinueWithoutSaving,
  onSaveAndContinue,
  onCancel,
}) => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const classes = useStyles();
  return (
    <Dialog
      open={!!nextLocationPathname}
      onClose={onCancel}
      className={classes.root}>
      <DialogTitle id="alert-dialog-title">
        {t('leaveWarningDialog.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('leaveWarningDialog.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <ButtonComponent
            label={t('leaveWarningDialog.buttons.cancel')}
            onClick={onCancel}
            className={classes.cancelButton}
          />
          <div />
          {onSaveAndContinue && (
            <ButtonComponent
              label={t('leaveWarningDialog.buttons.saveAndContinue')}
              color="primary"
              onClick={onSaveAndContinue}
              className={classes.continueButton}
            />
          )}
          <ButtonComponent
            label={t('leaveWarningDialog.buttons.continueWithoutSaving')}
            color="primary"
            onClick={onContinueWithoutSaving}
            className={classes.continueButton}
          />
        </>
      </DialogActions>
    </Dialog>
  );
};
