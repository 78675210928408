import { ListObject, ListParameter, ListValue } from 'src/api/api-gc/model';
import { APIListService } from 'src/api/api-gc/service';
import defaultList from 'src/api/defaultLists.json';

const listService = new APIListService();

const ListHelper = {
  getList: (values: ListObject[], listCode: string): ListObject | undefined => {
    let result = undefined;
    for (const value of values) {
      if (listCode === value.listCode) {
        result = value;
      }
    }

    return result;
  },
  getValueById: (values: ListValue[], id: number): ListValue | undefined => {
    let result = undefined;
    for (const value of values) {
      if (id === value.valueId) {
        result = value;
      }
    }

    return result;
  },
  getValue: (values: ListValue[], code: string): ListValue | undefined => {
    let result = undefined;
    for (const value of values || []) {
      if (code === value.valueCode) {
        result = value;
      }
    }

    return result;
  },
  getValueText: (values: ListValue[], code: string) => {
    for (const value of values) {
      if (code === value.valueCode) {
        return value.valueText;
      }
    }
  },
  fetchAPIList: (
    token: string,
    orgCode: string,
    code: string,
    mapLevel?: string[]
  ) => {
    return listService.getLists(
      token,
      orgCode,
      true,
      undefined,
      undefined,
      mapLevel ? mapLevel : ['LIST_VALUE', 'TRANSLATIONS'],
      code
    );
  },
  getListById: (
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: string[]
  ) => {
    return new APIListService().getList(
      token,
      orgCode,
      id,
      mapLevel ? mapLevel : ['LIST_VALUE', 'TRANSLATIONS']
    );
  },
  getParameterValue: (value: ListValue, parameterCode: string) => {
    if (value && value.valueParams) {
      for (const p of value.valueParams) {
        if (p.param?.paramName === parameterCode) {
          return p.paramValueText;
        }
      }
    }

    return undefined;
  },
  getParameter: (params: ListParameter[], parameterCode: string) => {
    if (params) {
      for (const p of params) {
        if (p.paramName === parameterCode) {
          return p;
        }
      }
    }

    return undefined;
  },
  checkListValueParameter: (
    value: ListValue,
    parameterCode: string,
    parameterValue: any
  ) => {
    if (value && value.valueParams) {
      for (const p of value.valueParams) {
        if (
          p.param?.paramName === parameterCode &&
          p.paramValueText === parameterValue
        ) {
          return true;
        }
      }
    }

    return false;
  },
  getListByCode: async (
    token: string,
    orgCode: string,
    listCode: string,
    mapLevel?: string[]
  ): Promise<ListObject | undefined> => {
    const {
      data: { values },
    } = await ListHelper.fetchAPIList(token, orgCode, listCode, mapLevel);
    const data = ListHelper.getList(values || [], listCode);
    if (data) {
      return data;
    } else {
      // @ts-ignore
      if (!!defaultList[listCode]) {
        const { data } = await listService.createList(
          token,
          orgCode,
          // @ts-ignore
          defaultList[listCode],
          ['LIST_PARAM', 'LIST_VALUE', 'TRANSLATIONS', 'LIST_VALUE_PARAM']
        );
        if (data) {
          return data;
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
  },
  getListValuesByCode: async (
    token: string,
    orgCode: string,
    listCode: string,
    mapLevel?: string[],
    enable?: boolean
  ): Promise<ListValue[]> => {
    const result = await ListHelper.getListByCode(
      token,
      orgCode,
      listCode,
      mapLevel
    );
    return result && result.values
      ? result.values.filter((v) => (!enable ? true : v.enable === true))
      : [];
  },
  getDefaultValue: (values: ListValue[]) => {
    for (const value of values) {
      if (value.valueDefault) {
        return value;
      }
    }
    return undefined;
  },
  getDefaultValueCode: (values: ListValue[]) => {
    const value: ListValue | undefined = ListHelper.getDefaultValue(values);
    if (value) {
      return value.valueCode;
    } else {
      return undefined;
    }
  },
  getMapListValuesById: (values?: ListValue[]): Map<number, ListValue> => {
    return new Map<number, ListValue>(
      (values || []).map((value: ListValue) => [value.valueId as number, value])
    );
  },
  getMapListValuesByCode: (values?: ListValue[]): Map<string, ListValue> => {
    return new Map<string, ListValue>(
      (values || []).map((value: ListValue) => [
        value.valueCode as string,
        value,
      ])
    );
  },
  getListValueByParameterValue: (
    values: ListValue[],
    parameterCode: string,
    parameterValue: any
  ): ListValue | undefined => {
    if (parameterValue !== undefined) {
      for (const v of values) {
        if (
          ListHelper.checkListValueParameter(v, parameterCode, parameterValue)
        ) {
          return v;
        }
      }
    }
    return undefined;
  },
};

export default ListHelper;
