import React, { useState } from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { CatalogProduct } from 'src/api/api-gc/model';
import ProductsListWithLazyLoading from 'src/components/Products/ProductsListWithLazyLoading';

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflow: 'hidden',
  },
}));

interface ProductsModalProps {
  open: boolean;
  onClose: (values: CatalogProduct[]) => void;
  onCancel: () => void;
  accessorField: string;
  value?: string | number;
  multiple?: boolean;
  parentId?: string;
  type?: 'MODELS' | 'PRODUCTS' | 'PARTS';
}

const ProductsModal = ({
  onClose,
  onCancel,
  open,
  accessorField,
  value,
  multiple = false,
  parentId,
  type,
}: ProductsModalProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [selectedValues, setSelectedValues] = useState<CatalogProduct[]>([]);

  const handleCancel = () => {
    onCancel();
  };

  const onValuesChanged = (values: CatalogProduct[]) => {
    setSelectedValues(values);
  };

  const handleDoubleClick = (values: CatalogProduct[]) => {
    if (values && values.length > 0) {
      onClose(values);
    }
  };

  const handleClose = () => {
    if (selectedValues && selectedValues.length > 0) {
      onClose(selectedValues);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleCancel}
      open={open}
      // @ts-ignore
      parentid={parentId}>
      <DialogTitle>{t('modals.productSelection.title')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ProductsListWithLazyLoading
          open={open}
          onDoubleClick={handleDoubleClick}
          accessorField={accessorField}
          value={value}
          multiple={multiple}
          onValuesChanged={onValuesChanged}
          type={type}
          inPopin
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('modals.productSelection.buttons.cancel')}
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          {t('modals.productSelection.buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductsModal;
