import version from 'src/version';

const config = {
  version: version,
  env: 'LIVE',
  app: {
    defaultLang: 'en_US',
    menu: {
      menuGroup: 'BO_ADMIN',
      itemName: 'MENU',
    },
  },
  api: {
    apiDashboard: {
      URL: 'https://loreal.manager.sbeglobalcare.com/backend/dashboard',
    },
    apiPreferences: {
      URL: 'https://loreal.manager.sbeglobalcare.com/backend/manager',
    },
    apiGc: {
      URL: 'https://loreal.manager.sbeglobalcare.com/backend/rest',
    },
  },
  sso: {
    urlsCode: {
      success: 'OAUTH_REDIRECT_MANAGER_UI_SUCCESS',
      fail: 'OAUTH_REDIRECT_MANAGER_UI_FAIL',
    },
  },
};

export default config;
