import { v4 as uuidv4 } from 'uuid';

const reA = /[^a-zA-Z]/g;
const reN = /[^0-9]/g;

const ErrorHelper = {
  warn: (error: any) => {
    if (error.response) {
      // la requête a été faite et le code de réponse du serveur n’est pas dans la plage 2xx

      if (error.response.headers) {
        switch (error.response.headers['content-type']) {
          case 'application/json':
            console.warn(
              `${error.response.status} _ ${error.response.data.code} _ ${error.response.data.message}`
            );
            break;
          case 'text/html; charset=utf-8':
            console.warn(
              `${error.response.status} _ ${(error.response.data || '').replace(
                /<[^>]*>?/gm,
                ''
              )}`
            );
            break;
          default:
            console.warn('Something went wrong:', error.response.data);
            break;
        }
      } else {
        console.warn('Something went wrong:', error.response.data);
      }
    } else if (error.request) {
      // la requête a été faite mais aucune réponse n’a été reçue
      // `error.request` est une instance de XMLHttpRequest dans le navigateur
      // et une instance de http.ClientRequest avec node.js
      console.warn(error.request);
    } else {
      // quelque chose s’est passé lors de la construction de la requête et cela a provoqué une erreur
      console.warn('Error', error.message);
    }
  },
};

export default ErrorHelper;
