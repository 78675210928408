import * as React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './App';
import { AppProviders, restoreSettings, SettingsProvider } from './context';
import './i18n';
import 'react-virtualized/styles.css';
import { registerLicense } from '@syncfusion/ej2-base';
import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

// Registering Syncfusion license key
registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxfcnVUQmJcUURyW0o='
);

const settings = restoreSettings();

ReactDOM.render(
  <SettingsProvider settings={settings}>
    <AppProviders>
      <App />
    </AppProviders>
  </SettingsProvider>,
  document.getElementById('root')
);
