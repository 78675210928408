import React, { useEffect, useMemo, useRef } from 'react';
import { match, RouteProps } from 'react-router';
import { CardMedia, Grid, Typography } from '@mui/material';
import Page from 'src/components/Page';
import ReactDOM from 'react-dom';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
    paddingTop: 25,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    objectFit: 'contain',
    backgroundSize: 'contain',
    height: '100%',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const KeycloakGroupInvitationFail: React.FC<
  {
    computedMatch: match<{ orgCode: string }>;
  } & RouteProps
> = ({ ...rest }) => {
  const orgCode = useMemo(() => {
    return rest.computedMatch.params.orgCode;
  }, [rest]);

  const classes = useStyles();
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fixHeight = () => {
      if (container && container.current) {
        // eslint-disable-next-line react/no-find-dom-node
        const element = ReactDOM.findDOMNode(container.current) as HTMLElement;
        if (element) {
          const offsetTop = element.offsetTop;

          element.style.height = window.innerHeight - offsetTop - 125 + 'px';
        }
      }
    };

    fixHeight();

    window.addEventListener('resize', fixHeight);

    return () => {
      window.removeEventListener('resize', fixHeight);
    };
  }, []);

  return (
    <Page title="GlobalCare Manager - Invitation" className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.container} ref={container}>
          <CardMedia
            className={classes.media}
            image="/static/images/login.svg"
            title="GlobalCare Manager"
          />
        </Grid>
        <Grid
          item
          xs
          style={{
            display: 'flex',
          }}
          className={classes.loading}
        >
          <Typography variant="h3" color="textPrimary">
            FAIL
          </Typography>
        </Grid>
      </Grid>
    </Page>
  );
};

export default KeycloakGroupInvitationFail;
