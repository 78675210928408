import { v4 as uuidv4 } from 'uuid';

const reA = /[^a-zA-Z]/g;
const reN = /[^0-9]/g;

const ArrayHelper = {
  addingUuid: (array: Array<any>, childMapping?: string | string[]) => {
    const newArray: Array<any> = new Array<any>();
    if (array) {
      array.forEach(function (obj) {
        const item = { uuid: obj.uuid || uuidv4(), ...obj };
        if (Array.isArray(childMapping)) {
          for (const c of childMapping) {
            if (c && item[c]) {
              item[c] = ArrayHelper.addingUuid(obj[c], childMapping);
            }
          }
        } else {
          if (childMapping && item[childMapping]) {
            item[childMapping] = ArrayHelper.addingUuid(
              obj[childMapping],
              childMapping
            );
          }
        }
        newArray.push(item);
      });
    }

    return newArray;
  },
  move: (array: Array<any>, old_index: number, new_index: number) => {
    if (new_index >= array.length) {
      let k = new_index - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(new_index, 0, array.splice(old_index, 1)[0]);
    return array;
  },
  diff: (a1: any, a2: any): any[] => {
    const a = [],
      diff = [];

    for (let i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (let i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (const k in a) {
      diff.push(k);
    }

    return diff;
  },
  equals: (a: any[], b: any[]) => JSON.stringify(a) === JSON.stringify(b),
  sortAlphaNum: (a: any, b: any) => {
    if (a !== undefined && b !== undefined) {
      var aA = a.replace(reA, '');
      var bA = b.replace(reA, '');
      if (aA === bA) {
        var aN = parseInt(a.replace(reN, ''), 10);
        var bN = parseInt(b.replace(reN, ''), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    } else {
      return a === undefined ? -1 : 1;
    }
  },
  concatWithoutDuplicates: (a: any[], b: any[]) => {
    return Array.from(
      [...a, ...b]
        .reduce((m, o) => m.set(JSON.stringify(o), o), new Map())
        .values()
    );
  },
  uniqBy: (arr: any[], key: string) => {
    var seen: Record<any, boolean> = {};
    return arr.filter(function (item) {
      var k = item[key];
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  },
};

export default ArrayHelper;
