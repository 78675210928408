import React, { useEffect, useMemo, useRef, useState } from 'react';
import { match, RouteProps, useLocation } from 'react-router';
import { Grid, Typography } from '@mui/material';
import Page from 'src/components/Page';
import ReactDOM from 'react-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { APIKeycloakService } from 'src/api/api-keycloak/apiKeycloak.service';
import { DetailProvider } from '../../components/keycloak/users/context';
import UserForm from 'src/components/keycloak/users/UserFormInvitation';
import { User } from 'src/api/api-keycloak/model';
import { history } from 'src/App';
import { useTranslation } from 'react-i18next';

const keycloakService = new APIKeycloakService();

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
    paddingTop: 25,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    margin: '24px 0',
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: '24px',
  },
  bye: {
    justifyContent: 'center',
    display: 'flex',
    margin: '24px',
  },
}));

const KeycloakGroupInvitationCreateView: React.FC<
  {
    computedMatch: match<{ orgCode: string }>;
  } & RouteProps
> = ({ ...rest }) => {
  const memoizedOrgCode = useMemo(() => {
    return rest.computedMatch.params.orgCode;
  }, [rest]);
  const { t } = useTranslation();
  const translate = (key: string, context?: Record<string, string>) =>
    t(`keycloak.users.invitation.create.${key}`, context);

  const classes = useStyles();
  const container = useRef<HTMLDivElement>(null);

  const params = new URLSearchParams(useLocation().search);
  const token = params.get('token');

  const [cancelled, setCancelled] = useState<boolean>(false);

  useEffect(() => {
    const fixHeight = () => {
      if (container && container.current) {
        // eslint-disable-next-line react/no-find-dom-node
        const element = ReactDOM.findDOMNode(container.current) as HTMLElement;
        if (element) {
          const offsetTop = element.offsetTop;

          element.style.height = window.innerHeight - offsetTop - 125 + 'px';
        }
      }
    };

    fixHeight();

    window.addEventListener('resize', fixHeight);

    return () => {
      window.removeEventListener('resize', fixHeight);
    };
  }, []);

  useEffect(() => {
    if (!memoizedOrgCode || !token) {
      history.push(`/${memoizedOrgCode}/keycloak/group-invitation/fail`);
    }
  }, [memoizedOrgCode]);

  const onSubmit = (user: User) => {
    keycloakService
      .groupInvitationCreate(memoizedOrgCode, {
        token: token as string,
        user: user,
      })
      .then(() => {
        history.push(`/${memoizedOrgCode}/keycloak/group-invitation/success`);
      })
      .catch(() => {
        history.push(`/${memoizedOrgCode}/keycloak/group-invitation/fail`);
      });
  };

  const onCancel = () => {
    setCancelled(true);
  };

  return (
    <Page title="GlobalCare Manager - Invitation" className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.header}>
          <Typography variant="h1" color="textPrimary">
            <img
              //className={classes.image}
              alt="SBE GlobalCare"
              src="/static/gc-logo.svg"
            />
            <span>GlobalCare</span>
          </Typography>
        </Grid>
        {!cancelled ? (
          <>
            <Grid item xs={12} md={1}>
              {' '}
            </Grid>
            <Grid item xs={12} md={10} className={classes.title}>
              <Typography variant="h1" color="textPrimary">
                {translate('title')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              {' '}
            </Grid>

            <Grid item xs={12} md={1}>
              {' '}
            </Grid>
            <Grid item xs={12} md={10}>
              <DetailProvider>
                <UserForm
                  user={{
                    enabled: true,
                  }}
                  onSubmit={onSubmit}
                  onCancel={onCancel}
                />
              </DetailProvider>
            </Grid>
            <Grid item xs={12} md={1}>
              {' '}
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h1"
              color="textPrimary"
              className={classes.bye}
            >
              BYE
            </Typography>
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default KeycloakGroupInvitationCreateView;
