import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Container,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { match, RouteProps } from 'react-router';
import { APISessionService } from 'src/api/api-gc/service';
import Page from 'src/components/Page';
import { LoadingScreenContext } from 'src/context/LoadingScreenContext';
import { FieldType, FieldValidatorType } from 'src/interfaces';
import config from 'src/config';
import Copyright from 'src/views/login/Copyright';
import LogoutHelper from 'src/helpers/LogoutHelper';
import ErrorHelper from 'src/helpers/ErrorHelper';
import { TextFieldComponent } from 'src/components/UI';
import { history } from 'src/App';
import { setToken } from 'src/utils/token';
import {
  ApiContext,
  LoginInformationsInterface,
  OrganizationInformationsInterface,
  useSettings,
} from 'src/context';
import { GetOrganizationInfoResponse } from 'src/api/api-gc';
import { defaultOrganisationInfos } from 'src/views/login/Login';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import wait from '../../../utils/wait';

const sessionService = new APISessionService();

interface LoginStateSchema {
  otp: FieldType;
}

interface LoginValidationStateSchema {
  otp: FieldValidatorType;
}

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  errorMessage: {
    width: '100%',
    textAlign: 'center',
    marginTop: '24px',
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    objectFit: 'contain',
    backgroundSize: 'contain',
  },

  form: {},
  submit: {},
  error: {
    color: 'red',
    fontSize: '13px',
  },
}));

const OneTimePassword: React.FC<
  {
    computedMatch: match<{ orgCode: string }>;
  } & RouteProps
> = (props) => {
  const orgCode = useMemo(() => {
    return props.computedMatch.params.orgCode;
  }, [props]);
  const loadingScreenContext = useContext(LoadingScreenContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const useSearchParams = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const searchParams = useSearchParams();
  const isMountedRef = useIsMountedRef();

  const apiContext = useContext(ApiContext);
  const { saveSettings } = useSettings();

  const [state, setState] = useState<any>({});
  const [otpFieldDisable, setOtpFieldDisable] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [error, setError] = useState('');

  const [userEmail, setUserEmail] = useState('');
  const [otpToken, setOtpToken] = useState('');

  const [organisationInfos, setOrganisationInfos] = useState(
    defaultOrganisationInfos
  );

  useEffect(() => {
    if (
      !props.location ||
      !props.location.state ||
      !(props.location.state as any).userEmail ||
      !(props.location.state as any).token
    ) {
      redirectLogin();
    } else {
      setUserEmail((props.location.state as any).userEmail);

      const otpToken = (props.location.state as any).token;
      setOtpToken(otpToken);

      //if (otpToken) {
      sessionService
        .checkTwoFactorAuthenticationToken(orgCode, otpToken)
        .catch((error: any) => {
          ErrorHelper.warn(error);
          redirectLogin();
        });
      //} else {
      //  redirectLogin();
      //}
    }
  }, []);

  useEffect(() => {
    const mapLevel = ['LIST_VALUE_ENABLE', 'TRANSLATIONS'];
    sessionService
      .getOrganizationInfo(orgCode, mapLevel)
      .then(function (response: AxiosResponse<GetOrganizationInfoResponse>) {
        if (response.data && isMountedRef.current) {
          setOrganisationInfos(response.data);
        }
      })
      .catch((error): void => {
        console.warn(error);
        history.push('/404');
      });
  }, [orgCode, isMountedRef]);

  const validationStateSchema: LoginValidationStateSchema = {
    otp: {
      required: true,
      error: t('otp.error.requiredField'),
    },
  };

  const validateState = () => {
    let hasErrorInState = false;
    const errors: Record<string, string> = {};
    for (const key in validationStateSchema) {
      const isInputFieldRequired: boolean =
        validationStateSchema[key as keyof LoginValidationStateSchema].required;

      if (isInputFieldRequired && !state[key as keyof LoginStateSchema]) {
        hasErrorInState = true;
        errors[key] =
          validationStateSchema[key as keyof LoginValidationStateSchema]
            .error || '';
      }
    }
    setErrors(errors);
    return !hasErrorInState;
  };

  const handleOnChange = (name: string, value: any) => {
    if ((state[name] || '') !== value) {
      setState({ ...state, [name]: value });
    }
    setError('');
    setErrors('');
  };

  const goToChangePassword = () => {
    history.push(`/${orgCode}/change-password`);
  };

  const redirectLogin = () => {
    LogoutHelper.logout(orgCode);
  };

  const redirect = () => {
    const currentPath = searchParams.get('currentPath');

    if (currentPath) {
      history.push(`/${orgCode}/${currentPath}`);
    } else {
      history.push(`/${orgCode}/dashboard`);
    }
  };

  /*const redirectSuccess = () => {
    history.push({
      pathname: `/${orgCode}/reset-password-success`,
      state: {
        title: t('loginCheck2FA.title'),
        success: t('loginCheck2FA.success.successfulReset'),
      },
    });
  };*/

  const loginCheck2FA = (otp: string) => {
    loadingScreenContext.setLoading(true);
    setOtpFieldDisable(true);

    if (otpToken) {
      sessionService
        .login2FA(orgCode, {
          token: otpToken,
          oneTimePassword: otp,
          mapLevel: ['SESSION_USER', 'ORGANIZATION_KEY'],
        })
        .then((response: AxiosResponse) => {
          loadingScreenContext.setLoading(false);
          if (response.status === 200) {
            if (
              response &&
              response.data &&
              response.data.session &&
              response.data.token
            ) {
              const currentUser = response.data.session.sessionUser;
              setToken(response.data.token);

              if (currentUser?.userNeedNewPwd) {
                loadingScreenContext.setLoading(false);
                goToChangePassword();
              } else {
                const {
                  masterList,
                  userPreferences,
                  session,
                  menuItems,
                  timezones,
                } = response.data;

                const orgInfosWithTimezones: OrganizationInformationsInterface =
                  {
                    countries: organisationInfos.listCountry?.values || [],
                    languages: organisationInfos.listLang?.values || [],
                    locales: organisationInfos.listLocale?.values || [],
                    timezones: timezones || [],
                  };

                const loginInformations: LoginInformationsInterface = {
                  orgCode: orgCode,
                  user: currentUser!,
                  orgInfos: orgInfosWithTimezones,
                  masterList: masterList!,
                  userPreferences: userPreferences!,
                  menu: menuItems || [],
                };

                sessionStorage.setItem(
                  'loginInfos',
                  JSON.stringify(loginInformations)
                );

                apiContext.setLoginInfos(loginInformations);
                saveSettings({
                  locale: session.sessionLocale,
                  loginMode: 'normal',
                });

                loadingScreenContext.setLoading(false);
                redirect();
              }
            }
          } else {
            setError(response.data.message);
            //TODO redirect to login page (maybe after 5 seconds)
            wait(5).then(() => {
              redirectLogin();
            });
          }
        });
      /*.catch((error: any) => {
          console.warn('Something went wrong:', error);
        });*/
    }
  };

  const handleOnSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (validateState()) {
      setError('');
      loginCheck2FA(state.otp);
    }
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Page
        className={classes.root}
        title={`GlobalCare Manager - ${config.env} - Reset your password`}>
        <Container maxWidth="md">
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <Avatar className={classes.icon}>
                <LockIcon fontSize="large" />
              </Avatar>
              <Typography variant="h2" color="textPrimary">
                {t('login2FA.title')}
              </Typography>
              <br />
              <Typography variant="subtitle1" color="textSecondary">
                {t('login2FA.subtitle', { email: userEmail })}
              </Typography>
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      required
                      fullWidth
                      name="otp"
                      label={t('login2FA.fields.otp')}
                      onChange={(e) => {
                        handleOnChange('otp', e.target.value);
                      }}
                      error={errors.otp !== undefined}
                      helperText={errors.otp}
                      disabled={otpFieldDisable}
                    />
                  </Grid>
                  {error && (
                    <FormHelperText error className={classes.errorMessage}>
                      {error}
                    </FormHelperText>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleOnSubmit}>
                      {t('login2FA.buttons.ok')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <CardMedia
              className={classes.media}
              image="/static/images/login.svg"
              title="GlobalCare Manager"
            />
          </Card>
          <Box mt={1}>
            <Copyright />
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default OneTimePassword;
