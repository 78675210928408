import React, { useCallback, useContext } from 'react';
import { Box, Divider, Paper, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { User } from 'src/api/api-keycloak/model';
import { history } from 'src/App';
import { ButtonComponent } from 'src/components/UI/Button/ButtonComponent';
import Description from './details/Description';
import Groups from './details/Groups';
import { DetailContext, ERRORS } from './context';
import { ValidationStateSchema } from 'src/interfaces';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  headerText: {
    minWidth: '150px',
    margin: '0 20px 0 10px',
    textTransform: 'capitalize',
  },
}));

const tabs = ['description', 'groups'];

export const hasAttributes = (user: User) => {
  return !!user.attributes && Object.keys(user.attributes).length > 0;
};

const UserForm: React.FC<{
  user: User;
  onDeleteUser?: () => void;
  onSubmit: (data: User) => void;
}> = ({ user, onSubmit, onDeleteUser }) => {
  //, onDeleteUser, onSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const translate = (key: string, context?: Record<string, string>) =>
    t(`keycloak.users.form.${key}`, context);

  const { dispatch, data } = useContext(DetailContext);

  const getFormData: () => User = useCallback(() => {
    return { ...user, ...data };
  }, [data, user]);

  const [tabsValue, setTabsValue] =
    React.useState<(typeof tabs)[number]>('description');
  const handleChange = (
    _: React.ChangeEvent<{}>,
    newValue: (typeof tabs)[number]
  ) => {
    setTabsValue(newValue);
  };

  const getValidationSchema = useCallback(() => {
    const validationStateSchema: ValidationStateSchema = {
      lastName: {
        required: true,
        error: t('errors.requiredField'),
        maxLength: 450,
      },
      firstName: {
        required: true,
        error: t('errors.requiredField'),
        maxLength: 450,
      },
      username: {
        required: true,
        error: t('errors.requiredField'),
        maxLength: 900,
      },
      userPwd: {
        required: !user.id,
        error: t('errors.requiredField'),
        maxLength: 40,
      },
      userPwdRepeat: {
        required: !user.id,
        error: t('errors.requiredField'),
        maxLength: 40,
      },
      email: {
        required: false,
        error: t('errors.requiredField'),
        maxLength: 1800,
      },
    };

    return validationStateSchema;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateState = useCallback(() => {
    let hasErrorInState = false;
    const errors: Record<string, string> = {};
    let firstErrorOn: string | undefined = undefined;
    for (const key in getValidationSchema()) {
      const isInputFieldRequired: boolean = getValidationSchema()[key].required;

      if (isInputFieldRequired && !getFormData()[key as keyof User]) {
        hasErrorInState = true;
        errors[key] = getValidationSchema()[key].error || '';
        if (!firstErrorOn) {
          firstErrorOn = 'description';
        }
      }
    }

    if (
      (getFormData().userPwd || getFormData().userPwdRepeat) &&
      getFormData().userPwd !== getFormData().userPwdRepeat
    ) {
      hasErrorInState = true;
      errors.userPwd = translate('validation.passwordsDontMatch');
      errors.userPwdRepeat = translate('validation.passwordsDontMatch');
    }

    if ((getFormData().groups || []).length < 1) {
      hasErrorInState = true;
      errors.groups = translate('validation.groups');
      if (!firstErrorOn) {
        firstErrorOn = 'groups';
      }
    }

    dispatch({
      payload: { errors: errors, firstErrorOn: firstErrorOn },
      type: ERRORS,
    });

    if (firstErrorOn) {
      setTabsValue(firstErrorOn as 'description' | 'groups');
    }

    return !hasErrorInState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFormData(), getValidationSchema]);

  const onSaveClick = () => {
    if (validateState()) {
      if (!!user.id) {
        onSubmit(data);
      } else {
        onSubmit(getFormData());
      }
    }
  };

  const tryToDelete = () => {
    if (onDeleteUser) {
      onDeleteUser();
    }
  };

  return (
    <Paper>
      <Box p={2} display="flex" alignItems="center">
        {/*<Hidden smDown>
          <DescriptionOutlinedIcon />
        </Hidden>
        <Box>
          <Typography
            className={classes.headerText}
            variant="h4"
            color="textPrimary">
            {translate(`${tabsValue}.title`)}
          </Typography>
        </Box>*/}
        <Tabs
          value={tabsValue}
          onChange={handleChange}
          textColor="secondary"
          variant="scrollable">
          {tabs.map((tab, index) => {
            return (
              <Tab key={index} label={translate(`${tab}.title`)} value={tab} />
            );
          })}
        </Tabs>
      </Box>

      <Divider />

      <Box p={3}>
        <Box display={tabsValue === 'description' ? 'block' : 'none'}>
          <Description
            user={user}
            validationStateSchema={getValidationSchema()}
          />
        </Box>

        <Box display={tabsValue === 'groups' ? 'block' : 'none'}>
          <Groups user={user} />
        </Box>

        <Box display="flex" justifyContent="flex-end" mt={4}>
          <ButtonComponent
            className={classes.button}
            icon="close"
            label="button.cancel"
            onClick={() => history.goBack()}
          />
          <ButtonComponent
            className={classes.button}
            icon="save"
            label="button.save"
            type="submit"
            disabled={Object.entries(data).length === 0}
            onClick={onSaveClick}
          />
          {user.id && !hasAttributes(user) ? (
            <ButtonComponent
              className={classes.button}
              deleteButton
              icon="delete"
              label="button.delete"
              onClick={tryToDelete}
            />
          ) : null}
        </Box>
      </Box>
    </Paper>
  );
};

export default UserForm;
