import { format } from 'date-fns';
import { localeMap } from 'src/context';

export const formatDate = (
  date?: Date,
  locale = 'en_UK',
  formatStr = 'PPP'
): string | undefined => {
  if (!date) {
    return;
  }

  return date
    ? format(Date.parse(date as unknown as string), formatStr, {
        locale: localeMap[locale],
      })
    : '';
};

export const isValidDate = (d: any) => {
  // @ts-ignore
  return d instanceof Date && !isNaN(d);
};
