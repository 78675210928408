import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Container,
  Divider,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import { AxiosResponse } from 'axios';
import { useTranslation, Trans } from 'react-i18next';
import {
  GetOrganizationInfoResponse,
  ListObject,
  LoginCheckResponse,
  Profile,
} from 'src/api/api-gc';
import { APISessionService } from 'src/api/api-gc/service';
import { history } from 'src/App';
import { SelectBox } from 'src/components/Common/index';
import {
  ApiContext,
  LoginInformationsInterface,
  OrganizationInformationsInterface,
} from 'src/context/ApiContext';
import { LoadingScreenContext } from 'src/context/LoadingScreenContext';
import { useSettings } from 'src/context/SettingsContext';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { FieldType, FieldValidatorType } from 'src/interfaces';
import { setToken } from 'src/utils/token';
import Copyright from 'src/views/login/Copyright';
import { useLocation } from 'react-router-dom';

const sessionService = new APISessionService();

interface LoginStateSchema {
  username: FieldType;
  password: FieldType;
  lang: FieldType;
  profileName: FieldType;

  [index: string]: FieldType;
}

interface LoginValidationStateSchema {
  username: FieldValidatorType;
  password: FieldValidatorType;
  lang: FieldValidatorType;
  profileName: FieldValidatorType;

  [index: string]: FieldValidatorType;
}

const defaultListObject: ListObject = {
  values: [],
};

/*export const defaultOrganisationInfos: GetOrganizationInfoResponse = {
  listLang: defaultListObject,
  listLocale: defaultListObject,
  listCountry: defaultListObject,
};*/

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  errorMessage: {
    width: '100%',
    textAlign: 'center',
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    objectFit: 'contain',
    backgroundSize: 'contain',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  form: {},
  submit: {},
  error: {
    color: 'red',
    fontSize: '13px',
  },
}));

const LoginClassic: React.FC<{
  orgCode: string;
  organisationInfos: GetOrganizationInfoResponse;
}> = ({ orgCode, organisationInfos }) => {
  const apiContext = useContext(ApiContext);
  const { saveSettings } = useSettings();

  const loadingScreenContext = useContext(LoadingScreenContext);
  const isMountedRef = useIsMountedRef();

  const useSearchParams = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const searchParams = useSearchParams();

  const { t } = useTranslation();
  const classes = useStyles();

  // Define your state schema
  const stateSchema: LoginStateSchema = {
    username: { value: '', error: '' },
    password: { value: '', error: '' },
    lang: { value: '', error: '' },
    profileName: { value: '', error: '' },
  };

  const [state, setState] = useState(stateSchema);
  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [error, setError] = useState('');

  const [profiles, setProfiles] = useState<Profile[]>([]);

  /*const [organisationInfos, setOrganisationInfos] = useState(
    defaultOrganisationInfos
  );*/

  /*useEffect(() => {
    const mapLevel = ['LIST_VALUE_ENABLE', 'TRANSLATIONS'];
    sessionService
      .getOrganizationInfo(orgCode, mapLevel)
      .then(function (response: AxiosResponse<GetOrganizationInfoResponse>) {
        if (response.data && isMountedRef.current) {
          setOrganisationInfos(response.data);
        }
      })
      .catch((error): void => {
        console.warn(error);
        history.push('/404');
      });
  }, [orgCode, isMountedRef]);*/

  useEffect(() => {
    if (organisationInfos && organisationInfos.listLocale) {
      for (const l of organisationInfos.listLocale.values || []) {
        if (l.valueDefault !== undefined && l.valueDefault) {
          const name = 'lang';
          const value = l.valueCode as string;
          const error = '';

          if (isMountedRef.current) {
            setState((prevState) => ({
              ...prevState,
              [name]: { value, error },
            }));
          }
        }
      }
    }
  }, [isMountedRef, organisationInfos]);

  // Define your validationStateSchema
  // Note: validationStateSchema and stateSchema property
  // should be the same in-order validation works!
  const validationStateSchema: LoginValidationStateSchema = {
    username: {
      required: true,
      /*
            validator: {
              regEx: /^[a-zA-Z]+$/,
              error: t("login.error.usernameFormat"),
            },
      */
    },
    password: {
      required: true,
    },
    lang: {
      required: false,
    },
    profileName: {
      required: false,
    },
  };

  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component
  const validateState = useCallback(() => {
    const hasErrorInState = Object.keys(validationStateSchema).some((key) => {
      const isInputFieldRequired: boolean = validationStateSchema[key].required;
      const stateError: string = state[key].error; // state error
      const stateValue: string = state[key].value; // state value

      return (isInputFieldRequired && !stateValue) || stateError;
    });

    return hasErrorInState;
  }, [state, validationStateSchema]);

  // For every changed in our state this will be fired
  // To be able to disable the button
  useEffect(() => {
    if (isDirty && isMountedRef.current) {
      setDisable(validateState());
    }
  }, [isDirty, isMountedRef, state, validateState]);

  // Used to handle every changes in every input
  const handleOnChange = useCallback(
    (event) => {
      if (isMountedRef.current) {
        setError('');
        setIsDirty(true);
        const name = event.target.name;
        const value = event.target.value;

        let error = '';
        const fieldValidator: FieldValidatorType = validationStateSchema[name];
        if (fieldValidator.required) {
          if (!value) {
            error = t('login.error.requiredField');
          }
        }

        /*if (
          fieldValidator.validator !== null &&
          typeof fieldValidator.validator === 'object'
        ) {
          if (value && !fieldValidator.validator.regEx.test(value)) {
            error = fieldValidator.validator.error;
          }
        }*/

        if (isMountedRef.current) {
          setState((prevState) => ({
            ...prevState,
            [name]: { value, error },
          }));
        }
      }
    },
    [t, validationStateSchema, isMountedRef]
  );

  const redirect = () => {
    const currentPath = searchParams.get('currentPath');

    if (currentPath) {
      history.push(`/${orgCode}/${currentPath}`);
    } else {
      history.push(`/${orgCode}/dashboard`);
    }
  };

  const redirectToOTP = (token: string, userEmail: string) => {
    loadingScreenContext.setLoading(false);

    history.push({
      pathname: `/${orgCode}/login/two-factor-authentication`,
      state: { userEmail: userEmail, token: token },
      search: history.location.search,
    });
  };

  const handleSignIn = (
    login: string,
    pwd: string,
    locale: string,
    profileName: string
  ) => {
    loadingScreenContext.setLoading(true);

    sessionService
      .login(orgCode, {
        userName: login,
        userPassword: pwd,
        locale: locale,
        profileName: profileName,
        mapLevel: ['SESSION_USER', 'ORGANIZATION_KEY'],
      })
      .then(
        (response: AxiosResponse<LoginCheckResponse>) => {
          if (
            response &&
            response.data &&
            response.data.session &&
            response.data.token
          ) {
            const currentUser = response.data.session.sessionUser;
            setToken(response.data.token);

            if (currentUser?.userNeedNewPwd) {
              loadingScreenContext.setLoading(false);
              goToChangePassword();
            } else {
              const {
                masterList,
                userPreferences,
                session,
                menuItems,
                timezones,
              } = response.data;

              const orgInfosWithTimezones: OrganizationInformationsInterface = {
                countries: organisationInfos.listCountry?.values || [],
                languages: organisationInfos.listLang?.values || [],
                locales: organisationInfos.listLocale?.values || [],
                timezones: timezones || [],
              };

              const loginInformations: LoginInformationsInterface = {
                orgCode: orgCode,
                user: currentUser!,
                orgInfos: orgInfosWithTimezones,
                masterList: masterList!,
                userPreferences: userPreferences!,
                menu: menuItems || [],
              };

              sessionStorage.setItem(
                'loginInfos',
                JSON.stringify(loginInformations)
              );

              apiContext.setLoginInfos(loginInformations);
              saveSettings({
                locale: session.sessionLocale,
                loginMode: 'normal',
              });

              loadingScreenContext.setLoading(false);
              redirect();
            }
          } else if (
            response &&
            response.data &&
            response.data.entityProfiles
          ) {
            const profiles: Profile[] = [];
            for (const entityProfile of response.data.entityProfiles) {
              profiles.push(entityProfile.profile as Profile);
            }
            setProfiles(profiles);
            loadingScreenContext.setLoading(false);
          } else if (
            response &&
            response.data &&
            response.data.twoFactorAuthenticationResponse
          ) {
            redirectToOTP(
              response.data.twoFactorAuthenticationResponse
                .twoFactorAuthenticationToken as string,
              response.data.twoFactorAuthenticationResponse.userEmail as string
            );
          } else {
            // @ts-ignore
            setError(t(response.data.message as string));
            loadingScreenContext.setLoading(false);
          }
        },
        (error) => {
          loadingScreenContext.setLoading(false);
          console.warn('Error during login attempt:', error);
        }
      )
      .catch((error: any) => {
        loadingScreenContext.setLoading(false);
        console.warn('Something went wrong:', error);
      });
  };

  const onSubmitForm = (state: LoginStateSchema) => {
    handleSignIn(
      state.username.value,
      state.password.value,
      state.lang.value,
      state.profileName.value
    );
  };

  const handleOnSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setError('');

    if (!validateState()) {
      onSubmitForm(state);
    }
  };

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();

    if (event.key === 'Enter' && !validateState()) {
      onSubmitForm(state);
    }
  };

  const goToChangePassword = () => {
    history.push(`/${orgCode}/change-password`);
  };

  return (
    <>
      {/*
      // @ts-ignore*/}
      <Container maxWidth="md">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar>
            <Typography variant="h2" color="textPrimary">
              {t('login.title')}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {t('login.subtitle')}
            </Typography>
            <Box mt={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    id="username"
                    label={t('login.username')}
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={state.username.value}
                    onChange={handleOnChange}
                    onKeyUp={handleOnKeyUp}
                    error={!!state.username.error}
                    helperText={state.username.error}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    name="password"
                    label={t('login.password')}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={state.password.value}
                    onChange={handleOnChange}
                    onKeyUp={handleOnKeyUp}
                    error={!!state.password.error}
                    helperText={state.password.error}
                  />
                </Grid>
                {organisationInfos.listLocale?.values && (
                  <Grid item xs={12}>
                    <SelectBox
                      label={t('login.language')}
                      name="lang"
                      value=""
                      onChange={handleOnChange}>
                      {organisationInfos.listLocale.values.map((lang) => {
                        return (
                          <MenuItem key={lang.valueCode} value={lang.valueCode}>
                            {lang.valueText}
                          </MenuItem>
                        );
                      })}
                    </SelectBox>
                  </Grid>
                )}
                {profiles && profiles.length > 0 && (
                  <Grid item xs={12}>
                    <SelectBox
                      label={t('login.profile')}
                      name="profileName"
                      value=""
                      onChange={handleOnChange}>
                      {profiles.map((profile) => {
                        return (
                          <MenuItem
                            key={profile.profileName}
                            value={profile.profileName}>
                            {profile.profileName}
                          </MenuItem>
                        );
                      })}
                    </SelectBox>
                  </Grid>
                )}
                {error && (
                  <FormHelperText error className={classes.errorMessage}>
                    {error}
                  </FormHelperText>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleOnSubmit}
                    disabled={disable}>
                    {t('login.signIn')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Grid container>
              <Grid item xs>
                <Link
                  className={classes.link}
                  variant="body2"
                  onClick={() => {
                    history.push(`/${orgCode}/request-reset-password`);
                  }}>
                  <Trans i18nKey="login.forgottenPassword">
                    Forgot password?
                  </Trans>
                </Link>
              </Grid>
              {/*<Grid item>
                  <Link href="#" variant="body2">
                    <Trans i18nKey="login.signUp">
                      Don't have an account? Sign Up
                    </Trans>
                  </Link>
                </Grid>*/}
            </Grid>
          </CardContent>
          <CardMedia
            className={classes.media}
            image="/static/images/login.svg"
            title="GlobalCare Manager"
          />
        </Card>
        <Box mt={1}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

export default LoginClassic;
