import React from 'react';
import { Collapse, IconButton, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSettings } from 'src/context/SettingsContext';
import { formatDate } from 'src/helpers/DateHelper';
import { TextFieldComponent } from 'src/components/UI';

interface DetailsProps {
  id?: number | string;
  createDate?: Date;
  updateDate?: Date;
}

const Details: React.FC<DetailsProps> = ({ id, createDate, updateDate }) => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const [expanded, setExpanded] = React.useState(false);
  const useStyles = makeStyles((theme: Theme) => ({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }));
  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Typography display="inline" variant="h5" color="textPrimary">
        {t('main.form.details')}
      </Typography>
      <IconButton
        className={clsx(classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        size="large"
      >
        <ExpandMoreIcon />
      </IconButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {id && (
          <TextFieldComponent
            variant="standard"
            InputProps={{ disableUnderline: true }}
            defaultValue={id}
            disabled
            label="Id"
          />
        )}
        {createDate && (
          <TextFieldComponent
            variant="standard"
            InputProps={{ disableUnderline: true }}
            defaultValue={formatDate(createDate, settings.locale)}
            disabled
            label={t('main.form.created')}
          />
        )}
        {updateDate && (
          <TextFieldComponent
            variant="standard"
            InputProps={{ disableUnderline: true }}
            defaultValue={formatDate(updateDate, settings.locale)}
            disabled
            label={t('main.form.updated')}
          />
        )}
      </Collapse>
    </>
  );
};

export default Details;
