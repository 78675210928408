import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from 'src/components/UI/TreeView/TreeView';
import { RenderTree } from 'src/interfaces/RenderTree';
import { TextFieldComponent } from 'src/components/UI';
import ReactDOM from 'react-dom';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      //overflowY: 'hidden',
      '& li': {
        height: 50,
      },
    },
  })
);

interface TreeItemsModalProps {
  open: boolean;
  multipleSelection?: boolean;
  onCancel: () => void;
  onContinue: (items: RenderTree[]) => void;
  list: RenderTree[];
  value?: RenderTree[];
  parentId?: string;
  title: string;
}

const TreeItemsModal = ({
  open,
  multipleSelection,
  onCancel,
  onContinue,
  list,
  value,
  parentId,
  title,
}: TreeItemsModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selected, setSelected] = React.useState<RenderTree[]>(value || []);
  const [search, setSearch] = useState<string>('');
  const [values, setValues] = useState<RenderTree[]>(list || []);

  const content = React.useRef<HTMLDivElement>(null);

  const isOpened = (item: RenderTree, selected: RenderTree[]): boolean => {
    let result = false;
    if (
      selected.some((node) => item.id === node.id && item.type === node.type)
    ) {
      result = true;
    } else if (item.children) {
      for (const i of item.children) {
        if (isOpened(i, selected)) {
          result = true;
        }
      }
    }

    item.isOpened = result;
    return result;
  };

  useEffect(() => {
    setSearch('');
    setSelected(value || []);
    for (const item of list) {
      isOpened(item, value || []);
    }
    setValues(list);
  }, [open, list, value]);

  const onSubmit = () => {
    if (selected && selected.length > 0) {
      onContinue(selected);
    }
  };

  var timer: any;
  useEffect(() => {
    const find = (item: any, search: string): any => {
      if (
        item.label &&
        (item.label as string).toLowerCase().includes(search.toLowerCase())
      ) {
        return item;
      } else if (item.children) {
        const children = [];
        for (const i of item.children) {
          const tmp = find(i, search);
          if (tmp !== undefined) {
            children.push(tmp);
          }
        }

        if (children.length > 0) {
          const tmp: any = { ...item };
          tmp.isOpened = true;
          tmp.children = children;
          return tmp;
        }
      }
    };

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (search === '') {
        setValues(list);
      } else {
        setValues(
          list
            .map((i) => find(i, search))
            .filter((i) => {
              return i !== undefined;
            })
        );
      }
    }, 400);
  }, [search]);

  const scrollTo = () => {
    const elt = ReactDOM.findDOMNode(content.current);
    if (elt) {
      // @ts-ignore
      const selected = elt.getElementsByClassName('Mui-checked')[0];
      if (selected) {
        // @ts-ignore
        elt.scrollTop = selected.offsetTop;
      }
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      // @ts-ignore
      parentid={parentId}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <div
        style={{
          margin: '15px',
          width: 'calc(100% - 30px)',
        }}>
        <TextFieldComponent
          label={'Search'}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          fullWidth
        />
      </div>
      <DialogContent className={classes.content} ref={content}>
        <Grid container>
          <Grid item xs={12}>
            <TreeView
              items={values as RenderTree[]}
              selectedValues={selected}
              onChange={(selectedValues) => {
                setSelected(selectedValues);
              }}
              multipleSelection={multipleSelection || false}
              onLoad={scrollTo}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('button.cancel')}
        </Button>
        <Button color="primary" onClick={onSubmit}>
          {t('button.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TreeItemsModal;
