/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  Task,
  TaskType,
  TaskTypeParameter,
  TaskTypeParameterValue,
  SearchTasksResponse,
  SearchTaskTypesResponse,
  SearchTaskTypeParametersResponse,
  SearchTaskTypeParameterValuesResponse,
} from '../model';

import { Configuration } from '../configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APITaskService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates an task
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createTask(
    token: string,
    orgCode: string,
    body?: Task,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Task>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createTask.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createTask.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<Task>(`${this.basePath}/task/tasks`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates an taskType
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createTaskType(
    token: string,
    orgCode: string,
    body?: TaskType,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TaskType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createTaskType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createTaskType.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<TaskType>(`${this.basePath}/task/taskTypes`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates an taskTypeParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskTypeId
   * @param body
   * @param mapLevel
   */
  public createTaskTypeParameter(
    token: string,
    orgCode: string,
    taskTypeId: number,
    body?: TaskTypeParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TaskTypeParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createTaskTypeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createTaskTypeParameter.'
      );
    }

    if (taskTypeId === null || taskTypeId === undefined) {
      throw new Error(
        'Required parameter taskTypeId was null or undefined when calling createTaskTypeParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<TaskTypeParameter>(
      `${this.basePath}/task/taskTypes/${encodeURIComponent(
        String(taskTypeId)
      )}/parameters`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a taskTaskParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskId
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public createTaskTypeParameterValue(
    token: string,
    orgCode: string,
    taskId: number,
    paramId: number,
    body?: TaskTypeParameterValue,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TaskTypeParameterValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createTaskTypeParameterValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createTaskTypeParameterValue.'
      );
    }

    if (taskId === null || taskId === undefined) {
      throw new Error(
        'Required parameter taskId was null or undefined when calling createTaskTypeParameterValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling createTaskTypeParameterValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<TaskTypeParameterValue>(
      `${this.basePath}/task/tasks/${encodeURIComponent(
        String(taskId)
      )}/params/${encodeURIComponent(String(paramId))}/taskParams`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the task
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskId
   * @param mapLevel
   */
  public getTask(
    token: string,
    orgCode: string,
    taskId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Task>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTask.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTask.'
      );
    }

    if (taskId === null || taskId === undefined) {
      throw new Error(
        'Required parameter taskId was null or undefined when calling getTask.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<Task>(
      `${this.basePath}/task/tasks/${encodeURIComponent(String(taskId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the taskType
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskTypeId
   * @param mapLevel
   */
  public getTaskType(
    token: string,
    orgCode: string,
    taskTypeId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TaskType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTaskType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTaskType.'
      );
    }

    if (taskTypeId === null || taskTypeId === undefined) {
      throw new Error(
        'Required parameter taskTypeId was null or undefined when calling getTaskType.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<TaskType>(
      `${this.basePath}/task/taskTypes/${encodeURIComponent(
        String(taskTypeId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the taskTypeParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskTypeId
   * @param paramId
   * @param mapLevel
   */
  public getTaskTypeParameter(
    token: string,
    orgCode: string,
    taskTypeId: number,
    paramId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TaskTypeParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTaskTypeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTaskTypeParameter.'
      );
    }

    if (taskTypeId === null || taskTypeId === undefined) {
      throw new Error(
        'Required parameter taskTypeId was null or undefined when calling getTaskTypeParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling getTaskTypeParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<TaskTypeParameter>(
      `${this.basePath}/task/taskTypes/${encodeURIComponent(
        String(taskTypeId)
      )}/parameters/${encodeURIComponent(String(paramId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the taskTaskParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskId
   * @param paramId
   * @param taskParamId
   * @param mapLevel
   */
  public getTaskTypeParameterValue(
    token: string,
    orgCode: string,
    taskId: number,
    paramId: number,
    taskParamId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TaskTypeParameterValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTaskTypeParameterValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTaskTypeParameterValue.'
      );
    }

    if (taskId === null || taskId === undefined) {
      throw new Error(
        'Required parameter taskId was null or undefined when calling getTaskTypeParameterValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling getTaskTypeParameterValue.'
      );
    }

    if (taskParamId === null || taskParamId === undefined) {
      throw new Error(
        'Required parameter taskParamId was null or undefined when calling getTaskTypeParameterValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<TaskTypeParameterValue>(
      `${this.basePath}/task/tasks/${encodeURIComponent(
        String(taskId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/taskParams/${encodeURIComponent(String(taskParamId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get taskTaskParameters
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param taskId
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   */
  public getTaskTypeParameterValues(
    token: string,
    orgCode: string,
    paramId: number,
    taskId: number,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean
  ): Promise<AxiosResponse<SearchTaskTypeParameterValuesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTaskTypeParameterValues.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTaskTypeParameterValues.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling getTaskTypeParameterValues.'
      );
    }

    if (taskId === null || taskId === undefined) {
      throw new Error(
        'Required parameter taskId was null or undefined when calling getTaskTypeParameterValues.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchTaskTypeParameterValuesResponse>(
      `${this.basePath}/task/tasks/${encodeURIComponent(
        String(taskId)
      )}/params/${encodeURIComponent(String(paramId))}/taskParams`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get taskTypeParameters
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskTypeId
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param paramName
   * @param paramType
   * @param paramMandatory
   */
  public getTaskTypeParameters(
    token: string,
    orgCode: string,
    taskTypeId?: number,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    paramName?: string,
    paramType?: string,
    paramMandatory?: boolean
  ): Promise<AxiosResponse<SearchTaskTypeParametersResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTaskTypeParameters.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTaskTypeParameters.'
      );
    }

    /*if (taskTypeId === null || taskTypeId === undefined) {
      throw new Error(
        'Required parameter taskTypeId was null or undefined when calling getTaskTypeParameters.'
      );
    }*/

    const queryParameters = new URLSearchParams();
    if (taskTypeId !== undefined && taskTypeId !== null) {
      queryParameters.append('taskTypeId', taskTypeId as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (paramName !== undefined && paramName !== null) {
      queryParameters.append('paramName', paramName as any);
    }
    if (paramType !== undefined && paramType !== null) {
      queryParameters.append('paramType', paramType as any);
    }
    if (paramMandatory !== undefined && paramMandatory !== null) {
      queryParameters.append('paramMandatory', paramMandatory as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchTaskTypeParametersResponse>(
      `${this.basePath}/task/taskTypes/${encodeURIComponent(
        String(taskTypeId)
      )}/parameters`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get taskTypes
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param typeCode
   * @param typeName
   */
  public getTaskTypes(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    typeCode?: string,
    typeName?: string
  ): Promise<AxiosResponse<SearchTaskTypesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTaskTypes.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTaskTypes.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (typeCode !== undefined && typeCode !== null) {
      queryParameters.append('typeCode', typeCode as any);
    }
    if (typeName !== undefined && typeName !== null) {
      queryParameters.append('typeName', typeName as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchTaskTypesResponse>(
      `${this.basePath}/task/taskTypes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get tasks
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param typeCode
   * @param taskCode
   */
  public getTasks(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    typeCode?: string,
    taskCode?: string
  ): Promise<AxiosResponse<SearchTasksResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTasks.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTasks.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (typeCode !== undefined && typeCode !== null) {
      queryParameters.append('typeCode', typeCode as any);
    }
    if (taskCode !== undefined && taskCode !== null) {
      queryParameters.append('taskCode', taskCode as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchTasksResponse>(`${this.basePath}/task/tasks`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Modify an task
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyTask(
    token: string,
    orgCode: string,
    taskId: number,
    body?: Task,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<Task>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyTask.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyTask.'
      );
    }

    if (taskId === null || taskId === undefined) {
      throw new Error(
        'Required parameter taskId was null or undefined when calling modifyTask.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<Task>(
      `${this.basePath}/task/tasks/${encodeURIComponent(String(taskId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an taskType
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskTypeId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyTaskType(
    token: string,
    orgCode: string,
    taskTypeId: number,
    body?: TaskType,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<TaskType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyTaskType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyTaskType.'
      );
    }

    if (taskTypeId === null || taskTypeId === undefined) {
      throw new Error(
        'Required parameter taskTypeId was null or undefined when calling modifyTaskType.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<TaskType>(
      `${this.basePath}/task/taskTypes/${encodeURIComponent(
        String(taskTypeId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an taskTypeParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskTypeId
   * @param paramId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyTaskTypeParameter(
    token: string,
    orgCode: string,
    taskTypeId: number,
    paramId: number,
    body?: TaskTypeParameter,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<TaskTypeParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyTaskTypeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyTaskTypeParameter.'
      );
    }

    if (taskTypeId === null || taskTypeId === undefined) {
      throw new Error(
        'Required parameter taskTypeId was null or undefined when calling modifyTaskTypeParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyTaskTypeParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<TaskTypeParameter>(
      `${this.basePath}/task/taskTypes/${encodeURIComponent(
        String(taskTypeId)
      )}/parameters/${encodeURIComponent(String(paramId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a taskTaskParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskId
   * @param paramId
   * @param taskParamId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyTaskTypeParameterValue(
    token: string,
    orgCode: string,
    taskId: number,
    paramId: number,
    taskParamId: number,
    body?: TaskTypeParameterValue,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<TaskTypeParameterValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyTaskTypeParameterValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyTaskTypeParameterValue.'
      );
    }

    if (taskId === null || taskId === undefined) {
      throw new Error(
        'Required parameter taskId was null or undefined when calling modifyTaskTypeParameterValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyTaskTypeParameterValue.'
      );
    }

    if (taskParamId === null || taskParamId === undefined) {
      throw new Error(
        'Required parameter taskParamId was null or undefined when calling modifyTaskTypeParameterValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<TaskTypeParameterValue>(
      `${this.basePath}/task/tasks/${encodeURIComponent(
        String(taskId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/taskParams/${encodeURIComponent(String(taskParamId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an task
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskId
   */
  public removeTask(
    token: string,
    orgCode: string,
    taskId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeTask.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeTask.'
      );
    }

    if (taskId === null || taskId === undefined) {
      throw new Error(
        'Required parameter taskId was null or undefined when calling removeTask.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/task/tasks/${encodeURIComponent(String(taskId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an taskType
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskTypeId
   */
  public removeTaskType(
    token: string,
    orgCode: string,
    taskTypeId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeTaskType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeTaskType.'
      );
    }

    if (taskTypeId === null || taskTypeId === undefined) {
      throw new Error(
        'Required parameter taskTypeId was null or undefined when calling removeTaskType.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/task/taskTypes/${encodeURIComponent(
        String(taskTypeId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an taskTypeParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskTypeId
   * @param paramId
   */
  public removeTaskTypeParameter(
    token: string,
    orgCode: string,
    taskTypeId: number,
    paramId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeTaskTypeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeTaskTypeParameter.'
      );
    }

    if (taskTypeId === null || taskTypeId === undefined) {
      throw new Error(
        'Required parameter taskTypeId was null or undefined when calling removeTaskTypeParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling removeTaskTypeParameter.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/task/taskTypes/${encodeURIComponent(
        String(taskTypeId)
      )}/parameters/${encodeURIComponent(String(paramId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a taskTaskParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskId
   * @param paramId
   * @param taskParamId
   */
  public removeTaskTypeParameterValue(
    token: string,
    orgCode: string,
    taskId: number,
    paramId: number,
    taskParamId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeTaskTypeParameterValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeTaskTypeParameterValue.'
      );
    }

    if (taskId === null || taskId === undefined) {
      throw new Error(
        'Required parameter taskId was null or undefined when calling removeTaskTypeParameterValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling removeTaskTypeParameterValue.'
      );
    }

    if (taskParamId === null || taskParamId === undefined) {
      throw new Error(
        'Required parameter taskParamId was null or undefined when calling removeTaskTypeParameterValue.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/task/tasks/${encodeURIComponent(
        String(taskId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/taskParams/${encodeURIComponent(String(taskParamId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an task
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskId
   * @param body
   * @param mapLevel
   */
  public replaceTask(
    token: string,
    orgCode: string,
    taskId: number,
    body?: Task,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Task>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceTask.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceTask.'
      );
    }

    if (taskId === null || taskId === undefined) {
      throw new Error(
        'Required parameter taskId was null or undefined when calling replaceTask.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<Task>(
      `${this.basePath}/task/tasks/${encodeURIComponent(String(taskId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an taskType
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskTypeId
   * @param body
   * @param mapLevel
   */
  public replaceTaskType(
    token: string,
    orgCode: string,
    taskTypeId: number,
    body?: TaskType,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TaskType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceTaskType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceTaskType.'
      );
    }

    if (taskTypeId === null || taskTypeId === undefined) {
      throw new Error(
        'Required parameter taskTypeId was null or undefined when calling replaceTaskType.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<TaskType>(
      `${this.basePath}/task/taskTypes/${encodeURIComponent(
        String(taskTypeId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an taskTypeParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskTypeId
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public replaceTaskTypeParameter(
    token: string,
    orgCode: string,
    taskTypeId: number,
    paramId: number,
    body?: TaskTypeParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TaskTypeParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceTaskTypeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceTaskTypeParameter.'
      );
    }

    if (taskTypeId === null || taskTypeId === undefined) {
      throw new Error(
        'Required parameter taskTypeId was null or undefined when calling replaceTaskTypeParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling replaceTaskTypeParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<TaskTypeParameter>(
      `${this.basePath}/task/taskTypes/${encodeURIComponent(
        String(taskTypeId)
      )}/parameters/${encodeURIComponent(String(paramId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a taskTaskParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param taskId
   * @param paramId
   * @param taskParamId
   * @param body
   * @param mapLevel
   */
  public replaceTaskTypeParameterValue(
    token: string,
    orgCode: string,
    taskId: number,
    paramId: number,
    taskParamId: number,
    body?: TaskTypeParameterValue,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<TaskTypeParameterValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceTaskTypeParameterValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceTaskTypeParameterValue.'
      );
    }

    if (taskId === null || taskId === undefined) {
      throw new Error(
        'Required parameter taskId was null or undefined when calling replaceTaskTypeParameterValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling replaceTaskTypeParameterValue.'
      );
    }

    if (taskParamId === null || taskParamId === undefined) {
      throw new Error(
        'Required parameter taskParamId was null or undefined when calling replaceTaskTypeParameterValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<TaskTypeParameterValue>(
      `${this.basePath}/task/tasks/${encodeURIComponent(
        String(taskId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/taskParams/${encodeURIComponent(String(taskParamId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
