import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch, SwitchProps, Tooltip } from '@mui/material';

interface SwitchCustomProps extends SwitchProps {
  label?: string;
  tooltip?: string;
}

export const SwitchComponent = ({
  tooltip,
  label,
  style,
  ...rest
}: SwitchCustomProps) => {
  if (label) {
    if (tooltip) {
      return (
        <Tooltip title={tooltip}>
          <FormControlLabel
            style={style}
            control={<Switch color="primary" {...rest} />}
            label={label || ''}
          />
        </Tooltip>
      );
    } else {
      return (
        <FormControlLabel
          style={style}
          control={<Switch color="primary" {...rest} />}
          label={label || ''}
        />
      );
    }
  } else {
    if (tooltip) {
      return (
        <Tooltip title={tooltip}>
          <Switch color="primary" {...rest} />
        </Tooltip>
      );
    } else {
      return <Switch color="primary" {...rest} />;
    }
  }
};
