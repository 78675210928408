import config from 'src/config.js';
import axios, { AxiosResponse } from 'axios';
import {
  Group,
  GroupInvitation,
  GroupInvitationAcceptation,
  User,
} from './model';
import PermissionsEnum from './PermissionsEnum';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIKeycloakService {
  protected basePath = config.api.apiGc.URL;

  public getGroups(token: string, orgCode: string, permission?: string) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getGroups.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getGroups.'
      );
    }

    const queryParameters = new URLSearchParams();
    // @ts-ignore
    if (permission && Object.values(PermissionsEnum).includes(permission)) {
      queryParameters.append('permission', permission as any);
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.get(`${this.basePath}/custom/keycloak/groups`, {
      headers: headers,
      params: queryParameters,
      validateStatus: validateStatus,
    }) as Promise<AxiosResponse<Group[]>>;
  }

  public getFlattedGroups(token: string, orgCode: string, permission?: string) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getFlattedGroups.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getFlattedGroups.'
      );
    }

    const queryParameters = new URLSearchParams();
    // @ts-ignore
    if (permission && Object.values(PermissionsEnum).includes(permission)) {
      queryParameters.append('permission', permission as any);
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.get(`${this.basePath}/custom/keycloak/flatted-groups`, {
      headers: headers,
      params: queryParameters,
      validateStatus: validateStatus,
    }) as Promise<AxiosResponse<Group[]>>;
  }

  public getGroup(token: string, orgCode: string, id: string) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getGroup.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getGroup.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getGroup.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.get(
      `${this.basePath}/custom/keycloak/groups/${encodeURIComponent(
        String(id)
      )}`,
      {
        headers: headers,
        validateStatus: validateStatus,
      }
    ) as Promise<AxiosResponse<Group>>;
  }

  public getUsers(token: string, orgCode: string) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUsers.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUsers.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.get(`${this.basePath}/custom/keycloak/users`, {
      headers: headers,
      validateStatus: validateStatus,
    }) as Promise<AxiosResponse<User[]>>;
  }

  public getUser(token: string, orgCode: string, id: string) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getUser.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getUser.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.get(
      `${this.basePath}/custom/keycloak/users/${encodeURIComponent(
        String(id)
      )}`,
      {
        headers: headers,
        validateStatus: validateStatus,
      }
    ) as Promise<AxiosResponse<User>>;
  }

  public createUser(token: string, orgCode: string, body: User) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createUser.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.post(`${this.basePath}/custom/keycloak/users`, body, {
      headers: headers,
      validateStatus: validateStatus,
    }) as Promise<AxiosResponse<User>>;
  }

  public saveUser(token: string, orgCode: string, id: string, body: User) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling saveUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling saveUser.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling saveUser.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.patch(
      `${this.basePath}/custom/keycloak/users/${encodeURIComponent(
        String(id)
      )}`,
      body,
      {
        headers: headers,
        validateStatus: validateStatus,
      }
    ) as Promise<AxiosResponse<User>>;
  }

  public deleteUser(token: string, orgCode: string, id: string) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteUser.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteUser.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.delete(
      `${this.basePath}/custom/keycloak/users/${encodeURIComponent(
        String(id)
      )}`,
      {
        headers: headers,
        validateStatus: validateStatus,
      }
    ) as Promise<AxiosResponse<User>>;
  }

  public sendGroupInvitation(
    token: string,
    orgCode: string,
    body: GroupInvitation
  ) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling sendGroupInvitation.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling sendGroupInvitation.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.post(
      `${this.basePath}/custom/keycloak/groups/send-invitation`,
      body,
      {
        headers: headers,
        validateStatus: validateStatus,
      }
    ) as Promise<AxiosResponse<User>>;
  }

  /**
   * group invitation join
   *
   * @param orgCode Organization Code
   * @param body
   */
  public groupInvitationJoin(
    orgCode: string,
    body: GroupInvitationAcceptation
  ): Promise<AxiosResponse<any>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling groupInvitationJoin.'
      );
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    /*// to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }*/

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.post<any>(
      `${this.basePath}/custom/keycloak/groups/invitation-join`,
      body,
      {
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * group invitation create
   *
   * @param orgCode Organization Code
   * @param body
   */
  public groupInvitationCreate(
    orgCode: string,
    body: GroupInvitationAcceptation
  ): Promise<AxiosResponse<any>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling groupInvitationCreate.'
      );
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.post<any>(
      `${this.basePath}/custom/keycloak/groups/invitation-create`,
      body,
      {
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  public createGroup(
    token: string,
    orgCode: string,
    body: { group: string; parentId?: string }
  ) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createGroup.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createGroup.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.post(`${this.basePath}/custom/keycloak/groups`, body, {
      headers: headers,
      validateStatus: validateStatus,
    }) as Promise<AxiosResponse<Group>>;
  }

  public getPendingInvites(token: string, orgCode: string) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getPendingInvites.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getPendingInvites.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.get(`${this.basePath}/custom/keycloak/pending-invites`, {
      headers: headers,
      //params: queryParameters,
      validateStatus: validateStatus,
    }) as Promise<AxiosResponse<any[]>>;
  }

  public deleteInvite(token: string, orgCode: string, id: string) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteInvites.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteInvites.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteInvites.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.delete(
      `${this.basePath}/custom/keycloak/pending-invites/${encodeURIComponent(
        String(id)
      )}`,
      {
        headers: headers,
        validateStatus: validateStatus,
      }
    ) as Promise<AxiosResponse<User>>;
  }

  public generateRealm(token: string, orgCode: string, body: any) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling generateRealm.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling generateRealm.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.post(`${this.basePath}/custom/keycloak/generate-realm`, body, {
      headers: headers,
      validateStatus: validateStatus,
    }) as Promise<AxiosResponse<any>>;
  }

  public createIdentityProvider(token: string, orgCode: string, body: any) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createIdentityProvider.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createIdentityProvider.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }

    // to determine the Accept header
    headers['Accept'] = 'application/json';

    // to determine the Content-Type header
    headers['Content-Type'] = 'application/json';

    return axios.post(
      `${this.basePath}/custom/keycloak/add-identity-provider`,
      body,
      {
        headers: headers,
        validateStatus: validateStatus,
      }
    ) as Promise<AxiosResponse<any>>;
  }
}
