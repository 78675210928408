import React from 'react';
import { Autocomplete, MenuItem } from '@mui/material';
import SelectBox from 'src/components/Common/SelectBox';
import { InputValueInterface } from 'src/interfaces';
import { SwitchComponent, TextFieldComponent } from 'src/components/UI';
import { SelectWithTreeView } from './SelectHelper';
import AsyncAutocomplete from '../UI/AsyncAutocomplete';
import { DatePickerComponent } from '../UI/DatePicker/DatePickerComponent';
import AsyncRowRadioButtonsGroup from '../UI/RadioButtonsGroup/AsyncRadioButtonsGroup';

export const AUTOCOMPLETE_INPUT = 'AUTOCOMPLETE_INPUT';
export const SELECT_INPUT = 'SELECT_INPUT';
export const ASYNC_SELECT_INPUT = 'ASYNC_SELECT_INPUT';
export const ASYNC_SELECT_TREE_INPUT = 'ASYNC_SELECT_TREE_INPUT';
export const SWITCH_INPUT = 'SWITCH_INPUT';
export const DATE_INPUT = 'DATE_INPUT';
export const ASYNC_RADIO_BUTTONS_INPUT = 'ASYNC_RADIO_BUTTONS_INPUT';
export const NUMBER_INPUT = 'number';

export interface InputGuesserInterface {
  //allowEmpty?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  label: string;
  multiline?: boolean;
  name: string;
  onChange?: (e: any, v?: any) => void;
  rows?: number;
  type?: string;
  value?: InputValueInterface | string | boolean | number;
  values?: InputValueInterface[];
  //withFormControl?: boolean;
  error?: boolean;
  helperText?: string;
  isCode?: boolean;
  required?: boolean;
  inputProps?: any;
  promise?: Promise<any>;
  getResult?: (result: any) => any;
  accessorField?: string;
  childrenField?: string;
  labelField?: string;
  freeSolo?: boolean;
}

export const InputGuesser: React.FC<InputGuesserInterface> = ({
  disabled,
  type,
  value,
  isCode,

  promise,
  getResult,
  accessorField,
  childrenField,
  labelField,

  ...rest
}) => {
  switch (type) {
    case AUTOCOMPLETE_INPUT: {
      const { label, onChange, values } = rest;

      return (
        <Autocomplete
          options={values ?? []}
          /*value={{
            ...rest.values?.find(
              item => (value as InputValueInterface)?.value === item.value
            ),
          }}*/
          isOptionEqualToValue={(
            o: InputValueInterface,
            v: InputValueInterface
          ) => o.value === (v.value ?? '')}
          onChange={onChange}
          getOptionLabel={(option) =>
            (option as InputValueInterface).label ?? ''
          }
          renderInput={(params) => (
            <InputGuesser
              {...{ ...params, label }}
              name=""
              onChange={() => null}
            />
          )}
        />
      );
    }
    case SELECT_INPUT: {
      return (
        <SelectBox
          {...rest}
          value={value?.toString() ?? ''}
          disabled={disabled}>
          {(rest.values || []).map(({ label, value }, index) => (
            <MenuItem value={value || ''} key={index}>
              {label}
            </MenuItem>
          ))}
        </SelectBox>
      );
    }
    case ASYNC_SELECT_INPUT: {
      const { onChange, label, required, helperText, freeSolo, error } = rest;

      const fetchDatas = async (): Promise<InputValueInterface[]> => {
        const result = await promise;

        return getResult
          ? getResult(result).map((r: any) => ({
              label: r[labelField as string],
              value: r[accessorField as string],
            }))
          : [];
      };

      return (
        <AsyncAutocomplete
          error={error}
          required={required}
          helperText={helperText}
          getRessources={fetchDatas}
          label={label}
          onChange={onChange as (value: any) => void}
          value={value?.toString() ?? ''}
          freeSolo={freeSolo}
        />
      );
    }
    case ASYNC_SELECT_TREE_INPUT: {
      const { onChange } = rest;

      return (
        <SelectWithTreeView
          {...rest}
          value={value?.toString() ?? ''}
          accessorField={accessorField as string}
          childrenField={childrenField as string}
          labelField={labelField as string}
          getResult={getResult as (result: any) => any}
          promise={promise as Promise<any>}
          onChange={onChange as (value?: string | number) => void}
        />
      );
    }
    case ASYNC_RADIO_BUTTONS_INPUT:
      const { onChange, label, required, helperText, error } = rest;

      return (
        <AsyncRowRadioButtonsGroup
          fullWidth
          error={error}
          required={required}
          helperText={helperText}
          promise={promise}
          getResult={getResult}
          title={label}
          onChange={onChange as (value: any) => void}
          value={value as string | number | undefined}
        />
      );
    case SWITCH_INPUT: {
      /*return rest.label ? (
        <FormControlLabel
          {...rest}
          control={<Switch {...rest} checked={!!value} />}
        />
      ) : (
        <Switch
          {...{
            [!!disabled ? 'checked' : 'defaultChecked']: !!value,
            ...rest,
          }}
        />
        );*/
      return (
        <SwitchComponent
          label={rest.label}
          checked={!!value}
          onChange={rest.onChange}
        />
      );
    }
    case DATE_INPUT: {
      const { onChange, label, required, helperText, error } = rest;

      return (
        <DatePickerComponent
          label={label}
          value={value}
          onChange={(date: any) => {
            if (onChange) {
              onChange(date);
            }
          }}
          error={error}
          required={required}
          helperText={helperText}
        />
      );
    }
    default: {
      return (
        <TextFieldComponent
          {...rest}
          type={type === NUMBER_INPUT ? type : 'text'}
          isCode={isCode}
          fullWidth
          value={value ?? ''}
        />
      );
    }
  }
};
