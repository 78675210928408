import React, { useCallback, useContext } from 'react';
import { Box, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { User } from 'src/api/api-keycloak/model';
import { ButtonComponent } from 'src/components/UI/Button/ButtonComponent';
import Description from './details/Description';
import { DetailContext, ERRORS } from './context';
import { ValidationStateSchema } from 'src/interfaces';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  headerText: {
    minWidth: '150px',
    margin: '0 20px 0 10px',
    textTransform: 'capitalize',
  },
}));

export const hasAttributes = (user: User) => {
  return !!user.attributes && Object.keys(user.attributes).length > 0;
};

const UserForm: React.FC<{
  user: User;
  onSubmit: (data: User) => void;
  onCancel: () => void;
}> = ({ user, onSubmit, onCancel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const translate = (key: string, context?: Record<string, string>) =>
    t(`keycloak.users.form.${key}`, context);

  const { dispatch, data } = useContext(DetailContext);

  const getFormData: () => User = useCallback(() => {
    return { ...user, ...data };
  }, [data, user]);

  const getValidationSchema = useCallback(() => {
    const validationStateSchema: ValidationStateSchema = {
      lastName: {
        required: true,
        error: t('errors.requiredField'),
        maxLength: 450,
      },
      firstName: {
        required: true,
        error: t('errors.requiredField'),
        maxLength: 450,
      },
      username: {
        required: true,
        error: t('errors.requiredField'),
        maxLength: 900,
      },
      userPwd: {
        required: !user.id,
        error: t('errors.requiredField'),
        maxLength: 40,
      },
      userPwdRepeat: {
        required: !user.id,
        error: t('errors.requiredField'),
        maxLength: 40,
      },
      email: {
        required: false,
        error: t('errors.requiredField'),
        maxLength: 1800,
      },
    };

    return validationStateSchema;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateState = useCallback(() => {
    let hasErrorInState = false;
    const errors: Record<string, string> = {};
    let firstErrorOn: string | undefined = undefined;
    for (const key in getValidationSchema()) {
      const isInputFieldRequired: boolean = getValidationSchema()[key].required;

      if (isInputFieldRequired && !getFormData()[key as keyof User]) {
        hasErrorInState = true;
        errors[key] = getValidationSchema()[key].error || '';
        if (!firstErrorOn) {
          firstErrorOn = 'description';
        }
      }
    }

    if (
      (getFormData().userPwd || getFormData().userPwdRepeat) &&
      getFormData().userPwd !== getFormData().userPwdRepeat
    ) {
      hasErrorInState = true;
      errors.userPwd = translate('validation.passwordsDontMatch');
      errors.userPwdRepeat = translate('validation.passwordsDontMatch');
    }

    dispatch({
      payload: { errors: errors, firstErrorOn: firstErrorOn },
      type: ERRORS,
    });

    return !hasErrorInState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFormData(), getValidationSchema]);

  const onSaveClick = () => {
    if (validateState()) {
      if (!!user.id) {
        onSubmit(data);
      } else {
        onSubmit(getFormData());
      }
    }
  };

  const onCancelClick = () => {
    onCancel();
  };

  return (
    <Paper>
      <Box p={3}>
        <Box>
          <Description
            user={user}
            validationStateSchema={getValidationSchema()}
            invitationMode
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" mt={4}>
          <ButtonComponent
            className={classes.button}
            icon="close"
            label="button.cancel"
            onClick={onCancelClick}
          />
          <ButtonComponent
            className={classes.button}
            icon="save"
            label="button.save"
            type="submit"
            disabled={Object.entries(data).length === 0}
            onClick={onSaveClick}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default UserForm;
