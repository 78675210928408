/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Configuration } from 'src/api/api-gc/configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';
import {
  CatalogAccessory,
  CatalogBom,
  CatalogFault,
  CatalogParam,
  CatalogParamValue,
  CatalogProduct,
  CatalogProductLnGroup,
  CatalogReference,
  CatalogSymptom,
  DatecodePattern,
  Manufacturer,
  ManufacturerFilter,
  ManufacturerLnGroup,
  ManufacturerParam,
  ManufacturerParamValue,
  SearchCatalogAccessoriesResponse,
  SearchCatalogBomsResponse,
  SearchCatalogFaultsResponse,
  SearchCatalogParamsResponse,
  SearchCatalogProductsResponse,
  SearchCatalogReferencesResponse,
  SearchCatalogSymptomsResponse,
  SearchDatecodePatternsResponse,
  SearchManufacturerParamsResponse,
  SearchManufacturersResponse,
  SearchSlaThresholdResponse,
  SlaThreshold,
} from 'src/api/api-gc/model';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APICatalogService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates a catalogAccessory
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createCatalogAccessory(
    token: string,
    orgCode: string,
    body?: CatalogAccessory,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogAccessory>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createCatalogAccessory.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createCatalogAccessory.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<CatalogAccessory>(
      `${this.basePath}/catalog/accessories`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a catalogBom
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createCatalogBom(
    token: string,
    orgCode: string,
    body?: CatalogBom,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogBom>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createCatalogBom.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createCatalogBom.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<CatalogBom>(`${this.basePath}/catalog/boms`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates a catalogFault
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createCatalogFault(
    token: string,
    orgCode: string,
    body?: CatalogFault,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogFault>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createCatalogFault.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createCatalogFault.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<CatalogFault>(`${this.basePath}/catalog/faults`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates a catalogParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createCatalogParam(
    token: string,
    orgCode: string,
    body?: CatalogParam,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createCatalogParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createCatalogParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<CatalogParam>(
      `${this.basePath}/catalog/productParams`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a catalogParamValue
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productId
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public createCatalogParamValue(
    token: string,
    orgCode: string,
    productId: number,
    paramId: number,
    body?: CatalogParamValue,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogParamValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createCatalogParamValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createCatalogParamValue.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling createCatalogParamValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling createCatalogParamValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<CatalogParamValue>(
      `${this.basePath}/catalog/products/${encodeURIComponent(
        String(productId)
      )}/params/${encodeURIComponent(String(paramId))}/paramValues`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a catalogProduct
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createCatalogProduct(
    token: string,
    orgCode: string,
    body?: CatalogProduct,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogProduct>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createCatalogProduct.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createCatalogProduct.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<CatalogProduct>(
      `${this.basePath}/catalog/products`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Add a group to catalogProduct
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productId
   * @param body
   * @param mapLevel
   */
  public createCatalogProductGroup(
    token: string,
    orgCode: string,
    productId: number,
    body?: CatalogProductLnGroup,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogProductLnGroup>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createCatalogProductGroup.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createCatalogProductGroup.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling createCatalogProductGroup.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<CatalogProductLnGroup>(
      `${this.basePath}/catalog/products/${encodeURIComponent(
        String(productId)
      )}/groups`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a catalogReference
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createCatalogReference(
    token: string,
    orgCode: string,
    body?: CatalogReference,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogReference>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createCatalogReference.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createCatalogReference.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<CatalogReference>(
      `${this.basePath}/catalog/references`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a catalogSymptom
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createCatalogSymptom(
    token: string,
    orgCode: string,
    body?: CatalogSymptom,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogSymptom>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createCatalogSymptom.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createCatalogSymptom.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<CatalogSymptom>(
      `${this.basePath}/catalog/symptoms`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a manufacturer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createManufacturer(
    token: string,
    orgCode: string,
    body?: Manufacturer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Manufacturer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createManufacturer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createManufacturer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<Manufacturer>(
      `${this.basePath}/catalog/manufacturers`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a manufacturerValueFilter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param body
   * @param mapLevel
   */
  public createManufacturerFilter(
    token: string,
    orgCode: string,
    manufacturerId: number,
    body?: ManufacturerFilter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ManufacturerFilter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createManufacturerFilter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createManufacturerFilter.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling createManufacturerFilter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<ManufacturerFilter>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/filters`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Add a group to manufacturer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param body
   * @param mapLevel
   */
  public createManufacturerGroup(
    token: string,
    orgCode: string,
    manufacturerId: number,
    body?: ManufacturerLnGroup,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ManufacturerLnGroup>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createManufacturerGroup.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createManufacturerGroup.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling createManufacturerGroup.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<ManufacturerLnGroup>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/groups`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a manufacturerParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createManufacturerParam(
    token: string,
    orgCode: string,
    body?: ManufacturerParam,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ManufacturerParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createManufacturerParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createManufacturerParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<ManufacturerParam>(
      `${this.basePath}/catalog/manufacturerParams`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a manufacturerValueParamValue
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public createManufacturerParamValue(
    token: string,
    orgCode: string,
    manufacturerId: number,
    paramId: number,
    body?: ManufacturerParamValue,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ManufacturerParamValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createManufacturerParamValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createManufacturerParamValue.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling createManufacturerParamValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling createManufacturerParamValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<ManufacturerParamValue>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/params/${encodeURIComponent(String(paramId))}/paramValues`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the catalogAccessory
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param accId
   * @param mapLevel
   */
  public getCatalogAccessory(
    token: string,
    orgCode: string,
    accId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogAccessory>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogAccessory.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogAccessory.'
      );
    }

    if (accId === null || accId === undefined) {
      throw new Error(
        'Required parameter accId was null or undefined when calling getCatalogAccessory.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<CatalogAccessory>(
      `${this.basePath}/catalog/accessories/${encodeURIComponent(
        String(accId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get catalogAccessories
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param accListValueId
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param productCategoryItemId
   * @param productId
   */
  public getCatalogAccessories(
    token: string,
    orgCode: string,
    accListValueId: number,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    productCategoryItemId?: number,
    productId?: number
  ): Promise<AxiosResponse<SearchCatalogAccessoriesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogAccessorys.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogAccessorys.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (accListValueId !== undefined && accListValueId !== null) {
      queryParameters.append('accListValueId', accListValueId as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (productCategoryItemId !== undefined && productCategoryItemId !== null) {
      queryParameters.append(
        'productCategoryItemId',
        productCategoryItemId as any
      );
    }
    if (productId !== undefined && productId !== null) {
      queryParameters.append('productId', productId as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchCatalogAccessoriesResponse>(
      `${this.basePath}/catalog/accessories`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the catalogBom
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param bomId
   * @param mapLevel
   */
  public getCatalogBom(
    token: string,
    orgCode: string,
    bomId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogBom>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogBom.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogBom.'
      );
    }

    if (bomId === null || bomId === undefined) {
      throw new Error(
        'Required parameter bomId was null or undefined when calling getCatalogBom.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<CatalogBom>(
      `${this.basePath}/catalog/boms/${encodeURIComponent(String(bomId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get catalogBoms
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param rootCatalogProductId
   * @param manufacturerCode
   * @param partTextIndex
   */
  public getCatalogBoms(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    rootCatalogProductId?: number,
    manufacturerCode?: string,
    partTextIndex?: string
  ): Promise<AxiosResponse<SearchCatalogBomsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogBoms.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogBoms.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (rootCatalogProductId !== undefined && rootCatalogProductId !== null) {
      queryParameters.append(
        'rootCatalogProductId',
        rootCatalogProductId as any
      );
    }
    if (manufacturerCode !== undefined && manufacturerCode !== null) {
      queryParameters.append('manufacturerCode', manufacturerCode as any);
    }
    if (partTextIndex !== undefined && partTextIndex !== null) {
      queryParameters.append('partTextIndex', partTextIndex as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchCatalogBomsResponse>(
      `${this.basePath}/catalog/boms`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the catalogFault
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param faultId
   * @param mapLevel
   */
  public getCatalogFault(
    token: string,
    orgCode: string,
    faultId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogFault>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogFault.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogFault.'
      );
    }

    if (faultId === null || faultId === undefined) {
      throw new Error(
        'Required parameter faultId was null or undefined when calling getCatalogFault.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<CatalogFault>(
      `${this.basePath}/catalog/faults/${encodeURIComponent(String(faultId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get catalogFaults
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param faultListValueId
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   */
  public getCatalogFaults(
    token: string,
    orgCode: string,
    faultListValueId?: number,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean
  ): Promise<AxiosResponse<SearchCatalogFaultsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogFaults.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogFaults.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (faultListValueId !== undefined && faultListValueId !== null) {
      queryParameters.append('faultListValueId', faultListValueId as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchCatalogFaultsResponse>(
      `${this.basePath}/catalog/faults`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the catalogParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param mapLevel
   */
  public getCatalogParam(
    token: string,
    orgCode: string,
    paramId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling getCatalogParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<CatalogParam>(
      `${this.basePath}/catalog/productParams/${encodeURIComponent(
        String(paramId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get catalogParams
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   */
  public getCatalogParams(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    text?: string
  ): Promise<AxiosResponse<SearchCatalogParamsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogParams.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogParams.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (text !== undefined && text !== null) {
      queryParameters.append('text', text as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchCatalogParamsResponse>(
      `${this.basePath}/catalog/productParams`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the catalogProduct
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productId
   * @param mapLevel
   */
  public getCatalogProduct(
    token: string,
    orgCode: string,
    productId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogProduct>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogProduct.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogProduct.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling getCatalogProduct.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<CatalogProduct>(
      `${this.basePath}/catalog/products/${encodeURIComponent(
        String(productId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get catalogProducts
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param lastCreationDate
   * @param categoryCode
   * @param searchCategoryHierarchy
   * @param manufacturerId
   * @param manufacturerCode
   * @param productCode
   * @param modelCode
   * @param referenceType
   * @param referenceCode
   * @param referenceName
   * @param isNew
   * @param isSerialized
   * @param isPublished
   * @param isModel
   * @param catalogGroups
   * @param productCategoryItemId
   * @param productPlatform
   * @param productName
   * @param productUuid
   * @param productId
   * @param isPart
   * @param hierarchyLevel
   * @param asc
   * @param desc
   */
  public getCatalogProducts(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    lastCreationDate?: Date,
    categoryCode?: string,
    searchCategoryHierarchy?: boolean,
    manufacturerId?: number,
    manufacturerCode?: string,
    productCode?: string,
    modelCode?: string,
    referenceType?: string,
    referenceCode?: string,
    referenceName?: string,
    isNew?: boolean,
    isSerialized?: boolean,
    isPublished?: boolean,
    isModel?: boolean,
    catalogGroups?: Array<string>,
    productCategoryItemId?: number,
    productPlatform?: string,
    productName?: string,
    productUuid?: string,
    productId?: number,
    isPart?: boolean,
    hierarchyLevel?: number,
    asc?: Array<string>,
    desc?: Array<string>
  ): Promise<AxiosResponse<SearchCatalogProductsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogProducts.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogProducts.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (lastCreationDate !== undefined && lastCreationDate !== null) {
      queryParameters.append(
        'lastCreationDate',
        lastCreationDate.toISOString()
      );
    }
    if (categoryCode !== undefined && categoryCode !== null) {
      queryParameters.append('categoryCode', categoryCode as any);
    }
    if (
      searchCategoryHierarchy !== undefined &&
      searchCategoryHierarchy !== null
    ) {
      queryParameters.append(
        'searchCategoryHierarchy',
        searchCategoryHierarchy as any
      );
    }
    if (manufacturerId !== undefined && manufacturerId !== null) {
      queryParameters.append('manufacturerId', manufacturerId as any);
    }
    if (manufacturerCode !== undefined && manufacturerCode !== null) {
      queryParameters.append('manufacturerCode', manufacturerCode as any);
    }
    if (productCode !== undefined && productCode !== null) {
      queryParameters.append('productCode', productCode as any);
    }
    if (modelCode !== undefined && modelCode !== null) {
      queryParameters.append('modelCode', modelCode as any);
    }
    if (referenceType !== undefined && referenceType !== null) {
      queryParameters.append('referenceType', referenceType as any);
    }
    if (referenceCode !== undefined && referenceCode !== null) {
      queryParameters.append('referenceCode', referenceCode as any);
    }
    if (referenceName !== undefined && referenceName !== null) {
      queryParameters.append('referenceName', referenceName as any);
    }
    if (isNew !== undefined && isNew !== null) {
      queryParameters.append('isNew', isNew as any);
    }
    if (isSerialized !== undefined && isSerialized !== null) {
      queryParameters.append('isSerialized', isSerialized as any);
    }
    if (isPublished !== undefined && isPublished !== null) {
      queryParameters.append('isPublished', isPublished as any);
    }
    if (isModel !== undefined && isModel !== null) {
      queryParameters.append('isModel', isModel as any);
    }
    if (catalogGroups) {
      catalogGroups.forEach((element) => {
        queryParameters.append('catalogGroups', element as any);
      });
    }
    if (productCategoryItemId !== undefined && productCategoryItemId !== null) {
      queryParameters.append(
        'productCategoryItemId',
        productCategoryItemId as any
      );
    }
    if (productPlatform !== undefined && productPlatform !== null) {
      queryParameters.append('productPlatform', productPlatform as any);
    }
    if (productName !== undefined && productName !== null) {
      queryParameters.append('productName', productName as any);
    }
    if (productUuid !== undefined && productUuid !== null) {
      queryParameters.append('productUuid', productUuid as any);
    }
    if (productId !== undefined && productId !== null) {
      queryParameters.append('productId', productId as any);
    }
    if (isPart !== undefined && isPart !== null) {
      queryParameters.append('isPart', isPart as any);
    }
    if (hierarchyLevel !== undefined && hierarchyLevel !== null) {
      queryParameters.append('hierarchyLevel', hierarchyLevel as any);
    }

    if (asc) {
      asc.forEach((element) => {
        queryParameters.append('asc', element as any);
      });
    }
    if (desc) {
      desc.forEach((element) => {
        queryParameters.append('desc', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchCatalogProductsResponse>(
      `${this.basePath}/catalog/products`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the catalogReference
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param referenceId
   * @param mapLevel
   */
  public getCatalogReference(
    token: string,
    orgCode: string,
    referenceId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogReference>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogReference.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogReference.'
      );
    }

    if (referenceId === null || referenceId === undefined) {
      throw new Error(
        'Required parameter referenceId was null or undefined when calling getCatalogReference.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<CatalogReference>(
      `${this.basePath}/catalog/references/${encodeURIComponent(
        String(referenceId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get catalogReferences
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param referenceType
   * @param referenceCode
   * @param referenceName
   */
  public getCatalogReferences(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    referenceType?: string,
    referenceCode?: string,
    referenceName?: string
  ): Promise<AxiosResponse<SearchCatalogReferencesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogReferences.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogReferences.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (referenceType !== undefined && referenceType !== null) {
      queryParameters.append('referenceType', referenceType);
    }
    if (referenceCode !== undefined && referenceCode !== null) {
      queryParameters.append('referenceCode', referenceCode);
    }
    if (referenceName !== undefined && referenceName !== null) {
      queryParameters.append('referenceName', referenceName);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchCatalogReferencesResponse>(
      `${this.basePath}/catalog/references`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the catalogSymptom
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param symptomId
   * @param mapLevel
   */
  public getCatalogSymptom(
    token: string,
    orgCode: string,
    symptomId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogSymptom>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogSymptom.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogSymptom.'
      );
    }

    if (symptomId === null || symptomId === undefined) {
      throw new Error(
        'Required parameter symptomId was null or undefined when calling getCatalogSymptom.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<CatalogSymptom>(
      `${this.basePath}/catalog/symptoms/${encodeURIComponent(
        String(symptomId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get catalogSymptoms
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param symptomListValueId
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   */
  public getCatalogSymptoms(
    token: string,
    orgCode: string,
    symptomListValueId?: number,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean
  ): Promise<AxiosResponse<SearchCatalogSymptomsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogSymptoms.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogSymptoms.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (symptomListValueId !== undefined && symptomListValueId !== null) {
      queryParameters.append('symptomListValueId', symptomListValueId as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchCatalogSymptomsResponse>(
      `${this.basePath}/catalog/symptoms`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the manufacturer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param mapLevel
   */
  public getManufacturer(
    token: string,
    orgCode: string,
    manufacturerId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Manufacturer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getManufacturer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getManufacturer.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling getManufacturer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<Manufacturer>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the manufacturerValueFilter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param filterId
   * @param mapLevel
   */
  public getManufacturerFilter(
    token: string,
    orgCode: string,
    manufacturerId: number,
    filterId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ManufacturerFilter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getManufacturerFilter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getManufacturerFilter.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling getManufacturerFilter.'
      );
    }

    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling getManufacturerFilter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<ManufacturerFilter>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/filters/${encodeURIComponent(String(filterId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the manufacturerParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerParamId
   * @param mapLevel
   */
  public getManufacturerParam(
    token: string,
    orgCode: string,
    manufacturerParamId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ManufacturerParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getManufacturerParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getManufacturerParam.'
      );
    }

    if (manufacturerParamId === null || manufacturerParamId === undefined) {
      throw new Error(
        'Required parameter manufacturerParamId was null or undefined when calling getManufacturerParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<ManufacturerParam>(
      `${this.basePath}/catalog/manufacturerParams/${encodeURIComponent(
        String(manufacturerParamId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get manufacturerParams
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param paramName
   * @param paramType
   * @param paramMandatory
   * @param paramMultiple
   */
  public getManufacturerParams(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    paramName?: string,
    paramType?: string,
    paramMandatory?: boolean,
    paramMultiple?: boolean
  ): Promise<AxiosResponse<SearchManufacturerParamsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getManufacturerParams.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getManufacturerParams.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (paramName !== undefined && paramName !== null) {
      queryParameters.append('paramName', paramName);
    }
    if (paramType !== undefined && paramType !== null) {
      queryParameters.append('paramType', paramType);
    }
    if (paramMandatory !== undefined && paramMandatory !== null) {
      queryParameters.append('paramMandatory', paramMandatory as any);
    }
    if (paramMultiple !== undefined && paramMultiple !== null) {
      queryParameters.append('paramMultiple', paramMultiple as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchManufacturerParamsResponse>(
      `${this.basePath}/catalog/manufacturerParams`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get manufacturers
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param categoryItemId
   * @param manufacturerCode
   * @param manufacturerId
   * @param manufacturerGroup
   */
  public getManufacturers(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    categoryItemId?: number,
    manufacturerCode?: string,
    manufacturerId?: Array<number>,
    manufacturerGroup?: Array<string>
  ): Promise<AxiosResponse<SearchManufacturersResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getManufacturers.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getManufacturers.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (categoryItemId !== undefined && categoryItemId !== null) {
      queryParameters.append('categoryItemId', categoryItemId as any);
    }
    if (manufacturerCode !== undefined && manufacturerCode !== null) {
      queryParameters.append('manufacturerCode', manufacturerCode as any);
    }
    if (manufacturerId) {
      manufacturerId.forEach((element) => {
        queryParameters.append('manufacturerId', element as any);
      });
    }
    if (manufacturerGroup) {
      manufacturerGroup.forEach((element) => {
        queryParameters.append('manufacturerGroup', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchManufacturersResponse>(
      `${this.basePath}/catalog/manufacturers`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a catalogAccessory
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param accId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyCatalogAccessory(
    token: string,
    orgCode: string,
    accId: number,
    body?: CatalogAccessory,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<CatalogAccessory>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyCatalogAccessory.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyCatalogAccessory.'
      );
    }

    if (accId === null || accId === undefined) {
      throw new Error(
        'Required parameter accId was null or undefined when calling modifyCatalogAccessory.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<CatalogAccessory>(
      `${this.basePath}/catalog/accessories/${encodeURIComponent(
        String(accId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a catalogBom
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param bomId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyCatalogBom(
    token: string,
    orgCode: string,
    bomId: number,
    body?: CatalogBom,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<CatalogBom>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyCatalogBom.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyCatalogBom.'
      );
    }

    if (bomId === null || bomId === undefined) {
      throw new Error(
        'Required parameter bomId was null or undefined when calling modifyCatalogBom.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<CatalogBom>(
      `${this.basePath}/catalog/boms/${encodeURIComponent(String(bomId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a catalogFault
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param faultId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyCatalogFault(
    token: string,
    orgCode: string,
    faultId: number,
    body?: CatalogFault,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<CatalogFault>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyCatalogFault.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyCatalogFault.'
      );
    }

    if (faultId === null || faultId === undefined) {
      throw new Error(
        'Required parameter faultId was null or undefined when calling modifyCatalogFault.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<CatalogFault>(
      `${this.basePath}/catalog/faults/${encodeURIComponent(String(faultId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a catalogParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyCatalogParam(
    token: string,
    orgCode: string,
    paramId: number,
    body?: CatalogParam,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<CatalogParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyCatalogParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyCatalogParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyCatalogParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<CatalogParam>(
      `${this.basePath}/catalog/productParams/${encodeURIComponent(
        String(paramId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a catalogParamValue
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productId
   * @param paramId
   * @param paramValueId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyCatalogParamValue(
    token: string,
    orgCode: string,
    productId: number,
    paramId: number,
    paramValueId: number,
    body?: CatalogParamValue,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<CatalogParamValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyCatalogParamValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyCatalogParamValue.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling modifyCatalogParamValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyCatalogParamValue.'
      );
    }

    if (paramValueId === null || paramValueId === undefined) {
      throw new Error(
        'Required parameter paramValueId was null or undefined when calling modifyCatalogParamValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<CatalogParamValue>(
      `${this.basePath}/catalog/products/${encodeURIComponent(
        String(productId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/paramValues/${encodeURIComponent(String(paramValueId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a catalogProduct
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyCatalogProduct(
    token: string,
    orgCode: string,
    productId: number,
    body?: CatalogProduct,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<CatalogProduct>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyCatalogProduct.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyCatalogProduct.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling modifyCatalogProduct.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<CatalogProduct>(
      `${this.basePath}/catalog/products/${encodeURIComponent(
        String(productId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a catalogReference
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param referenceId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyCatalogReference(
    token: string,
    orgCode: string,
    referenceId: number,
    body?: CatalogReference,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<CatalogReference>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyCatalogReference.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyCatalogReference.'
      );
    }

    if (referenceId === null || referenceId === undefined) {
      throw new Error(
        'Required parameter referenceId was null or undefined when calling modifyCatalogReference.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<CatalogReference>(
      `${this.basePath}/catalog/references/${encodeURIComponent(
        String(referenceId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a catalogSymptom
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param symptomId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyCatalogSymptom(
    token: string,
    orgCode: string,
    symptomId: number,
    body?: CatalogSymptom,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<CatalogSymptom>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyCatalogSymptom.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyCatalogSymptom.'
      );
    }

    if (symptomId === null || symptomId === undefined) {
      throw new Error(
        'Required parameter symptomId was null or undefined when calling modifyCatalogSymptom.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<CatalogSymptom>(
      `${this.basePath}/catalog/symptoms/${encodeURIComponent(
        String(symptomId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a manufacturer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyManufacturer(
    token: string,
    orgCode: string,
    manufacturerId: number,
    body?: Manufacturer,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<Manufacturer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyManufacturer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyManufacturer.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling modifyManufacturer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<Manufacturer>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a manufacturerValueFilter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param filterId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyManufacturerFilter(
    token: string,
    orgCode: string,
    manufacturerId: number,
    filterId: number,
    body?: ManufacturerFilter,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ManufacturerFilter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyManufacturerFilter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyManufacturerFilter.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling modifyManufacturerFilter.'
      );
    }

    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling modifyManufacturerFilter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<ManufacturerFilter>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/filters/${encodeURIComponent(String(filterId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a manufacturerParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerParamId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyManufacturerParam(
    token: string,
    orgCode: string,
    manufacturerParamId: number,
    body?: ManufacturerParam,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ManufacturerParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyManufacturerParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyManufacturerParam.'
      );
    }

    if (manufacturerParamId === null || manufacturerParamId === undefined) {
      throw new Error(
        'Required parameter manufacturerParamId was null or undefined when calling modifyManufacturerParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<ManufacturerParam>(
      `${this.basePath}/catalog/manufacturerParams/${encodeURIComponent(
        String(manufacturerParamId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a manufacturerValueParamValue
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param paramId
   * @param paramValueId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyManufacturerParamValue(
    token: string,
    orgCode: string,
    manufacturerId: number,
    paramId: number,
    paramValueId: number,
    body?: ManufacturerParamValue,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ManufacturerParamValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyManufacturerParamValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyManufacturerParamValue.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling modifyManufacturerParamValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyManufacturerParamValue.'
      );
    }

    if (paramValueId === null || paramValueId === undefined) {
      throw new Error(
        'Required parameter paramValueId was null or undefined when calling modifyManufacturerParamValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<ManufacturerParamValue>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/paramValues/${encodeURIComponent(String(paramValueId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a catalogAccessory
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param accId
   */
  public removeCatalogAccessory(
    token: string,
    orgCode: string,
    accId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCatalogAccessory.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCatalogAccessory.'
      );
    }

    if (accId === null || accId === undefined) {
      throw new Error(
        'Required parameter accId was null or undefined when calling removeCatalogAccessory.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/accessories/${encodeURIComponent(
        String(accId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a catalogBom
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param bomId
   */
  public removeCatalogBom(
    token: string,
    orgCode: string,
    bomId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCatalogBom.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCatalogBom.'
      );
    }

    if (bomId === null || bomId === undefined) {
      throw new Error(
        'Required parameter bomId was null or undefined when calling removeCatalogBom.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/boms/${encodeURIComponent(String(bomId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a catalogFault
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param faultId
   */
  public removeCatalogFault(
    token: string,
    orgCode: string,
    faultId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCatalogFault.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCatalogFault.'
      );
    }

    if (faultId === null || faultId === undefined) {
      throw new Error(
        'Required parameter faultId was null or undefined when calling removeCatalogFault.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/faults/${encodeURIComponent(String(faultId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a catalogParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   */
  public removeCatalogParam(
    token: string,
    orgCode: string,
    paramId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCatalogParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCatalogParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling removeCatalogParam.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/productParams/${encodeURIComponent(
        String(paramId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a catalogParamValue
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productId
   * @param paramId
   * @param paramValueId
   */
  public removeCatalogParamValue(
    token: string,
    orgCode: string,
    productId: number,
    paramId: number,
    paramValueId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCatalogParamValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCatalogParamValue.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling removeCatalogParamValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling removeCatalogParamValue.'
      );
    }

    if (paramValueId === null || paramValueId === undefined) {
      throw new Error(
        'Required parameter paramValueId was null or undefined when calling removeCatalogParamValue.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/products/${encodeURIComponent(
        String(productId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/paramValues/${encodeURIComponent(String(paramValueId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a catalogProduct
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productId
   */
  public removeCatalogProduct(
    token: string,
    orgCode: string,
    productId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCatalogProduct.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCatalogProduct.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling removeCatalogProduct.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/products/${encodeURIComponent(
        String(productId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a catalogProductLnGroup
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productId
   * @param groupCode
   */
  public removeCatalogProductGroup(
    token: string,
    orgCode: string,
    productId: number,
    groupCode: string
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCatalogProductGroup.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCatalogProductGroup.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling removeCatalogProductGroup.'
      );
    }

    if (groupCode === null || groupCode === undefined) {
      throw new Error(
        'Required parameter groupCode was null or undefined when calling removeCatalogProductGroup.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/products/${encodeURIComponent(
        String(productId)
      )}/groups/${encodeURIComponent(String(groupCode))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a catalogReference
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param referenceId
   */
  public removeCatalogReference(
    token: string,
    orgCode: string,
    referenceId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCatalogReference.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCatalogReference.'
      );
    }

    if (referenceId === null || referenceId === undefined) {
      throw new Error(
        'Required parameter referenceId was null or undefined when calling removeCatalogReference.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/references/${encodeURIComponent(
        String(referenceId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a catalogSymptom
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param symptomId
   */
  public removeCatalogSymptom(
    token: string,
    orgCode: string,
    symptomId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCatalogSymptom.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCatalogSymptom.'
      );
    }

    if (symptomId === null || symptomId === undefined) {
      throw new Error(
        'Required parameter symptomId was null or undefined when calling removeCatalogSymptom.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/symptoms/${encodeURIComponent(
        String(symptomId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a manufacturer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   */
  public removeManufacturer(
    token: string,
    orgCode: string,
    manufacturerId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeManufacturer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeManufacturer.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling removeManufacturer.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a manufacturerValueFilter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param filterId
   */
  public removeManufacturerFilter(
    token: string,
    orgCode: string,
    manufacturerId: number,
    filterId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeManufacturerFilter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeManufacturerFilter.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling removeManufacturerFilter.'
      );
    }

    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling removeManufacturerFilter.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/filters/${encodeURIComponent(String(filterId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a manufacturerLnGroup
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param groupCode
   */
  public removeManufacturerGroup(
    token: string,
    orgCode: string,
    manufacturerId: number,
    groupCode: string
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeManufacturerGroup.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeManufacturerGroup.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling removeManufacturerGroup.'
      );
    }

    if (groupCode === null || groupCode === undefined) {
      throw new Error(
        'Required parameter groupCode was null or undefined when calling removeManufacturerGroup.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/groups/${encodeURIComponent(String(groupCode))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a manufacturerParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerParamId
   */
  public removeManufacturerParam(
    token: string,
    orgCode: string,
    manufacturerParamId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeManufacturerParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeManufacturerParam.'
      );
    }

    if (manufacturerParamId === null || manufacturerParamId === undefined) {
      throw new Error(
        'Required parameter manufacturerParamId was null or undefined when calling removeManufacturerParam.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/manufacturerParams/${encodeURIComponent(
        String(manufacturerParamId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a manufacturerValueParamValue
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param paramId
   * @param paramValueId
   */
  public removeManufacturerParamValue(
    token: string,
    orgCode: string,
    manufacturerId: number,
    paramId: number,
    paramValueId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeManufacturerParamValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeManufacturerParamValue.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling removeManufacturerParamValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling removeManufacturerParamValue.'
      );
    }

    if (paramValueId === null || paramValueId === undefined) {
      throw new Error(
        'Required parameter paramValueId was null or undefined when calling removeManufacturerParamValue.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/paramValues/${encodeURIComponent(String(paramValueId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a catalogAccessory
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param accId
   * @param body
   * @param mapLevel
   */
  public replaceCatalogAccessory(
    token: string,
    orgCode: string,
    accId: number,
    body?: CatalogAccessory,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogAccessory>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceCatalogAccessory.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceCatalogAccessory.'
      );
    }

    if (accId === null || accId === undefined) {
      throw new Error(
        'Required parameter accId was null or undefined when calling replaceCatalogAccessory.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<CatalogAccessory>(
      `${this.basePath}/catalog/accessories/${encodeURIComponent(
        String(accId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a catalogBom
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param bomId
   * @param body
   * @param mapLevel
   */
  public replaceCatalogBom(
    token: string,
    orgCode: string,
    bomId: number,
    body?: CatalogBom,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogBom>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceCatalogBom.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceCatalogBom.'
      );
    }

    if (bomId === null || bomId === undefined) {
      throw new Error(
        'Required parameter bomId was null or undefined when calling replaceCatalogBom.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<CatalogBom>(
      `${this.basePath}/catalog/boms/${encodeURIComponent(String(bomId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a catalogFault
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param faultId
   * @param body
   * @param mapLevel
   */
  public replaceCatalogFault(
    token: string,
    orgCode: string,
    faultId: number,
    body?: CatalogFault,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogFault>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceCatalogFault.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceCatalogFault.'
      );
    }

    if (faultId === null || faultId === undefined) {
      throw new Error(
        'Required parameter faultId was null or undefined when calling replaceCatalogFault.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<CatalogFault>(
      `${this.basePath}/catalog/faults/${encodeURIComponent(String(faultId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a catalogParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public replaceCatalogParam(
    token: string,
    orgCode: string,
    paramId: number,
    body?: CatalogParam,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceCatalogParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceCatalogParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling replaceCatalogParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<CatalogParam>(
      `${this.basePath}/catalog/productParams/${encodeURIComponent(
        String(paramId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a catalogParamValue
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productId
   * @param paramId
   * @param paramValueId
   * @param body
   * @param mapLevel
   */
  public replaceCatalogParamValue(
    token: string,
    orgCode: string,
    productId: number,
    paramId: number,
    paramValueId: number,
    body?: CatalogParamValue,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogParamValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceCatalogParamValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceCatalogParamValue.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling replaceCatalogParamValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling replaceCatalogParamValue.'
      );
    }

    if (paramValueId === null || paramValueId === undefined) {
      throw new Error(
        'Required parameter paramValueId was null or undefined when calling replaceCatalogParamValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<CatalogParamValue>(
      `${this.basePath}/catalog/products/${encodeURIComponent(
        String(productId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/paramValues/${encodeURIComponent(String(paramValueId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a catalogProduct
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productId
   * @param body
   * @param mapLevel
   */
  public replaceCatalogProduct(
    token: string,
    orgCode: string,
    productId: number,
    body?: CatalogProduct,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogProduct>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceCatalogProduct.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceCatalogProduct.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling replaceCatalogProduct.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<CatalogProduct>(
      `${this.basePath}/catalog/products/${encodeURIComponent(
        String(productId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a catalogReference
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param referenceId
   * @param body
   * @param mapLevel
   */
  public replaceCatalogReference(
    token: string,
    orgCode: string,
    referenceId: number,
    body?: CatalogReference,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogReference>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceCatalogReference.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceCatalogReference.'
      );
    }

    if (referenceId === null || referenceId === undefined) {
      throw new Error(
        'Required parameter referenceId was null or undefined when calling replaceCatalogReference.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<CatalogReference>(
      `${this.basePath}/catalog/references/${encodeURIComponent(
        String(referenceId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a catalogSymptom
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param symptomId
   * @param body
   * @param mapLevel
   */
  public replaceCatalogSymptom(
    token: string,
    orgCode: string,
    symptomId: number,
    body?: CatalogSymptom,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<CatalogSymptom>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceCatalogSymptom.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceCatalogSymptom.'
      );
    }

    if (symptomId === null || symptomId === undefined) {
      throw new Error(
        'Required parameter symptomId was null or undefined when calling replaceCatalogSymptom.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<CatalogSymptom>(
      `${this.basePath}/catalog/symptoms/${encodeURIComponent(
        String(symptomId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a manufacturer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param body
   * @param mapLevel
   */
  public replaceManufacturer(
    token: string,
    orgCode: string,
    manufacturerId: number,
    body?: Manufacturer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Manufacturer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceManufacturer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceManufacturer.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling replaceManufacturer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<Manufacturer>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a manufacturerValueFilter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param filterId
   * @param body
   * @param mapLevel
   */
  public replaceManufacturerFilter(
    token: string,
    orgCode: string,
    manufacturerId: number,
    filterId: number,
    body?: ManufacturerFilter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ManufacturerFilter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceManufacturerFilter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceManufacturerFilter.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling replaceManufacturerFilter.'
      );
    }

    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling replaceManufacturerFilter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<ManufacturerFilter>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/filters/${encodeURIComponent(String(filterId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a manufacturerParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerParamId
   * @param body
   * @param mapLevel
   */
  public replaceManufacturerParam(
    token: string,
    orgCode: string,
    manufacturerParamId: number,
    body?: ManufacturerParam,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ManufacturerParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceManufacturerParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceManufacturerParam.'
      );
    }

    if (manufacturerParamId === null || manufacturerParamId === undefined) {
      throw new Error(
        'Required parameter manufacturerParamId was null or undefined when calling replaceManufacturerParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<ManufacturerParam>(
      `${this.basePath}/catalog/manufacturerParams/${encodeURIComponent(
        String(manufacturerParamId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a manufacturerValueParamValue
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param manufacturerId
   * @param paramId
   * @param paramValueId
   * @param body
   * @param mapLevel
   */
  public replaceManufacturerParamValue(
    token: string,
    orgCode: string,
    manufacturerId: number,
    paramId: number,
    paramValueId: number,
    body?: ManufacturerParamValue,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ManufacturerParamValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceManufacturerParamValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceManufacturerParamValue.'
      );
    }

    if (manufacturerId === null || manufacturerId === undefined) {
      throw new Error(
        'Required parameter manufacturerId was null or undefined when calling replaceManufacturerParamValue.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling replaceManufacturerParamValue.'
      );
    }

    if (paramValueId === null || paramValueId === undefined) {
      throw new Error(
        'Required parameter paramValueId was null or undefined when calling replaceManufacturerParamValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<ManufacturerParamValue>(
      `${this.basePath}/catalog/manufacturers/${encodeURIComponent(
        String(manufacturerId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/paramValues/${encodeURIComponent(String(paramValueId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a datecodePattern
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createDatecodePattern(
    token: string,
    orgCode: string,
    body?: DatecodePattern,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<DatecodePattern>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createDatecodePattern.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createDatecodePattern.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<DatecodePattern>(
      `${this.basePath}/catalog/datecodePattern`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the datecodePattern
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param datecodePatternId
   * @param mapLevel
   */
  public getDatecodePattern(
    token: string,
    orgCode: string,
    datecodePatternId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<DatecodePattern>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getDatecodePattern.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getDatecodePattern.'
      );
    }

    if (datecodePatternId === null || datecodePatternId === undefined) {
      throw new Error(
        'Required parameter datecodePatternId was null or undefined when calling getDatecodePattern.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<DatecodePattern>(
      `${this.basePath}/catalog/datecodePattern/${encodeURIComponent(
        String(datecodePatternId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get datecodePatterns
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param asc
   * @param desc
   * @param orderByIgnoreCase
   * @param datecodeDescription
   * @param datecodeRegex
   * @param datecodeFormat
   * @param datecodeOffset
   * @param datecodeYears
   * @param datecodeMonths
   * @param datecodeDays
   * @param datecodeWeeks
   */
  public getDatecodePatterns(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    asc?: Array<string>,
    desc?: Array<string>,
    orderByIgnoreCase?: boolean,
    datecodeDescription?: string,
    datecodeRegex?: string,
    datecodeFormat?: string,
    datecodeOffset?: string,
    datecodeYears?: string,
    datecodeMonths?: string,
    datecodeDays?: string,
    datecodeWeeks?: string
  ): Promise<AxiosResponse<SearchDatecodePatternsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getDatecodePatterns.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getDatecodePatterns.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (asc) {
      asc.forEach((element) => {
        queryParameters.append('asc', <any>element);
      });
    }
    if (desc) {
      desc.forEach((element) => {
        queryParameters.append('desc', <any>element);
      });
    }
    if (orderByIgnoreCase !== undefined && orderByIgnoreCase !== null) {
      queryParameters.append('orderByIgnoreCase', orderByIgnoreCase as any);
    }
    if (datecodeDescription !== undefined && datecodeDescription !== null) {
      queryParameters.append('datecodeDescription', datecodeDescription as any);
    }
    if (datecodeRegex !== undefined && datecodeRegex !== null) {
      queryParameters.append('datecodeRegex', datecodeRegex as any);
    }
    if (datecodeFormat !== undefined && datecodeFormat !== null) {
      queryParameters.append('datecodeFormat', datecodeFormat as any);
    }
    if (datecodeOffset !== undefined && datecodeOffset !== null) {
      queryParameters.append('datecodeOffset', datecodeOffset as any);
    }
    if (datecodeYears !== undefined && datecodeYears !== null) {
      queryParameters.append('datecodeYears', datecodeYears as any);
    }
    if (datecodeMonths !== undefined && datecodeMonths !== null) {
      queryParameters.append('datecodeMonths', datecodeMonths as any);
    }
    if (datecodeDays !== undefined && datecodeDays !== null) {
      queryParameters.append('datecodeDays', datecodeDays as any);
    }
    if (datecodeWeeks !== undefined && datecodeWeeks !== null) {
      queryParameters.append('datecodeWeeks', datecodeWeeks as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchDatecodePatternsResponse>(
      `${this.basePath}/catalog/datecodePattern`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a datecodePattern
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param datecodePatternId
   * @param body
   * @param mapLevel
   */
  public modifyDatecodePattern(
    token: string,
    orgCode: string,
    datecodePatternId: number,
    body?: DatecodePattern,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<DatecodePattern>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyDatecodePattern.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyDatecodePattern.'
      );
    }

    if (datecodePatternId === null || datecodePatternId === undefined) {
      throw new Error(
        'Required parameter datecodePatternId was null or undefined when calling modifyDatecodePattern.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<DatecodePattern>(
      `${this.basePath}/catalog/datecodePattern/${encodeURIComponent(
        String(datecodePatternId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a datecodePattern
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param datecodePatternId
   */
  public removeDatecodePattern(
    token: string,
    orgCode: string,
    datecodePatternId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeDatecodePattern.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeDatecodePattern.'
      );
    }

    if (datecodePatternId === null || datecodePatternId === undefined) {
      throw new Error(
        'Required parameter datecodePatternId was null or undefined when calling removeDatecodePattern.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/catalog/datecodePattern/${encodeURIComponent(
        String(datecodePatternId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a datecodePattern
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param datecodePatternId
   * @param body
   * @param mapLevel
   */
  public replaceDatecodePattern(
    token: string,
    orgCode: string,
    datecodePatternId: number,
    body?: DatecodePattern,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<DatecodePattern>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceDatecodePattern.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceDatecodePattern.'
      );
    }

    if (datecodePatternId === null || datecodePatternId === undefined) {
      throw new Error(
        'Required parameter datecodePatternId was null or undefined when calling replaceDatecodePattern.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<DatecodePattern>(
      `${this.basePath}/catalog/datecodePattern/${encodeURIComponent(
        String(datecodePatternId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
