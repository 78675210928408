import * as React from 'react';
import { InputValueInterface } from 'src/interfaces';
import RowRadioButtonsGroup, {
  RowRadioButtonsGroupProps,
} from './RadioButtonsGroup';

export interface Props extends RowRadioButtonsGroupProps {
  promise?: Promise<any>;
  getResult?: (result: any) => any;
}

const AsyncRowRadioButtonsGroup: React.FC<Props> = ({
  promise,
  getResult,
  ...rest
}) => {
  const [options, setOptions] = React.useState<InputValueInterface[]>([]);
  //const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    //(async () => {
    if (promise && getResult) {
      promise.then((result: any) => {
        setOptions(getResult(result) || []);
        //setLoading(false);
      });
    } else {
      setOptions([]);
    }
    //})();
  }, [promise, getResult]);

  return <RowRadioButtonsGroup {...rest} values={options} />;
};

export default AsyncRowRadioButtonsGroup;
