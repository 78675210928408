/* tslint:disable:no-unused-variable member-ordering */

import config from 'src/config.js';

import {
  GetOAuthLoginUrl,
  GetOAuthLoginUrlResponse,
  GetOrganizationInfoResponse,
  LoginCheck,
  LoginCheckOAuthResponseType,
  LoginCheckOAuthType,
  LoginCheckResponse,
} from '../model';
import { Configuration } from '../configuration';

import axios, { AxiosResponse } from 'axios';
import { LoginCheck2FA } from '../model/loginCheck2FA';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APISessionService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * disconnect
   *
   * @param orgCode Organization Code
   * @param token
   */
  public disconnect(
    orgCode: string,
    token?: string
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling disconnect.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling disconnect.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    headers['Accept'] =
      this.configuration.selectHeaderAccept(['application/json']) || '';

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.delete(`${this.basePath}/session/disconnect`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * get organization info by code
   *
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getOrganizationInfo(
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<GetOrganizationInfoResponse>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getOrganizationInfo.'
      );
    }

    const params = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        params.append('mapLevel', element);
      });
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get(`${this.basePath}/session/organizationInfo`, {
      params: params,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    }) as Promise<AxiosResponse<GetOrganizationInfoResponse>>;
  }

  /**
   * login
   *
   * @param orgCode Organization Code
   * @param body
   */
  public login(
    orgCode: string,
    body?: LoginCheck
  ): Promise<AxiosResponse<LoginCheckResponse>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling login.'
      );
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post(`${this.basePath}/session/login`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      //validateStatus: validateStatus,
      validateStatus: (status) => {
        return status < 500; // Resolve only if the status code is less than 500
      },
    }) as Promise<AxiosResponse<LoginCheckResponse>>;
  }

  /**
   * get OAuth Login Url
   *
   * @param orgCode Organization Code
   * @param body
   */
  public getOAuthLoginUrl(
    orgCode: string,
    body?: GetOAuthLoginUrl
  ): Promise<AxiosResponse<GetOAuthLoginUrlResponse>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling login.'
      );
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<GetOAuthLoginUrlResponse>(
      `${this.basePath}/session/getOAuthLoginUrl`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        //validateStatus: validateStatus,
        validateStatus: (status) => {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );
  }

  /**
   * login Check OAuth
   *
   * @param orgCode Organization Code
   * @param body
   */
  public loginCheckOAuth(
    orgCode: string,
    body?: LoginCheckOAuthType
  ): Promise<AxiosResponse<LoginCheckOAuthResponseType>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling login.'
      );
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<LoginCheckOAuthResponseType>(
      `${this.basePath}/session/loginCheckOAuth`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        //validateStatus: validateStatus,
        validateStatus: (status) => {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );
  }

  /**
   * Change password
   *
   * @param token
   * @param orgCode Organization Code
   * @param userPasswordOld
   * @param newPassword
   */
  public changePassword(
    token: string,
    orgCode: string,
    userPasswordOld: string,
    newPassword: string
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling changePassword.'
      );
    }
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling changePassword.'
      );
    }
    if (userPasswordOld === null || userPasswordOld === undefined) {
      throw new Error(
        'Required parameter userPasswordOld was null or undefined when calling changePassword.'
      );
    }
    if (newPassword === null || newPassword === undefined) {
      throw new Error(
        'Required parameter newPassword was null or undefined when calling changePassword.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }
    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<any>(
      `${this.basePath}/password/change`,
      {
        userOldPwd: userPasswordOld,
        userPwd: newPassword,
      },
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        //validateStatus: validateStatus,
        validateStatus: (status) => {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );
  }

  /**
   * Request user password reset
   *
   * @param orgCode Organization Code
   * @param userEmail
   * @param userName
   */
  public requestUserPwdReset(
    orgCode: string,
    userEmail?: string,
    userName?: string
  ): Promise<AxiosResponse<any>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling requestUserPwdReset.'
      );
    }

    if (
      (userEmail === null || userEmail === undefined) &&
      (userName === null || userName === undefined)
    ) {
      throw new Error(
        "Parameters userEmail and userName can't both be null or undefined when calling requestUserPwdReset."
      );
    }

    const headers: { [key: string]: string } = {};
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<any>(
      `${this.basePath}/password/request-reset`,
      {
        userEmail: userEmail,
        userName: userName,
      },
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        //validateStatus: validateStatus,
        validateStatus: (status) => {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );
  }

  /**
   * Reset password
   *
   * @param orgCode Organization Code
   * @param resetPasswordToken
   * @param userPwd
   */
  public resetPassword(
    orgCode: string,
    resetPasswordToken: string,
    userPwd: string
  ): Promise<AxiosResponse<any>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling resetPassword.'
      );
    }

    if (resetPasswordToken === null || resetPasswordToken === undefined) {
      throw new Error(
        'Required parameter resetPasswordToken was null or undefined when calling resetPassword.'
      );
    }

    if (userPwd === null || userPwd === undefined) {
      throw new Error(
        'Required parameter userPwd was null or undefined when calling resetPassword.'
      );
    }

    const headers: { [key: string]: string } = {};

    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<any>(
      `${this.basePath}/password/reset`,
      {
        resetPasswordToken: resetPasswordToken,
        userPwd: userPwd,
      },
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        //validateStatus: validateStatus,
        validateStatus: (status) => {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );
  }

  /**
   * Check reset password token
   *
   * @param orgCode Organization Code
   * @param resetPasswordToken
   */
  public checkResetPasswordToken(
    orgCode: string,
    resetPasswordToken: string
  ): Promise<AxiosResponse<any>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling checkResetPasswordToken.'
      );
    }

    if (resetPasswordToken === null || resetPasswordToken === undefined) {
      throw new Error(
        'Required parameter resetPasswordToken was null or undefined when calling checkResetPasswordToken.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (resetPasswordToken) {
      queryParameters.append('token', <any>resetPasswordToken);
    }

    const headers: { [key: string]: string } = {};

    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<any>(`${this.basePath}/session/checkResetPasswordToken`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Check Two-factor Authentication token
   *
   * @param orgCode Organization Code
   * @param twoFactorAuthenticationToken
   */
  public checkTwoFactorAuthenticationToken(
    orgCode: string,
    twoFactorAuthenticationToken: string
  ): Promise<AxiosResponse<any>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling checkTwoFactorAuthenticationToken.'
      );
    }

    if (
      twoFactorAuthenticationToken === null ||
      twoFactorAuthenticationToken === undefined
    ) {
      throw new Error(
        'Required parameter twoFactorAuthenticationToken was null or undefined when calling checkTwoFactorAuthenticationToken.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (twoFactorAuthenticationToken) {
      queryParameters.append('token', <any>twoFactorAuthenticationToken);
    }

    const headers: { [key: string]: string } = {};

    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<any>(
      `${this.basePath}/session/checkTwoFactorAuthenticationToken`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * login 2FA
   *
   * @param orgCode Organization Code
   * @param body
   */
  public login2FA(
    orgCode: string,
    body?: LoginCheck2FA
  ): Promise<AxiosResponse<LoginCheckResponse>> {
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling login.'
      );
    }

    const headers = {} as any;
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post(`${this.basePath}/session/login2FA`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      //validateStatus: validateStatus,
      validateStatus: (status) => {
        return status < 500; // Resolve only if the status code is less than 500
      },
    }) as Promise<AxiosResponse<LoginCheckResponse>>;
  }
}
