import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { CatalogProduct } from 'src/api/api-gc/model';
import { TranslationHelper } from 'src/helpers';
import { getToken } from 'src/utils/token';
import { useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactDOM from 'react-dom';
import {
  CategorySelect,
  EnableSelect,
  ManufacturerSelect,
} from 'src/components/Form/SelectHelper';
import GcApiHelper, {
  GetCatalogProductsRequest,
} from 'src/helpers/GcApiHelper';
import {
  ComboBoxComponent,
  OptionType,
  TextFieldComponent,
} from 'src/components/UI';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import useHashParamCustom from 'src/hooks/useHashParamCustom';
import ObjectHelper from 'src/helpers/ObjectHelper';
import jsonHelper from 'src/helpers/JsonHelper';

const regexValidPageNumber = /^[1-9\b]+$/;

const useStyles = makeStyles((theme) => ({
  searchPanel: {
    borderColor: theme.palette.divider,
    borderWidth: '0 1px 0 0',
    borderStyle: 'solid',
  },
  table: {
    '& th': {
      //cursor: 'pointer',
    },
    '& td': {
      wordBreak: 'break-word',
      cursor: 'pointer',
    },
  },
  orderable: {
    cursor: 'pointer',
  },
  orderByAsc: {
    '&::after': {
      content: `'\\e840'`,
      fontFamily: 'e-icons',
      float: 'right',
    },
  },
  orderByDesc: {
    '&::after': {
      content: `'\\e83f'`,
      fontFamily: 'e-icons',
      float: 'right',
    },
  },
  searchDrawer: {
    width: '300px',
    position: 'relative',
    padding: '20px',
    marginRight: '20px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    border: 0,
  },
  searchDrawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  searchDrawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    /*width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },*/
    width: '50px',
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: 'inherit',
  },
  searchToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  totalCount: {
    textAlign: 'right',
  },
}));

interface ProductsListWithLazyLoadingProps {
  open?: boolean;
  onDoubleClick: (values: CatalogProduct[], page?: number) => void;
  onValuesChanged?: (values: CatalogProduct[]) => void;
  accessorField?: string;
  value?: string | number;
  multiple?: boolean;
  type?: 'MODELS' | 'PRODUCTS' | 'PARTS';
  inPopin?: boolean;
  resetValue?: () => void;
}

const ProductsListWithLazyLoading = ({
  onDoubleClick,
  onValuesChanged,
  open,
  accessorField,
  value,
  multiple = false,
  type,
  inPopin,
  resetValue,
}: ProductsListWithLazyLoadingProps) => {
  const { useHashParam } = useHashParamCustom();

  const [search, setSearch] = useHashParam('search', '');
  const [selectedPage, setSelectedPage] = useHashParam('page', '');
  const [sort, setSort] = useHashParam('sort', '');
  const { orgCode } = useParams<{ orgCode: string }>();
  const { t, i18n } = useTranslation();
  const translate = (key: string, context?: Record<string, string>) =>
    t(`modals.productSelection.array.${key}`, context);

  const theme = useTheme();
  const [searchPanelOpen, setSearchPanelOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setSearchPanelOpen(true);
  };

  const handleDrawerClose = () => {
    setSearchPanelOpen(false);
  };

  const defaultRequest: GetCatalogProductsRequest = {
    token: getToken(),
    orgCode: orgCode,
    mapLevel: [
      'CATEGORY_ITEM',
      'TOTAL_COUNT',
      'TRANSLATIONS',
      'PRODUCT_NAME_AND_TRANSLATIONS',
      'MANUFACTURER',
    ],
    limitOffset: 0,
    limitCount: 20,
    isModel: type === 'MODELS',
    isPart: type === 'PARTS',
    searchCategoryHierarchy: true,
    //hierarchyLevel: 3,
  };
  const [selectedValues, setSelectedValues] = useState<CatalogProduct[]>([]);
  const perfectScrollbarSearchPanel = useRef<PerfectScrollbar>(null);
  const perfectScrollbar = useRef<PerfectScrollbar>(null);
  const tfPageNumber = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchState, setSearchState] = useState<
    Record<string, string | number | boolean | undefined>
  >(
    search && search.length > 0 && jsonHelper.checkIfStringIsJson(search)
      ? JSON.parse(search)
      : {}
  );
  const [scrollable, setScrollable] = useState<boolean>(false);

  const getInitialSearch = (): GetCatalogProductsRequest => {
    let initialSearch = defaultRequest;
    if (search && search.length > 0 && jsonHelper.checkIfStringIsJson(search)) {
      Object.assign(initialSearch, JSON.parse(search));
    }
    if (sort && sort.length > 0) {
      const orderBy = JSON.parse(sort);
      Object.assign(initialSearch, {
        asc: orderBy && orderBy['asc'] ? [orderBy['asc']] : undefined,
        desc: orderBy && orderBy['desc'] ? [orderBy['desc']] : undefined,
      });
    }

    return initialSearch;
  };

  /*const [request, setRequest] = useState<GetCatalogProductsRequest>(
    search && search.length > 0
      ? { ...defaultRequest, ...JSON.parse(search) }
      : defaultRequest
  );*/

  const [request, setRequest] = useState<GetCatalogProductsRequest>(
    getInitialSearch()
  );

  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [values, setValues] = useState<CatalogProduct[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const [orderBy, setOrderBy] = React.useState<
    Record<string, string> | undefined
  >(undefined);

  useEffect(() => {
    if (open === true && initialized) {
      setRequest(defaultRequest);
      setValues([]);
      setScrollable(false);
      setSearchState({});
      setSelectedValues([]);

      setPageNumber(1);

      requestGetProducts();
    }
  }, [open]);

  const setPageNumber = (pageIndex: number) => {
    setPage(pageIndex);
    if (open === undefined) {
      setSelectedPage(String(pageIndex));
    }
  };

  const requestGetProducts = () => {
    setLoading(true);
    setScrollable(false);
    setValues([]);

    GcApiHelper.getCatalogProducts(request)
      .then(({ data: { values, totalCount } }) => {
        if (!values || values.length === 0) {
          setScrollable(false);
        } else {
          //if (values.length === 20) {
          setScrollable(true);
        }
        setTotalCount(totalCount || 0);
        setValues(values || []);
        fixHeight();
      })
      .finally(() => {
        setLoading(false);
        fixHeight();
      });
  };

  useEffect(() => {
    setInitialized(true);
    if (selectedPage === '') {
      requestGetProducts();
    } else {
      onPageChange(Number(selectedPage));
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      console.log(request);
      requestGetProducts();
    }
  }, [request]);

  const onPageChange = (pageIndex: number) => {
    setPageNumber(pageIndex);
    setRequest((prevState) => ({
      ...prevState,
      limitOffset: (pageIndex - 1) * 20,
    }));
  };

  const classes = useStyles();

  const fixHeight = () => {
    if (perfectScrollbar?.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const element = ReactDOM.findDOMNode(
        perfectScrollbar.current
      ) as HTMLElement;
      if (element) {
        const offsetTop = element.offsetTop;

        if (inPopin) {
          element.style.height =
            window.innerHeight -
            offsetTop -
            (window.innerWidth > 1199 ? 179 : 223) +
            'px';
        } else {
          element.style.height =
            window.innerHeight -
            offsetTop -
            (window.innerWidth > 1199 ? 74 : 118) +
            'px';
        }
      }
    }

    if (perfectScrollbarSearchPanel?.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const element = ReactDOM.findDOMNode(
        perfectScrollbarSearchPanel.current
      ) as HTMLElement;
      if (element) {
        const offsetTop = element.offsetTop;

        element.style.height =
          window.innerHeight -
          offsetTop -
          (inPopin ? 208 : window.innerWidth > 1199 ? 333 : 313) +
          'px';
      }
    }
  };

  useEffect(() => {
    fixHeight();
    window.addEventListener('resize', fixHeight);

    return () => {
      window.removeEventListener('resize', fixHeight);
    };
  }, []);

  const handleOnChange = (
    field: string,
    value: string | number | boolean | undefined
  ) => {
    setSearchState(
      ObjectHelper.removeEmpty({ ...searchState, [field]: value }) as Record<
        string,
        string
      >
    );
  };

  const handleClick = (value: CatalogProduct) => {
    if (multiple) {
      if (selectedValues.includes(value)) {
        setSelectedValues(selectedValues.filter((v) => v !== value));
      } else {
        setSelectedValues(selectedValues.concat(value));
      }
    } else {
      setSelectedValues([value]);
    }
  };

  const handleSort = (value: string) => {
    if (orderBy) {
      if (orderBy['asc'] === value) {
        setOrderBy({ desc: value });
      } else {
        setOrderBy({ asc: value });
      }
    } else {
      setOrderBy({ asc: value });
    }
  };

  useEffect(() => {
    setSort(JSON.stringify(orderBy));
    if (initialized) {
      setRequest((prevState) => ({
        //...defaultRequest,
        ...prevState,
        asc: orderBy && orderBy['asc'] ? [orderBy['asc']] : undefined,
        desc: orderBy && orderBy['desc'] ? [orderBy['desc']] : undefined,
      }));
    }
  }, [orderBy]);

  useEffect(() => {
    if (onValuesChanged) {
      onValuesChanged(selectedValues);
    }
  }, [selectedValues]);

  const handleDoubleClick = () => {
    if (!multiple) {
      onDoubleClick(selectedValues);
    }
  };

  const handleSearch = () => {
    if (resetValue) {
      resetValue();
    }
    setPageNumber(1);
    setSelectedValues([]);

    if (open === undefined) {
      setSearch(
        Object.keys(searchState).length > 0 ? JSON.stringify(searchState) : ''
      );
    }

    setRequest({ ...defaultRequest, ...searchState });
  };

  /*const onChangeGoToPage = (event: any) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value == '' || regex.test(event.target.value)) {
      if (event.target.value > Math.floor(totalCount / 20)) {
        setGoToPageNumber(Math.floor(totalCount / 20));
      } else {
        setGoToPageNumber(event.target.value);
      }
    }
  };*/

  const handleOnKeyUpGoToPage = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();

    if (event.key === 'Enter') {
      goToPage();
    }
  };

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();

    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const getType = (): string | undefined => {
    if (searchState.isModel) {
      return 'models';
    } else if (searchState.isPart) {
      return 'parts';
    } else if (searchState.isModel === false && !searchState.isPart) {
      return 'products';
    } else {
      return undefined;
    }
  };

  const handleOnChangeType = (value: any) => {
    if (value === null) {
      setSearchState({ ...searchState, isModel: undefined, isPart: undefined });
    } else {
      switch (value.key) {
        case 'models':
          setSearchState({ ...searchState, isModel: true, isPart: undefined });
          break;
        case 'products':
          setSearchState({ ...searchState, isModel: false, isPart: undefined });
          break;
        case 'parts':
          setSearchState({ ...searchState, isModel: undefined, isPart: true });
          break;
      }
    }
  };

  const goToPage = () => {
    if (
      tfPageNumber.current &&
      regexValidPageNumber.test(tfPageNumber.current.value) &&
      page !== Number(tfPageNumber.current.value)
    ) {
      onPageChange(Number(tfPageNumber.current.value));
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Drawer
        anchor="left"
        classes={{
          paper: clsx(classes.searchDrawer, {
            [classes.searchDrawerOpen]: searchPanelOpen,
            [classes.searchDrawerClose]: !searchPanelOpen,
          }),
        }}
        open
        variant="persistent">
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.searchToolbar}>
            <IconButton
              onClick={searchPanelOpen ? handleDrawerClose : handleDrawerOpen}
              size="large">
              {!searchPanelOpen ? (
                <SearchIcon />
              ) : theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '-12px' }}>
            <Grid
              container
              style={{ display: searchPanelOpen ? 'inherit' : 'none' }}>
              <Grid item xs={12}>
                <PerfectScrollbar
                  ref={perfectScrollbarSearchPanel}
                  options={{ suppressScrollX: true }}
                  style={
                    window.innerWidth > 1199
                      ? {
                          height: window.innerHeight - 413,
                          display: 'flex',
                        }
                      : { height: window.innerHeight - 410, display: 'flex' }
                  }>
                  <Grid
                    container
                    spacing={3}
                    style={{ display: 'block', marginBottom: 0, marginTop: 0 }}>
                    <Grid item xs={12}>
                      <TextFieldComponent
                        fullWidth
                        label={translate('fields.code')}
                        name="productCode"
                        value={searchState.productCode || ''}
                        onChange={(e) => {
                          handleOnChange(
                            e.target.name,
                            e.target.value
                              ? e.target.value.toUpperCase()
                              : undefined
                          );
                        }}
                        onKeyUp={handleOnKeyUp}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldComponent
                        fullWidth
                        label={translate('fields.name')}
                        name="productName"
                        value={searchState.productName || ''}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                        onKeyUp={handleOnKeyUp}
                        inputProps={{
                          maxLength: 600,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ManufacturerSelect
                        value={
                          searchState.manufacturerId
                            ? Number(searchState.manufacturerId)
                            : undefined
                        }
                        onChange={(value) => {
                          handleOnChange('manufacturerId', value);
                        }}
                        label={translate('fields.manufacturer')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CategorySelect
                        value={
                          searchState.productCategoryItemId
                            ? Number(searchState.productCategoryItemId)
                            : undefined
                        }
                        onChange={(value) => {
                          handleOnChange('productCategoryItemId', value);
                        }}
                        //size="small"
                        label={translate('fields.category')}
                        categoryCode="CATALOG_PRODUCT"
                        //value={value as string}
                        accessorField="itemId"
                        //values={datas[field]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldComponent
                        fullWidth
                        value={searchState.referenceCode || ''}
                        label={translate('fields.reference')}
                        name="referenceCode"
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                        onKeyUp={handleOnKeyUp}
                        inputProps={{
                          maxLength: 600,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <EnableSelect
                        value={searchState.enable as boolean}
                        onChange={(value) => {
                          handleOnChange('enable', value);
                        }}
                        label={translate('fields.enable')}
                      />
                    </Grid>
                    {type === undefined ? (
                      <Grid item xs={12}>
                        <ComboBoxComponent
                          options={['models', 'products', 'parts'].map(
                            (value) => {
                              const opt: OptionType = {
                                key: value,
                                label: translate(`types.${value}`),
                              };
                              return opt;
                            }
                          )}
                          label={translate('fields.type')}
                          value={getType()}
                          onChange={(_event, value) => {
                            handleOnChangeType(value);
                          }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </PerfectScrollbar>
              </Grid>
              {/*<Grid item xs={12}>
              {selected.map((data: SelectData) => (
                <Chip
                  key={data.value}
                  label={data.label}
                  //className={classes.chip}
                  //onDelete={handleDelete(data)}
                />
              ))}
            </Grid>*/}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button onClick={handleSearch} color="primary">
                  {t('modals.productSelection.buttons.search')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PerfectScrollbar
            ref={perfectScrollbar}
            options={{ suppressScrollX: true }}
            style={
              window.innerWidth > 900
                ? {
                    height: window.innerHeight - 301,
                    display: 'flex',
                  }
                : { height: window.innerHeight - 580, display: 'flex' }
            }>
            <Table stickyHeader={true} className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className={clsx(classes.orderable, {
                      [classes['orderByAsc']]:
                        orderBy && orderBy['asc'] === 'productCode',
                      [classes['orderByDesc']]:
                        orderBy && orderBy['desc'] === 'productCode',
                    })}
                    onClick={() => {
                      handleSort('productCode');
                    }}>
                    {translate('fields.code')}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={clsx(classes.orderable, {
                      [classes['orderByAsc']]:
                        orderBy && orderBy['asc'] === 'productName',
                      [classes['orderByDesc']]:
                        orderBy && orderBy['desc'] === 'productName',
                    })}
                    onClick={() => {
                      handleSort('productName');
                    }}>
                    {translate('fields.name')}
                  </TableCell>
                  {/*<TableCell align="center">
                    {translate('fields.title')}
                  </TableCell>*/}
                  <TableCell
                    align="center"
                    onClick={() => {
                      //handleSort('productCategoryItem.itemCode');
                    }}>
                    {translate('fields.category')}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      //handleSort('manufacturer.manufacturerCode');
                    }}>
                    {translate('fields.manufacturer')}
                  </TableCell>
                  <TableCell align="center">
                    {translate('fields.enable')}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                style={{
                  backgroundColor: theme.palette.background.dark,
                }}>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : values.length > 0 ? (
                  values.map((v) => {
                    return (
                      <TableRow
                        hover
                        key={v.productId}
                        selected={
                          selectedValues.length > 0
                            ? selectedValues.includes(v)
                            : v[
                                (accessorField as keyof CatalogProduct) ||
                                  'productId'
                              ] === value
                        }
                        onClick={() => handleClick(v)}
                        onDoubleClick={() => handleDoubleClick()}>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: 'nowrap' }}>
                          {v.productCode}
                        </TableCell>
                        <TableCell align="center">{v.productName}</TableCell>
                        {/*<TableCell align="center">
                        {TranslationHelper.getTranslation(
                          i18n.language,
                          '',
                          v.titleTranslations
                        )}
                      </TableCell>*/}
                        <TableCell align="center">
                          {v.categoryItem
                            ? TranslationHelper.getTranslation(
                                i18n.language,
                                v.categoryItem.itemName,
                                v.categoryItem.translations
                              )
                            : ''}
                        </TableCell>
                        <TableCell align="center">
                          {v.manufacturer
                            ? v.manufacturer.manufacturerName
                            : ''}
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox checked={v.enable} disabled />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      unselectable={'on'}
                      style={{ cursor: 'default' }}>
                      <Typography
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                          fontWeight: theme.typography.fontWeightBold,
                        }}>
                        {translate('noResult')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: 0,
          }}>
          {!loading && (
            <Grid
              container
              style={{
                backgroundColor: theme.palette.background.default,
                padding: '1px 5px 5px 5px',
              }}>
              <Grid item xs={12} lg>
                <Pagination
                  style={{
                    paddingTop: '8px',
                    paddingBottom: '4px',
                  }}
                  sx={{ minWidth: '400px' }}
                  page={page}
                  defaultPage={1}
                  color={'primary'}
                  count={Math.ceil(Number(totalCount) / 20)}
                  onChange={(event, p: number) => {
                    if (page !== p) {
                      onPageChange(p);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6} lg>
                <TextFieldComponent
                  inputRef={tfPageNumber}
                  style={{
                    marginTop: '4px',
                    marginLeft: '4px',
                    width: '100px',
                  }}
                  label={translate('goToPage')}
                  size={'small'}
                  onKeyUp={(event: any) => {
                    handleOnKeyUpGoToPage(event);
                  }}
                  /*onChange={(event: any) => {
                    onChangeGoToPage(event);
                  }}*/
                  defaultValue={''}
                />
                <Button
                  onClick={goToPage}
                  variant={'contained'}
                  style={{
                    marginTop: '4px',
                    marginLeft: '4px',
                    marginRight: '10px',
                    height: '40px',
                  }}>
                  {t('modals.productSelection.buttons.goToPage')}
                </Button>
              </Grid>
              <Grid item xs={6} lg>
                <span>
                  <Typography
                    className={classes.totalCount}
                    style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      float: 'right',
                    }}>
                    {translate('totalCount', {
                      totalCount: totalCount.toString(),
                    })}
                  </Typography>
                </span>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default ProductsListWithLazyLoading;
