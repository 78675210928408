import React, { useEffect, useMemo } from 'react';
import { match, RouteProps } from 'react-router';
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
  Avatar,
  Box,
  colors,
} from '@mui/material';
import Page from 'src/components/Page';
import config from 'src/config';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';
import Copyright from 'src/views/login/Copyright';
import LogoutHelper from 'src/helpers/LogoutHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  errorMessage: {
    width: '100%',
    textAlign: 'center',
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    objectFit: 'contain',
    backgroundSize: 'contain',
  },

  form: {},
  submit: {},
  error: {
    color: 'red',
    fontSize: '13px',
  },
}));

const RequestResetPasswordSent: React.FC<
  {
    computedMatch: match<{ orgCode: string }>;
  } & RouteProps
> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const orgCode = useMemo(() => {
    return props.computedMatch.params.orgCode;
  }, [props]);

  const state: any = props.location ? props.location.state : undefined;

  const redirectLogin = () => {
    LogoutHelper.logout(orgCode);
  };

  useEffect(() => {
    console.log(state);
    if (!state) {
      redirectLogin();
    }
  }, []);

  return (
    <>
      <Page
        className={classes.root}
        title={`GlobalCare Manager - ${config.env} - Reset Email sent`}>
        <Container maxWidth="md">
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <Avatar className={classes.icon}>
                <LockIcon fontSize="large" />
              </Avatar>
              <Typography variant="h2" color="textPrimary">
                {t('requestResetPasswordSent.title')}
              </Typography>
              <br />
              <Typography variant="subtitle1" color="textSecondary">
                {t('requestResetPasswordSent.subtitle')}
              </Typography>
            </CardContent>
            <CardMedia
              className={classes.media}
              image="/static/images/login.svg"
              title="GlobalCare Manager"
            />
          </Card>
          <Box mt={1}>
            <Copyright />
          </Box>
        </Container>
      </Page>
    </>
  );
};
export default RequestResetPasswordSent;
