import * as React from 'react';
import { Helmet } from 'react-helmet';

interface PageProps {
  children: React.ReactNode;
  className?: string;
  style?: any;
  title: string;
}

const Page = ({ title, children, ...rest }: PageProps) => {
  return (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

export default Page;
