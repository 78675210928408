/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  BrlAction,
  BrlCondition,
  BrlEnum,
  BrlFunction,
  BrlEntity,
  BrlMessage,
  BrlProperty,
  BrlRule,
  BrlRuleItem,
  BrlRuleItemAction,
  BrlRuleItemCondition,
  BrlTest,
  BrlService,
  BrlDomain,
  BrlApi,
  BrlApiServer,
  BrlServer,
  BrlParameter,
  BrlGroup,
} from 'src/api/api-gc/model';
import { Configuration } from 'src/api/api-gc/configuration';
import axios, { AxiosResponse } from 'axios';
import config from 'src/config';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIBrlConfigurationService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * createAction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addAction(
    token: string,
    orgCode: string,
    body: BrlAction,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addAction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addAction.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addAction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(`${this.basePath}/businessRules/actions`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * createCondition
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addCondition(
    token: string,
    orgCode: string,
    body: BrlCondition,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addCondition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addCondition.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addCondition.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(`${this.basePath}/businessRules/conditions`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * createFunction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addFunction(
    token: string,
    orgCode: string,
    body: BrlFunction,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addFunction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addFunction.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addFunction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(`${this.basePath}/businessRules/functions`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * addEnum
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addEnum(
    token: string,
    orgCode: string,
    body: BrlEnum,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addEnum.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addEnum.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addEnum.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(`${this.basePath}/businessRules/enums`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * createEntity
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addEntity(
    token: string,
    orgCode: string,
    body: BrlEntity,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addEntity.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(`${this.basePath}/businessRules/entities`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * createProperty
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param entityId
   * @param body
   * @param mapLevel
   */
  public addProperty(
    token: string,
    orgCode: string,
    entityId: number,
    body: BrlProperty,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addProperty.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addProperty.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling addProperty.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addProperty.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/properties/${entityId}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * createRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addRule(
    token: string,
    orgCode: string,
    body: BrlRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addRule.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(`${this.basePath}/businessRules/rules`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * createRuleItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleId
   * @param body
   * @param mapLevel
   */
  public addRuleItem(
    token: string,
    orgCode: string,
    ruleId: number,
    body: BrlRuleItem,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addRuleItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addRuleItem.'
      );
    }

    if (ruleId === null || ruleId === undefined) {
      throw new Error(
        'Required parameter ruleId was null or undefined when calling addRuleItem.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addRuleItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/ruleItems/${ruleId}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * createRuleItemAction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleItemId
   * @param body
   * @param mapLevel
   */
  public addRuleItemAction(
    token: string,
    orgCode: string,
    ruleItemId: number,
    body: BrlRuleItemAction,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addRuleItemAction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addRuleItemAction.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling addRuleItemAction.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addRuleItemAction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/ruleItemActions/${ruleItemId}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * createRuleItemCondition
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleItemId
   * @param body
   * @param mapLevel
   */
  public addRuleItemCondition(
    token: string,
    orgCode: string,
    ruleItemId: number,
    body: BrlRuleItemCondition,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addRuleItemCondition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addRuleItemCondition.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling addRuleItemCondition.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addRuleItemCondition.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/ruleItemConditions/${ruleItemId}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * createTest
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addTest(
    token: string,
    orgCode: string,
    body: BrlTest,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addTest.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addTest.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addTest.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(`${this.basePath}/businessRules/tests`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * duplicateAction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateAction(
    token: string,
    orgCode: string,
    id: number,
    body: BrlAction,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateAction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateAction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateAction.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateAction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/actions/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateCondition
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateCondition(
    token: string,
    orgCode: string,
    id: number,
    body: BrlCondition,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateCondition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateCondition.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateCondition.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateCondition.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/conditions/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateFunction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateFunction(
    token: string,
    orgCode: string,
    id: number,
    body: BrlFunction,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateFunction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateFunction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateFunction.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateFunction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/functions/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateEnum
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateEnum(
    token: string,
    orgCode: string,
    id: number,
    body: BrlEnum,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateEnum.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateEnum.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateEnum.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateEnum.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/enums/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateEntity
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateEntity(
    token: string,
    orgCode: string,
    id: number,
    body: BrlEntity,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateEntity.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateEntity.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/entities/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateEntity
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param entityId
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateProperty(
    token: string,
    orgCode: string,
    entityId: number,
    id: number,
    body: BrlEntity,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateProperty.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateProperty.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling duplicateProperty.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateProperty.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateProperty.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/properties/${entityId}/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateRule(
    token: string,
    orgCode: string,
    id: number,
    body: BrlRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateRule.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateRule.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/rules/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateRuleItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleId
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateRuleItem(
    token: string,
    orgCode: string,
    ruleId: number,
    id: number,
    body: BrlRuleItem,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateRuleItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateRuleItem.'
      );
    }

    if (ruleId === null || ruleId === undefined) {
      throw new Error(
        'Required parameter ruleId was null or undefined when calling duplicateRuleItem.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateRuleItem.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateRuleItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/ruleItems/${ruleId}/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateRuleItemAction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleItemId
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateRuleItemAction(
    token: string,
    orgCode: string,
    ruleItemId: number,
    id: number,
    body: BrlRuleItemAction,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateRuleItemAction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateRuleItemAction.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling duplicateRuleItemAction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateRuleItemAction.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateRuleItemAction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/ruleItemActions/${ruleItemId}/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateRuleItemCondition
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleItemId
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateRuleItemCondition(
    token: string,
    orgCode: string,
    ruleItemId: number,
    id: number,
    body: BrlRuleItemCondition,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateRuleItemCondition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateRuleItemCondition.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling duplicateRuleItemCondition.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateRuleItemCondition.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateRuleItemCondition.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/ruleItemConditions/${ruleItemId}/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateTest
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateTest(
    token: string,
    orgCode: string,
    id: number,
    body: BrlTest,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateTest.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateTest.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateTest.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateTest.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/tests/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * generateRules
   *
   * @param token Token
   * @param orgCode Organization Code
   */
  public generateRules(
    token: string,
    orgCode: string,
    jarname: string,
    version: string,
    major: string,
    minor: string
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling generateRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling generateRules.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<any>(
      `${this.basePath}/businessRules/rules/generateRules/${jarname}/${version}/${major}/${minor}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * generateRules
   *
   * @param token Token
   * @param orgCode Organization Code
   */
  public deployConfig(
    token: string,
    orgCode: string,
    configId?: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deployConfig.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deployConfig.'
      );
    }

    if (configId === null || configId === undefined) {
      throw new Error(
        'Required parameter number was null or undefined when calling deployConfig.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<any>(
      `${this.basePath}/businessRules/rules/deployConfig/${configId}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * generateEntities
   *
   * @param token Token
   * @param orgCode Organization Code

   */
  public generateEntities(
    token: string,
    orgCode: string,
    jarname: string,
    version: string,
    major: string,
    minor: string
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling generateRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling generateRules.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<any>(
      `${this.basePath}/businessRules/rules/generateEntities/${jarname}/${version}/${major}/${minor}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * generateWar
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param jarname
   * @param version
   * @param major
   * @param minor
   */
  public generateWar(
    token: string,
    orgCode: string,
    jarname: string,
    version: string,
    major: string,
    minor: string
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling generateRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling generateRules.'
      );
    }

    if (jarname === null || jarname === undefined) {
      throw new Error(
        'Required parameter jarname was null or undefined when calling generateRules.'
      );
    }

    if (version === null || version === undefined) {
      throw new Error(
        'Required parameter version was null or undefined when calling generateRules.'
      );
    }

    if (major === null || major === undefined) {
      throw new Error(
        'Required parameter major was null or undefined when calling generateRules.'
      );
    }

    if (minor === null || minor === undefined) {
      throw new Error(
        'Required parameter minor was null or undefined when calling generateRules.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<any>(
      `${this.basePath}/businessRules/rules/generateWar/${jarname}/${version}/${major}/${minor}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * deploy
   *
   * @param token Token
   * @param orgCode Organization Code
   */
  public deploy(
    token: string,
    orgCode: string,
    jarname: string,
    version: string,
    major: string,
    minor: string
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling generateRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling generateRules.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<any>(
      `${this.basePath}/businessRules/rules/deploy/${jarname}/${version}/${major}/${minor}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
  /**
   * getAction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param mapLevel
   */
  public getAction(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlAction>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getAction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getAction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getAction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlAction>(
      `${this.basePath}/businessRules/actions/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getActions
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getActions(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlAction[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getActions.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getActions.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlAction[]>(`${this.basePath}/businessRules/actions`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getCondition
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param mapLevel
   */
  public getCondition(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlCondition>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCondition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCondition.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getCondition.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlCondition>(
      `${this.basePath}/businessRules/conditions/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getConditions
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getConditions(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlCondition[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getConditions.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getConditions.'
      );
    }
    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlCondition[]>(
      `${this.basePath}/businessRules/conditions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
  /**
   * getFunction
   *
   * @param token Token
   * @param orgCode Organization Code

   * @param id
   * @param mapLevel
   */
  public getFunction(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlFunction>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getFunction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getFunction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getFunction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlFunction>(
      `${this.basePath}/businessRules/functions/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getFunctions
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getFunctions(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlFunction[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getFunctions.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getFunctions.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlFunction[]>(
      `${this.basePath}/businessRules/functions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getEnum
   *
   * @param token Token
   * @param orgCode Organization Code

   * @param id
   * @param mapLevel
   */
  public getEnum(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlEnum>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEnum.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEnum.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getEnum.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlEnum>(`${this.basePath}/businessRules/enums/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getEnums
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getEnums(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlEnum[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEnums.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEnums.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlEnum[]>(`${this.basePath}/businessRules/enums`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getEntities
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getEntities(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlEntity[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntities.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntities.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlEntity[]>(`${this.basePath}/businessRules/entities`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getEntity
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param mapLevel
   */
  public getEntity(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlEntity>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getEntity.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlEntity>(
      `${this.basePath}/businessRules/entities/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getProperties
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param entityId
   * @param mapLevel
   */
  public getProperties(
    token: string,
    orgCode: string,
    entityId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlProperty[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getProperties.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getProperties.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling getProperties.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlProperty[]>(
      `${this.basePath}/businessRules/properties/${entityId}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getProperty
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param entityId
   * @param id
   * @param mapLevel
   */
  public getProperty(
    token: string,
    orgCode: string,
    entityId: number,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlProperty>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getProperty.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getProperty.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling getProperty.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProperty.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlProperty>(
      `${this.basePath}/businessRules/properties/${entityId}/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param mapLevel
   */
  public getRule(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlRule>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getRule.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlRule>(`${this.basePath}/businessRules/rules/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getRuleItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleId
   * @param id
   * @param mapLevel
   */
  public getRuleItem(
    token: string,
    orgCode: string,
    ruleId: number,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlRuleItem>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getRuleItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getRuleItem.'
      );
    }

    if (ruleId === null || ruleId === undefined) {
      throw new Error(
        'Required parameter ruleId was null or undefined when calling getRuleItem.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getRuleItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlRuleItem>(
      `${this.basePath}/businessRules/ruleItems/${ruleId}/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getRuleItemAction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleItemId
   * @param id
   * @param mapLevel
   */
  public getRuleItemAction(
    token: string,
    orgCode: string,
    ruleItemId: number,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlRuleItemAction>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getRuleItemAction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getRuleItemAction.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling getRuleItemAction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getRuleItemAction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlRuleItemAction>(
      `${this.basePath}/businessRules/ruleItemActions/${ruleItemId}/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getRuleItemActions
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleId
   * @param mapLevel
   */
  public getRuleItemActions(
    token: string,
    orgCode: string,
    ruleId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlRuleItemAction[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getRuleItemActions.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getRuleItemActions.'
      );
    }

    if (ruleId === null || ruleId === undefined) {
      throw new Error(
        'Required parameter ruleId was null or undefined when calling getRuleItemActions.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlRuleItemAction[]>(
      `${this.basePath}/businessRules/ruleItemActions/${ruleId}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getRuleItemCondition
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleItemId
   * @param id
   * @param mapLevel
   */
  public getRuleItemCondition(
    token: string,
    orgCode: string,
    ruleItemId: number,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlRuleItemCondition>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getRuleItemCondition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getRuleItemCondition.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling getRuleItemCondition.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getRuleItemCondition.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlRuleItemCondition>(
      `${this.basePath}/businessRules/ruleItemConditions/${ruleItemId}/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getRuleItems
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleId
   * @param mapLevel
   */
  public getRuleItems(
    token: string,
    orgCode: string,
    ruleId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlRuleItem[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getRuleItems.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getRuleItems.'
      );
    }

    if (ruleId === null || ruleId === undefined) {
      throw new Error(
        'Required parameter ruleId was null or undefined when calling getRuleItems.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlRuleItem[]>(
      `${this.basePath}/businessRules/ruleItems/${ruleId}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getRules(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlRule[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlRule[]>(`${this.basePath}/businessRules/rules`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getTest
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param mapLevel
   */
  public getTest(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlTest>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTest.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTest.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getTest.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlTest>(`${this.basePath}/businessRules/tests/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getTests
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getTests(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlTest[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getTests.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getTests.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlTest[]>(`${this.basePath}/businessRules/tests`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getruleitemconditions
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleId
   * @param mapLevel
   */
  public getRuleItemConditions(
    token: string,
    orgCode: string,
    ruleItemId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlRuleItemCondition[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getruleitemconditions.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getruleitemconditions.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling getruleitemconditions.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<BrlRuleItemCondition[]>(
      `${this.basePath}/businessRules/ruleItemConditions/${ruleItemId}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * init
   *
   * @param token Token
   * @param orgCode Organization Code
   */
  public init(token: string, orgCode: string): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling init.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling init.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<any>(`${this.basePath}/businessRules/rules/init`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * init
   *
   * @param token Token
   * @param orgCode Organization Code
   */
  public isAlive(token: string, orgCode: string): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling isAlive.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling isAlive.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<any>(`${this.basePath}/businessRules/rules/IsAlive`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeAction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeAction(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeAction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeAction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeAction.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(`${this.basePath}/businessRules/actions/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeCondition
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeCondition(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeCondition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeCondition.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeCondition.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/businessRules/conditions/${id}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
  /**
   * removeFunction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeFunction(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeFunction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeFunction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeFunction.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(`${this.basePath}/businessRules/functions/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeEnum
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeEnum(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeEnum.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeEnum.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeEnum.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(`${this.basePath}/businessRules/enums/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeEntity
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeEntity(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeEntity.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeEntity.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(`${this.basePath}/businessRules/entities/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeProperty
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param entityId
   * @param id
   */
  public removeProperty(
    token: string,
    orgCode: string,
    entityId: number,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeProperty.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeProperty.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling removeProperty.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeProperty.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/businessRules/properties/${entityId}/${id}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * removeRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeRule(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeRule.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeRule.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(`${this.basePath}/businessRules/rules/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeRuleItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleId
   * @param id
   */
  public removeRuleItem(
    token: string,
    orgCode: string,
    ruleId: number,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeRuleItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeRuleItem.'
      );
    }

    if (ruleId === null || ruleId === undefined) {
      throw new Error(
        'Required parameter ruleId was null or undefined when calling removeRuleItem.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeRuleItem.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/businessRules/ruleItems/${ruleId}/${id}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * removeRuleItemAction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleItemId
   * @param id
   */
  public removeRuleItemAction(
    token: string,
    orgCode: string,
    ruleItemId: number,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeRuleItemAction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeRuleItemAction.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling removeRuleItemAction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeRuleItemAction.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/businessRules/ruleItemActions/${ruleItemId}/${id}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * removeRuleItemCondition
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleItemId
   * @param id
   */
  public removeRuleItemCondition(
    token: string,
    orgCode: string,
    ruleItemId: number,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeRuleItemCondition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeRuleItemCondition.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling removeRuleItemCondition.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeRuleItemCondition.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/businessRules/ruleItemConditions/${ruleItemId}/${id}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * removeTest
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeTest(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeTest.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeTest.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeTest.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(`${this.basePath}/businessRules/tests/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * runningRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleName
   * @param ruleVersion
   * @param body
   */
  public runningRules(
    token: string,
    orgCode: string,
    ruleName: string,
    ruleVersion: string,
    body: BrlMessage
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling runningRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling runningRules.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling runningRules.'
      );
    }

    if (ruleName === null || ruleName === undefined) {
      throw new Error(
        'Required parameter ruleName was null or undefined when calling runningRules.'
      );
    }

    if (ruleVersion === null || ruleVersion === undefined) {
      throw new Error(
        'Required parameter ruleVersion was null or undefined when calling runningRules.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<any>(
      `${this.basePath}/businessRules/rules/running/${ruleName}/${ruleVersion}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateAction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateAction(
    token: string,
    orgCode: string,
    id: number,
    body: BrlAction,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateAction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateAction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateAction.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateAction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/actions/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateCondition
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateCondition(
    token: string,
    orgCode: string,
    id: number,
    body: BrlCondition,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateCondition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateCondition.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateCondition.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateCondition.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/conditions/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateFunction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateFunction(
    token: string,
    orgCode: string,
    id: number,
    body: BrlFunction,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateFunction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateFunction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateFunction.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateFunction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/functions/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateEnum
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateEnum(
    token: string,
    orgCode: string,
    id: number,
    body: BrlEnum,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateEnum.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateEnum.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateEnum.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateEnum.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/enums/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateEntity
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateEntity(
    token: string,
    orgCode: string,
    id: number,
    body: BrlEntity,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateEntity.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateEntity.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateEntity.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateEntity.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/entities/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateProperty
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param entityId
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateProperty(
    token: string,
    orgCode: string,
    entityId: number,
    id: number,
    body: BrlProperty,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateProperty.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateProperty.'
      );
    }

    if (entityId === null || entityId === undefined) {
      throw new Error(
        'Required parameter entityId was null or undefined when calling updateProperty.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateProperty.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateProperty.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/properties/${entityId}/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateRule
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateRule(
    token: string,
    orgCode: string,
    id: number,
    body: BrlRule,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateRule.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateRule.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateRule.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateRule.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/rules/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateRuleItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleId
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateRuleItem(
    token: string,
    orgCode: string,
    ruleId: number,
    id: number,
    body: BrlRuleItem,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateRuleItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateRuleItem.'
      );
    }

    if (ruleId === null || ruleId === undefined) {
      throw new Error(
        'Required parameter ruleId was null or undefined when calling updateRuleItem.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateRuleItem.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateRuleItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/ruleItems/${ruleId}/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateRuleItemAction
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleItemId
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateRuleItemAction(
    token: string,
    orgCode: string,
    ruleItemId: number,
    id: number,
    body: BrlRuleItemAction,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateRuleItemAction.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateRuleItemAction.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling updateRuleItemAction.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateRuleItemAction.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateRuleItemAction.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/ruleItemActions/${ruleItemId}/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateRuleItemCondition
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleItemId
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateRuleItemCondition(
    token: string,
    orgCode: string,
    ruleItemId: number,
    id: number,
    body: BrlRuleItemCondition,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateRuleItemCondition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateRuleItemCondition.'
      );
    }

    if (ruleItemId === null || ruleItemId === undefined) {
      throw new Error(
        'Required parameter ruleItemId was null or undefined when calling updateRuleItemCondition.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateRuleItemCondition.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateRuleItemCondition.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/ruleItemConditions/${ruleItemId}/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * updateTest
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateTest(
    token: string,
    orgCode: string,
    id: number,
    body: BrlTest,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateTest.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateTest.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateTest.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateTest.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<any>(
      `${this.basePath}/businessRules/tests/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * createService
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param apiId
   * @param body
   * @param mapLevel
   */
  public addService(
    token: string,
    orgCode: string,
    body: BrlService,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlService>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addService.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addService.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addService.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(`${this.basePath}/businessRules/services`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * duplicateService
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param apiId
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateService(
    token: string,
    orgCode: string,
    apiId: number,
    id: number,
    body: BrlService,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlService>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateService.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateService.'
      );
    }

    if (apiId === null || apiId === undefined) {
      throw new Error(
        'Required parameter apiId was null or undefined when calling duplicateService.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateService.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateService.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(
      `${this.basePath}/businessRules/services/${apiId}/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getService
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param apiId
   * @param id
   * @param mapLevel
   */
  public getService(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlService>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getService.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getService.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getService.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/services/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getServices
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getServices(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlService[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getServices.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getServices.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/services`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * updateService
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateService(
    token: string,
    orgCode: string,
    id: number,
    body: BrlService,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlService>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateService.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateService.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateService.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateService.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch(`${this.basePath}/businessRules/services/${id}`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeService
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeService(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeService.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeService.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeService.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete(`${this.basePath}/businessRules/services/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * createDomain
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addDomain(
    token: string,
    orgCode: string,
    body: BrlDomain,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlDomain>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addDomain.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addDomain.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addDomain.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(`${this.basePath}/businessRules/domains`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * duplicateDomain
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateDomain(
    token: string,
    orgCode: string,
    id: number,
    body: BrlDomain,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlDomain>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateDomain.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateDomain.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateDomain.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateDomain.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(
      `${this.basePath}/businessRules/domains/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getDomain
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param mapLevel
   */
  public getDomain(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlDomain>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getDomain.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getDomain.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getDomain.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/domains/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getDomains
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getDomains(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlDomain[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getDomains.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getDomains.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/domains`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * updateDomain
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateDomain(
    token: string,
    orgCode: string,
    id: number,
    body: BrlDomain,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlDomain>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateDomain.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateDomain.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateDomain.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateDomain.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch(`${this.basePath}/businessRules/domains/${id}`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeDomain
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeDomain(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeDomain.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeDomain.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeDomain.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete(`${this.basePath}/businessRules/domains/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * createGroup
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addGroup(
    token: string,
    orgCode: string,
    body: BrlGroup,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlGroup>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addGroup.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addGroup.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addGroup.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(`${this.basePath}/businessRules/groups`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getGroup
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param mapLevel
   */
  public getGroup(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlGroup>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getGroup.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getGroup.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getGroup.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/groups/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getGroups
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getGroups(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlGroup[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getGroups.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getGroups.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/groups`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * updateGroup
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateGroup(
    token: string,
    orgCode: string,
    id: number,
    body: BrlGroup,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlGroup>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateGroup.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateGroup.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateGroup.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateGroup.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch(`${this.basePath}/businessRules/groups/${id}`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeGroup
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeGroup(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeGroup.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeGroup.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeGroup.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete(`${this.basePath}/businessRules/groups/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * addApi
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addApi(
    token: string,
    orgCode: string,
    body: BrlApi,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlApi>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addApi.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addApi.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addApi.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(`${this.basePath}/businessRules/apis`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * createApiServer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addApiServer(
    token: string,
    orgCode: string,
    body: BrlApiServer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlApiServer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addApiServer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addApiServer.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addApiServer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(`${this.basePath}/businessRules/apiServers`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * duplicateApi
   *
   * @param token Token
   * @param orgCode Organization Code

   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateApi(
    token: string,
    orgCode: string,
    id: number,
    body: BrlApi,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlApi>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateApi.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateApi.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateApi.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateApi.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(
      `${this.basePath}/businessRules/apis/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateApiServer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param serverId
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateApiServer(
    token: string,
    orgCode: string,
    serverId: number,
    id: number,
    body: BrlApiServer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlApiServer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateApiServer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateApiServer.'
      );
    }

    if (serverId === null || serverId === undefined) {
      throw new Error(
        'Required parameter serverId was null or undefined when calling duplicateApiServer.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateApiServer.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateApiServer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(`${this.basePath}/businessRules/apiServers/${id}`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getApi
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param mapLevel
   */
  public getApi(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlApi>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getApi.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getApi.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getApi.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/apis/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getApiServer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param serverId
   * @param id
   * @param mapLevel
   */
  public getApiServer(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlApiServer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getApiServer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getApiServer.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getApiServer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/apiServers/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getApiServers
   *
   * @param token Token
   * @param orgCode Organization Code

   * @param mapLevel
   */
  public getApiServers(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlApiServer[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getApiServers.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getApiServers.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/apiServers`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getApis
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getApis(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlApi[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getApis.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getApis.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/apis`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * updateApi
   *
   * @param token Token
   * @param orgCode Organization Code

   * @param id
   * @param body
   * @param mapLevel
   */
  public updateApi(
    token: string,
    orgCode: string,
    id: number,
    body: BrlApi,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlApi>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateApi.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateApi.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateApi.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateApi.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch(`${this.basePath}/businessRules/apis/${id}`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * updateApiServer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param serverId
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateApiServer(
    token: string,
    orgCode: string,
    id: number,
    body: BrlApiServer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlApiServer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateApiServer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateApiServer.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateApiServer.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateApiServer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch(
      `${this.basePath}/businessRules/apiServers/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * removeApi
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeApi(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeApi.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeApi.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeApi.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete(`${this.basePath}/businessRules/apis/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeApiServer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param serverId
   * @param id
   */
  public removeApiServer(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeApiServer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeApiServer.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeApiServer.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete(`${this.basePath}/businessRules/apiServers/${id}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * createServer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public addServer(
    token: string,
    orgCode: string,
    body: BrlServer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlServer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addServer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addServer.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addServer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(`${this.basePath}/businessRules/servers`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * duplicateServer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateServer(
    token: string,
    orgCode: string,

    id: number,
    body: BrlServer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlServer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateServer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateServer.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateServer.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateServer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(
      `${this.basePath}/businessRules/servers/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getServer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param mapLevel
   */
  public getServer(
    token: string,
    orgCode: string,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlServer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getServer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getServer.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getServer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/servers/${id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * getServers
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param mapLevel
   */
  public getServers(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlServer[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getServers.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getServers.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/servers`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * updateServer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateServer(
    token: string,
    orgCode: string,
    id: number,
    body: BrlServer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlServer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateServer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateServer.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateServer.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateServer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch(`${this.basePath}/businessRules/servers/${id}`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * removeServer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param id
   */
  public removeServer(
    token: string,
    orgCode: string,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeServer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeServer.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeServer.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete(`${this.basePath}/businessRules/servers/${id} `, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * createParameter
   *
   * @param body
   * @param orgCode Organization Code
   * @param serviceId
   * @param token Token
   * @param mapLevel
   */
  public addParameter(
    token: string,
    orgCode: string,
    serviceId: number,
    body: BrlParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addParameter.'
      );
    }

    if (serviceId === null || serviceId === undefined) {
      throw new Error(
        'Required parameter serviceId was null or undefined when calling addParameter.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(
      `${this.basePath}/businessRules/parameters/${serviceId}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * duplicateParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param serviceId
   * @param id
   * @param body
   * @param mapLevel
   */
  public duplicateParameter(
    token: string,
    orgCode: string,
    serviceId: number,
    id: number,
    body: BrlParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling duplicateParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling duplicateParameter.'
      );
    }

    if (serviceId === null || serviceId === undefined) {
      throw new Error(
        'Required parameter serviceId was null or undefined when calling duplicateParameter.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling duplicateParameter.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling duplicateParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(
      `${this.basePath}/businessRules/parameters/${serviceId}/duplicate/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param serviceId
   * @param id
   * @param mapLevel
   */
  public getParameter(
    token: string,
    orgCode: string,
    serviceId: number,
    id: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getParameter.'
      );
    }

    if (serviceId === null || serviceId === undefined) {
      throw new Error(
        'Required parameter serviceId was null or undefined when calling getParameter.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(
      `${this.basePath}/businessRules/parameters/${serviceId}/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * getParameters
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param serviceId
   * @param mapLevel
   */
  public getParameters(
    token: string,
    orgCode: string,
    serviceId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getParameters.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getParameters.'
      );
    }

    if (serviceId === null || serviceId === undefined) {
      throw new Error(
        'Required parameter serviceId was null or undefined when calling getParameters.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get(`${this.basePath}/businessRules/parameters/${serviceId}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * updateParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param serviceId
   * @param id
   * @param body
   * @param mapLevel
   */
  public updateParameter(
    token: string,
    orgCode: string,
    serviceId: number,
    id: number,
    body: BrlParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<BrlParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling updateParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateParameter.'
      );
    }

    if (serviceId === null || serviceId === undefined) {
      throw new Error(
        'Required parameter serviceId was null or undefined when calling updateParameter.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateParameter.'
      );
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch(
      `${this.basePath}/businessRules/parameters/${serviceId}/${id}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * removeParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param serviceId
   * @param id
   */
  public removeParameter(
    token: string,
    orgCode: string,
    serviceId: number,
    id: number
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeParameter.'
      );
    }

    if (serviceId === null || serviceId === undefined) {
      throw new Error(
        'Required parameter serviceId was null or undefined when calling removeParameter.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeParameter.'
      );
    }

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete(
      `${this.basePath}/businessRules/parameters/${serviceId}/${id}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * runTest
   *
   */

  public runTest(
    token: string,
    orgCode: string,
    inputParameters?: string,
    headerKeys?: string,
    input?: string
  ): Promise<AxiosResponse<any>> {
    if (inputParameters === null || inputParameters === undefined) {
      throw new Error(
        'Required parameter inputParameters was null or undefined when calling runTest.'
      );
    }
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeParameter.'
      );
    }
    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    const queryParameters = new URLSearchParams();

    if (input === null || input === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling runTest.'
      );
    }

    // add keys
    const headerMessages = {} as any;
    if (headerKeys) {
      const keys: String[] = headerKeys.split(';');
      var arrayLength = keys.length;
      for (var i = 0; i < arrayLength; i++) {
        const values: String[] = keys[i].split('=');
        headerMessages[values[0].toString()] = values[1].toString();
      }
    }

    const message = {
      url: inputParameters,
      headers: headerMessages,
      body: JSON.parse(input),
    };

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post(`${this.basePath}/custom/brl/test`, message, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * runGetUrl
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   */
  public runGetUrl(
    token: string,
    orgCode: string,
    url: string
  ): Promise<AxiosResponse> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling runTest.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling runTest.'
      );
    }

    if (url === null || url === undefined) {
      throw new Error(
        'Required parameter url was null or undefined when calling runTest.'
      );
    }

    const queryParameters = new URLSearchParams();

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    headers['session-id'] = String('e9d66808-41d7-41f9-a3e3-0d6362de3c4a');

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<any>(`${url}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }
}
