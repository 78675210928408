import React, { useContext, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ApiContext } from 'src/context/ApiContext';
import { APISessionService } from 'src/api/api-gc/service';
import { getToken } from 'src/utils/token';
import { useSettings } from 'src/context';
import LogoutHelper from 'src/helpers/LogoutHelper';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

function Account({ ...props }) {
  const getOrgCode = () => {
    return props.computedMatch.params.orgCode;
  };

  const apiContext = useContext(ApiContext);
  const apiSessionService = new APISessionService();
  const { settings } = useSettings();

  const classes = useStyles();
  const ref = useRef(null);
  // const account = useSelector(state => state.account);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    apiSessionService
      .disconnect(getOrgCode(), getToken())
      .then(() => {
        cleanSessionAndRedirect();
      })
      .catch(() => {
        cleanSessionAndRedirect();
      });
  };

  const cleanSessionAndRedirect = () => {
    LogoutHelper.logout(
      getOrgCode(),
      undefined,
      settings.loginMode === 'oauth' ? 'oauthDisconnected' : undefined
    );
  };

  const userFirstName = apiContext.user.userFirstName;
  const userLastName = apiContext.user.userLastName;

  return (
    <>
      <Box
        id="header-usermenu"
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}>
        <Avatar alt="User" className={classes.avatar} src={null} />
        <Hidden mdDown>
          <Typography variant="h6" color="inherit">
            {`${userFirstName} ${userLastName}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}>
        <MenuItem component={RouterLink} to={'/' + getOrgCode() + '/profile'}>
          Profile
        </MenuItem>
        <MenuItem component={RouterLink} to={'/' + getOrgCode() + '/account'}>
          Account
        </MenuItem>
        <MenuItem id="header-usermenu-logout" onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
