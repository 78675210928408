import { Configuration } from 'src/api/api-gc/configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';
import { DeviceInformation } from 'src/api/api-gc/model/deviceInformation';
import { searchDeviceInformationResponse } from 'src/api/api-gc/model/searchDeviceInformationsResponse';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APiDeviceInformationsService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   *get All Device Informations of an org
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param userId
   * @param entityId
   * @param profileId
   * @param mapLevel
   */
  public getDeviceInformations(
    token: string,
    orgCode: string,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<searchDeviceInformationResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter sessionId was null or undefined when calling addEntityProfilesToUser.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addEntityProfilesToUser.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.get<searchDeviceInformationResponse>(
      `${this.basePath}/catalog/productInformation`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   *get Product Information of an id
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productInformationId
   * @param mapLevel
   */
  public getDeviceInformationFromId(
    token: string,
    orgCode: string,
    productInformationId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<DeviceInformation>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter sessionId was null or undefined when calling getDeviceInformationFromId.'
      );
    }
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getDeviceInformationFromId.'
      );
    }
    if (productInformationId === null || productInformationId === undefined) {
      throw new Error(
        'Required parameter productInformationId was null or undefined when calling getDeviceInformationFromId.'
      );
    }
    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }
    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }
    return axios.get<DeviceInformation>(
      `${this.basePath}/catalog/productInformation/${productInformationId}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   *create a Product Information
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createDeviceInformation(
    token: string,
    orgCode: string,
    body: DeviceInformation,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<DeviceInformation>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter sessionId was null or undefined when calling createDeviceInformation.'
      );
    }
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createDeviceInformation.'
      );
    }
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling createDeviceInformation.'
      );
    }
    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }
    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }
    return axios.post<DeviceInformation>(
      `${this.basePath}/catalog/productInformation/`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * update a Product Information
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productInformationId
   * @param body
   * @param mapLevel
   */
  public updateDeviceInformation(
    token: string,
    orgCode: string,
    productInformationId: number,
    body: DeviceInformation,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<DeviceInformation>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter sessionId was null or undefined when calling updateDeviceInformation.'
      );
    }
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling updateDeviceInformation.'
      );
    }
    if (productInformationId === null || productInformationId === undefined) {
      throw new Error(
        'Required parameter productInformationId was null or undefined when calling updateDeviceInformation.'
      );
    }
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateDeviceInformation.'
      );
    }
    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }
    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }
    return axios.patch<DeviceInformation>(
      `${this.basePath}/catalog/productInformation/${productInformationId}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   *delete Product Information with the id
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param productInformationId
   * @param mapLevel
   */
  public deleteDeviceInformation(
    token: string,
    orgCode: string,
    productInformationId: number,
    mapLevel?: Array<string>
  ) {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter sessionId was null or undefined when calling getDeviceInformationFromId.'
      );
    }
    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getDeviceInformationFromId.'
      );
    }
    if (productInformationId === null || productInformationId === undefined) {
      throw new Error(
        'Required parameter productInformationId was null or undefined when calling getDeviceInformationFromId.'
      );
    }
    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }
    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }
    return axios.delete(
      `${this.basePath}/catalog/productInformation/${productInformationId}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
