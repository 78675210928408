/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Configuration } from 'src/api/api-gc/configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';
import {
  LogisticConfig,
  LogisticParam,
  LogisticProcess,
  SearchLogisticConfigsResponse,
  SearchLogisticParamsResponse,
  SearchLogisticProcessResponse,
} from '../model';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APILogisticService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates a logisticConfig
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createLogisticConfig(
    token: string,
    orgCode: string,
    body?: LogisticConfig,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticConfig>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createLogisticConfig.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createLogisticConfig.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<LogisticConfig>(
      `${this.basePath}/logistics/configs`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a logisticParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createLogisticParam(
    token: string,
    orgCode: string,
    body?: LogisticParam,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createLogisticParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createLogisticParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<LogisticParam>(
      `${this.basePath}/logistics/params`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a logisticProcess
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createLogisticProcess(
    token: string,
    orgCode: string,
    body?: LogisticProcess,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticProcess>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createLogisticProcess.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createLogisticProcess.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<LogisticProcess>(
      `${this.basePath}/logistics/process`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a logisticConfig
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param configId
   */
  public deleteLogisticConfig(
    token: string,
    orgCode: string,
    configId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteLogisticConfig.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteLogisticConfig.'
      );
    }

    if (configId === null || configId === undefined) {
      throw new Error(
        'Required parameter configId was null or undefined when calling deleteLogisticConfig.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/logistics/configs/${encodeURIComponent(
        String(configId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a logisticParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   */
  public deleteLogisticParam(
    token: string,
    orgCode: string,
    paramId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteLogisticParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteLogisticParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling deleteLogisticParam.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/logistics/params/${encodeURIComponent(
        String(paramId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a logisticProcess
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param processId
   */
  public deleteLogisticProcess(
    token: string,
    orgCode: string,
    processId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteLogisticProcess.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteLogisticProcess.'
      );
    }

    if (processId === null || processId === undefined) {
      throw new Error(
        'Required parameter processId was null or undefined when calling deleteLogisticProcess.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/logistics/process/${encodeURIComponent(
        String(processId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a logisticConfig
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param configId
   * @param mapLevel
   */
  public getLogisticConfig(
    token: string,
    orgCode: string,
    configId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticConfig>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticConfig.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticConfig.'
      );
    }

    if (configId === null || configId === undefined) {
      throw new Error(
        'Required parameter configId was null or undefined when calling getLogisticConfig.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<LogisticConfig>(
      `${this.basePath}/logistics/configs/${encodeURIComponent(
        String(configId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search logisticConfigs
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param configCode
   * @param configName
   */
  public getLogisticConfigs(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    configCode?: string,
    configName?: string,
    carrierId?: number
  ): Promise<AxiosResponse<SearchLogisticConfigsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticConfigs.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticConfigs.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (configCode !== undefined && configCode !== null) {
      queryParameters.append('configCode', configCode as any);
    }
    if (configName !== undefined && configName !== null) {
      queryParameters.append('configName', configName as any);
    }
    if (carrierId !== undefined && carrierId !== null) {
      queryParameters.append('carrierId', carrierId as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchLogisticConfigsResponse>(
      `${this.basePath}/logistics/configs`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a logisticParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param mapLevel
   */
  public getLogisticParam(
    token: string,
    orgCode: string,
    paramId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling getLogisticParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<LogisticParam>(
      `${this.basePath}/logistics/params/${encodeURIComponent(
        String(paramId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search logisticParams
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   */
  public getLogisticParams(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean
  ): Promise<AxiosResponse<SearchLogisticParamsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticParams.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticParams.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchLogisticParamsResponse>(
      `${this.basePath}/logistics/params`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a logisticProcess
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param processId
   * @param mapLevel
   */
  public getLogisticProcess(
    token: string,
    orgCode: string,
    processId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticProcess>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticProcess.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticProcess.'
      );
    }

    if (processId === null || processId === undefined) {
      throw new Error(
        'Required parameter processId was null or undefined when calling getLogisticProcess.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<LogisticProcess>(
      `${this.basePath}/logistics/process/${encodeURIComponent(
        String(processId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search logisticProcesses
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param processCode
   * @param processName
   * @param processCatItemId
   * @param processLegCode
   * @param processBagType
   * @param processBagStatus
   */
  public getLogisticProcesses(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    processCode?: string,
    processName?: string,
    processCatItemId?: number,
    processLegCode?: string,
    processBagType?: string,
    processBagStatus?: string
  ): Promise<AxiosResponse<SearchLogisticProcessResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLogisticProcesses.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLogisticProcesses.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (processCode !== undefined && processCode !== null) {
      queryParameters.append('processCode', processCode as any);
    }
    if (processName !== undefined && processName !== null) {
      queryParameters.append('processName', processName as any);
    }
    if (processCatItemId !== undefined && processCatItemId !== null) {
      queryParameters.append('processCatItemId', processCatItemId as any);
    }
    if (processLegCode !== undefined && processLegCode !== null) {
      queryParameters.append('processLegCode', processLegCode as any);
    }
    if (processBagType !== undefined && processBagType !== null) {
      queryParameters.append('processBagType', processBagType as any);
    }
    if (processBagStatus !== undefined && processBagStatus !== null) {
      queryParameters.append('processBagStatus', processBagStatus as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchLogisticProcessResponse>(
      `${this.basePath}/logistics/process`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a logisticConfig
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param configId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyLogisticConfig(
    token: string,
    orgCode: string,
    configId: number,
    body?: LogisticConfig,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<LogisticConfig>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyLogisticConfig.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyLogisticConfig.'
      );
    }

    if (configId === null || configId === undefined) {
      throw new Error(
        'Required parameter configId was null or undefined when calling modifyLogisticConfig.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<LogisticConfig>(
      `${this.basePath}/logistics/configs/${encodeURIComponent(
        String(configId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a logisticParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyLogisticParam(
    token: string,
    orgCode: string,
    paramId: number,
    body?: LogisticParam,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<LogisticParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyLogisticParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyLogisticParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyLogisticParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<LogisticParam>(
      `${this.basePath}/logistics/params/${encodeURIComponent(
        String(paramId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a logisticProcess
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param processId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyLogisticProcess(
    token: string,
    orgCode: string,
    processId: number,
    body?: LogisticProcess,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<LogisticProcess>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyLogisticProcess.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyLogisticProcess.'
      );
    }

    if (processId === null || processId === undefined) {
      throw new Error(
        'Required parameter processId was null or undefined when calling modifyLogisticProcess.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<LogisticProcess>(
      `${this.basePath}/logistics/process/${encodeURIComponent(
        String(processId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a logisticConfig
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param configId
   * @param body
   * @param mapLevel
   */
  public replaceLogisticConfig(
    token: string,
    orgCode: string,
    configId: number,
    body?: LogisticConfig,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticConfig>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceLogisticConfig.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceLogisticConfig.'
      );
    }

    if (configId === null || configId === undefined) {
      throw new Error(
        'Required parameter configId was null or undefined when calling replaceLogisticConfig.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<LogisticConfig>(
      `${this.basePath}/logistics/configs/${encodeURIComponent(
        String(configId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a logisticParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public replaceLogisticParam(
    token: string,
    orgCode: string,
    paramId: number,
    body?: LogisticParam,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticParam>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceLogisticParam.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceLogisticParam.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling replaceLogisticParam.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<LogisticParam>(
      `${this.basePath}/logistics/params/${encodeURIComponent(
        String(paramId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Replace a logisticProcess
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param processId
   * @param body
   * @param mapLevel
   */
  public replaceLogisticProcess(
    token: string,
    orgCode: string,
    processId: number,
    body?: LogisticProcess,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<LogisticProcess>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceLogisticProcess.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceLogisticProcess.'
      );
    }

    if (processId === null || processId === undefined) {
      throw new Error(
        'Required parameter processId was null or undefined when calling replaceLogisticProcess.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<LogisticProcess>(
      `${this.basePath}/logistics/process/${encodeURIComponent(
        String(processId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * patch a list of logistics process of an leg
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public modifyLogisticProcessByLeg(
    token: string,
    orgCode: string,
    logisticLegId: number,
    body: LogisticProcess[],
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<SearchLogisticProcessResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyLogisticProcessByLeg.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyLogisticProcessByLeg.'
      );
    }

    if (logisticLegId === null || logisticLegId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyLogisticProcessByLeg.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<SearchLogisticProcessResponse>(
      `${this.basePath}/logistics/leg/${encodeURIComponent(
        String(logisticLegId)
      )}/process`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * delete a list of logistics process linked to a leg
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param paramId
   * @param mapLevel
   */
  public deleteLogisticProcessByLeg(
    token: string,
    orgCode: string,
    logisticLegId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<SearchLogisticProcessResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyLogisticProcessByLeg.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyLogisticProcessByLeg.'
      );
    }

    if (logisticLegId === null || logisticLegId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyLogisticProcessByLeg.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/logistics/leg/${encodeURIComponent(
        String(logisticLegId)
      )}/process`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
