import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layout/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthRoute from 'src/components/AuthRoute';
import GuestRoute from 'src/components/GuestRoute';
import Login from './views/login/Login';
import OAuthFail from './views/login/OAuthFail';
import OAuthSuccess from './views/login/OAuthSuccess';
import Logout from './views/login/Logout';
import NotFound from './views/pages/notfound';
import RequestResetPassword from './views/login/password/RequestResetPassword';
import ResetPassword from './views/login/password/ResetPassword';
import RequestResetPasswordSent from './views/login/password/RequestResetPasswordSent';
import EditPasswordSuccess from './views/login/password/EditPasswordSuccess';
import ChangePassword from './views/login/password/ChangePassword';
import OneTimePassword from './views/login/twoFactorAuthentication/OneTimePassword';
import KeycloakGroupInvitationJoinView from './views/keycloak/KeycloakGroupInvitationJoinView';
import KeycloakGroupInvitationCreateView from './views/keycloak/KeycloakGroupInvitationCreateView';
import KeycloakGroupInvitationSuccessView from './views/keycloak/KeycloakGroupInvitationSuccessView';
import KeycloakGroupInvitationFail from './views/keycloak/KeycloakGroupInvitationFail';

const pathList = '/:orgCode/(.*?/|)list/:listCode';
const pathCategory = '/:orgCode/(.*?/|)category/:categoryCode';
const pathMapGroup = '/:orgCode/(.*?/|)map-group/:mapGroup';

export const routes = [
  {
    path: '/:orgCode/(.*?/|)entity-types',
    file: () => import('src/views/config/entityTypes/EntityTypesListView'),
  },
  {
    path: '/:orgCode/(.*?/|)entity-types/create',
    file: () => import('src/views/config/entityTypes/EntityTypeCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)entity-types/:id',
    file: () => import('src/views/config/entityTypes/EntityTypeEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)menus',
    file: () => import('src/views/config/menus/MenuScreen'),
  },
  {
    path: '/:orgCode/(.*?/|)lists',
    file: () => import('src/views/config/lists/ListsView'),
  },
  {
    path: '/:orgCode/(.*?/|)lists/create',
    file: () => import('src/views/config/lists/ListCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)lists/:id',
    file: () => import('src/views/config/lists/ListEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)organization-keys',
    file: () =>
      import('src/views/config/organizationKeys/OrganizationKeysView'),
  },
  {
    path: '/:orgCode/(.*?/|)urls',
    file: () => import('src/views/config/urls/UrlsListView'),
  },
  {
    path: '/:orgCode/(.*?/|)urls/create',
    file: () => import('src/views/config/urls/UrlCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)urls/:id',
    file: () => import('src/views/config/urls/UrlEditView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/conditions/:action/:id',
    file: () => import('src/views/brl/ConditionDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/functions/:action/:id',
    file: () => import('src/views/brl/FunctionDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/enums/:action/:id',
    file: () => import('src/views/brl/EnunDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/actions/:action/:id',
    file: () => import('src/views/brl/ActionDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/entities/:action/:id',
    file: () => import('src/views/brl/EntityDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules',
    file: () => import('src/views/brl/RulesView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/brs/:action/:id',
    file: () => import('src/views/brl/RuleDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/rules/brlgroups/:action/:id',
    file: () => import('src/views/brl/GroupDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/tests',
    file: () => import('src/views/brl/TestsView'),
  },
  {
    path: '/:orgCode/claim-rules/tests/test/:action/:id',
    file: () => import('src/views/brl/TestDetailsView'),
  },
  {
    path: '/:orgCode/claim-rules/services',
    file: () => import('src/views/brl/ServicesView'),
  },
  {
    path: '/:orgCode/claim-rules/configuration',
    file: () => import('src/views/brl/ConfigurationView'),
  },
  {
    path: '/:orgCode/dashboard',
    //file: () => import('src/views/DashboardView'),
    file: () => import('src/views/DashboardView/TemporaryDashboard'),
  },
  {
    path: '/:orgCode/dashboard-test',
    file: () => import('src/views/DashboardView'),
  },
  {
    path: '/:orgCode/(.*?/|)entities',
    file: () => import('src/views/organization/EntitiyListView'),
  },
  {
    path: '/:orgCode/(.*?/|)entities/create',
    file: () => import('src/views/organization/EntityCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)entities/:id',
    file: () => import('src/views/organization/EntityEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-parameters',
    file: () => import('src/views/catalog/parameters/ParametersView'),
  },
  {
    path: pathCategory,
    file: () => import('src/views/categories/CategoryItemsView'),
  },
  {
    path: '/:orgCode/(.*?/|)categories',
    file: () => import('src/views/categories/CategoriesView'),
  },
  {
    path: '/:orgCode/(.*?/|)categories/create',
    file: () => import('src/views/categories/CategoryCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)categories/:id',
    file: () => import('src/views/categories/CategoryEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)manufacturers-parameters',
    file: () =>
      import('src/views/catalog/manufacturers/parameters/ParametersView'),
  },
  {
    path: '/:orgCode/(.*?/|)manufacturers',
    file: () => import('src/views/catalog/manufacturers/ManufacturersView'),
  },
  {
    path: '/:orgCode/(.*?/|)manufacturers/create',
    file: () =>
      import('src/views/catalog/manufacturers/ManufacturersCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)manufacturers/:id',
    file: () => import('src/views/catalog/manufacturers/ManufacturersEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)news',
    file: () => import('src/views/news/NewsView'),
  },
  {
    path: '/:orgCode/(.*?/|)news/create',
    file: () => import('src/views/news/NewsCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)news/:id',
    file: () => import('src/views/news/NewsEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products',
    file: () => import('src/views/catalog/products/ProductsBasicView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products/create',
    file: () => import('src/views/catalog/products/ProductCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products/:id',
    file: () => import('src/views/catalog/products/ProductEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products-tree',
    file: () => import('src/views/catalog/products/ProductsTreeView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products-tree/create',
    file: () => import('src/views/catalog/products/ProductCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)catalog-products-tree/:id',
    file: () => import('src/views/catalog/products/ProductEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)users',
    file: () => import('src/views/organization/users/UsersListView'),
  },
  {
    path: '/:orgCode/(.*?/|)users/create',
    file: () => import('src/views/organization/users/UserCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)users/:id',
    file: () => import('src/views/organization/users/UserEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)acls',
    file: () => import('src/views/security/acls/AclsListView'),
  },
  {
    path: '/:orgCode/(.*?/|)profile-parameters',
    file: () =>
      import('src/views/security/profileParameters/ProfileParametersListView'),
  },
  {
    path: '/:orgCode/(.*?/|)profiles',
    file: () => import('src/views/security/profiles/ProfilesListView'),
  },
  {
    path: '/:orgCode/(.*?/|)profiles/create',
    file: () => import('src/views/security/profiles/ProfileCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)profiles/duplicate/:id',
    file: () => import('src/views/security/profiles/ProfileDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)profiles/:id',
    file: () => import('src/views/security/profiles/ProfileEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process',
    file: () => import('src/views/process/ProcessView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process-rules',
    file: () => import('src/views/process/rules/ProcessRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process-rules/create',
    file: () => import('src/views/process/rules/ProcessRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process-rules/duplicate/:id',
    file: () => import('src/views/process/rules/ProcessRulesDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process-rules/:id',
    file: () => import('src/views/process/rules/ProcessRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)booking-process-workflow/:processCode',
    file: () => import('src/views/process/workflow/ProcessWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-delivery-rules',
    file: () => import('src/views/logistic/delivery/rules/DeliveryRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-delivery-rules/create',
    file: () =>
      import('src/views/logistic/delivery/rules/DeliveryRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-delivery-rules/duplicate/:id',
    file: () =>
      import('src/views/logistic/delivery/rules/DeliveryRulesDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-delivery-rules/:id',
    file: () =>
      import('src/views/logistic/delivery/rules/DeliveryRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)template-rules',
    file: () => import('src/views/template/rules/TemplateRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)template-rules/create',
    file: () => import('src/views/template/rules/TemplateRuleCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)template-rules/duplicate/:id',
    file: () => import('src/views/template/rules/TemplateRuleDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)template-rules/:id',
    file: () => import('src/views/template/rules/TemplateRuleEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)templates',
    file: () => import('src/views/template/TemplateListView'),
  },
  {
    path: '/:orgCode/(.*?/|)templates/create',
    file: () => import('src/views/template/TemplateCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)templates/:id',
    file: () => import('src/views/template/TemplateEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-workflow',
    file: () => import('src/views/logistic/workflow/LogisticWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)order-workflow',
    file: () => import('src/views/order/workflow/OrderWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)quotation-workflow',
    file: () => import('src/views/quotation/workflow/QuotationWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)return-workflow',
    file: () =>
      import('src/views/returnRequest/workflow/ReturnRequestWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)treatment-workflow',
    file: () => import('src/views/treatment/workflow/TreatmentWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)operation-rules',
    file: () =>
      import('src/views/treatment/operationRules/TreatmentOperationRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)operation-rules/create',
    file: () =>
      import(
        'src/views/treatment/operationRules/TreatmentOperationRuleCreateView'
      ),
  },
  {
    path: '/:orgCode/(.*?/|)operation-rules/duplicate/:id',
    file: () =>
      import(
        'src/views/treatment/operationRules/TreatmentOperationRuleDuplicateView'
      ),
  },
  {
    path: '/:orgCode/(.*?/|)operation-rules/:id',
    file: () =>
      import(
        'src/views/treatment/operationRules/TreatmentOperationRuleEditView'
      ),
  },
  {
    path: pathList,
    file: () => import('src/views/list/ListView'),
  },
  {
    path: pathMapGroup,
    file: () => import('src/views/mapGroup/MapGroupView'),
  },
  {
    path: '/:orgCode/(.*?/|)api-users',
    file: () => import('src/views/api/users/ApiUsersView'),
  },
  {
    path: '/:orgCode/(.*?/|)api-users/create',
    file: () => import('src/views/api/users/ApiUsersCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)api-users/:id',
    file: () => import('src/views/api/users/ApiUsersEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)task-types',
    file: () => import('src/views/task/type/TaskTypesListView'),
  },
  {
    path: '/:orgCode/(.*?/|)task-types/create',
    file: () => import('src/views/task/type/TaskTypeCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)task-types/:id',
    file: () => import('src/views/task/type/TaskTypeEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)tasks',
    file: () => import('src/views/task/TasksListView'),
  },
  {
    path: '/:orgCode/(.*?/|)tasks/create',
    file: () => import('src/views/task/TaskCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)tasks/:id',
    file: () => import('src/views/task/TaskEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-policies',
    file: () => import('src/views/insurance/policies/InsurancePoliciesView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-policies/create',
    file: () =>
      import('src/views/insurance/policies/InsurancePolicyCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-policies/duplicate/:id',
    file: () =>
      import('src/views/insurance/policies/InsurancePolicyDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-policies/:id',
    file: () => import('src/views/insurance/policies/InsurancePolicyEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-equipements',
    file: () =>
      import('src/views/insurance/equipments/InsuranceEquipmentsView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-equipements/create',
    file: () =>
      import('src/views/insurance/equipments/InsuranceEquipmentCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)insurance-equipements/:id',
    file: () =>
      import('src/views/insurance/equipments/InsuranceEquipmentEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)trade-in-grid-prices',
    file: () => import('src/views/tradeIn/gridPrices/GridPricesListView'),
  },
  {
    path: '/:orgCode/404',
    file: () => import('src/views/pages/Error404View'),
  },
  {
    path: '/:orgCode/(.*?/|)quote-option-rules',
    file: () => import('src/views/quoteOption/rules/QuoteOptionRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)quote-option-rules/create',
    file: () =>
      import('src/views/quoteOption/rules/QuoteOptionRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)quote-option-rules/duplicate/:id',
    file: () =>
      import('src/views/quoteOption/rules/QuoteOptionRulesDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)quote-option-rules/:id',
    file: () => import('src/views/quoteOption/rules/QuoteOptionRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-rules',
    file: () => import('src/views/warranty/rules/WarrantyRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-rules/create',
    file: () => import('src/views/warranty/rules/WarrantyRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-rules/duplicate/:id',
    file: () => import('src/views/warranty/rules/WarrantyRulesDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-rules/:id',
    file: () => import('src/views/warranty/rules/WarrantyRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)dictionary-data',
    file: () => import('src/views/DictionaryData/DictionaryDataListView'),
  },
  {
    path: '/:orgCode/(.*?/|)dictionary-data/domain/:domain',
    file: () => import('src/views/DictionaryData/DictionaryDataDomainView'),
  },
  {
    path: '/:orgCode/(.*?/|)dictionary-data/create',
    file: () => import('src/views/DictionaryData/DictionaryDataCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)dictionary-data/:id',
    file: () => import('src/views/DictionaryData/DictionaryDataEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)configuration-ui',
    file: () => import('src/views/ConfigurationUI/ConfigurationUIItemsView'),
  },
  {
    path: '/:orgCode/(.*?/|)screening-forms',
    file: () => import('src/views/screening/ScreeningFormsListView'),
  },
  {
    path: '/:orgCode/(.*?/|)screening-forms/create',
    file: () => import('src/views/screening/ScreeningFormCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)screening-forms/duplicate/:id',
    file: () => import('src/views/screening/ScreeningFormsDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)screening-forms/:id',
    file: () => import('src/views/screening/ScreeningFormsEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)api-rules',
    file: () => import('src/views/ApiRules/ApiRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)api-rules/create',
    file: () => import('src/views/ApiRules/ApiRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)api-rules/duplicate/:id',
    file: () => import('src/views/ApiRules/ApiRulesDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)api-rules/:id',
    file: () => import('src/views/ApiRules/ApiRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)screening-state-rules/:formCode',
    file: () => import('src/views/screening/ScreeningStateRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-configs',
    file: () => import('src/views/logistic/config/LogisticConfigView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-parameters',
    file: () => import('src/views/logistic/parameters/ParametersView'),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-calculation-rules',
    file: () =>
      import('src/views/warrantyCalculation/WarrantyCalculationRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-calculation-rules/create',
    file: () =>
      import(
        'src/views/warrantyCalculation/WarrantyCalculationRulesCreateView'
      ),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-calculation-rules/duplicate/:id',
    file: () =>
      import(
        'src/views/warrantyCalculation/WarrantyCalculationRulesDuplicateView'
      ),
  },
  {
    path: '/:orgCode/(.*?/|)warranty-calculation-rules/:id',
    file: () =>
      import('src/views/warrantyCalculation/WarrantyCalculationRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)logistic-process-workflow',
    file: () =>
      import('src/views/logistic/process/LogisticProcessWorkflowView'),
  },
  {
    path: '/:orgCode/(.*?/|)stock-rules',
    file: () => import('src/views/stock/StockRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)stock-rules/create',
    file: () => import('src/views/stock/StockRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)stock-rules/duplicate/:id',
    file: () => import('src/views/stock/StockRulesDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)stock-rules/:id',
    file: () => import('src/views/stock/StockRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)urls-for-integration',
    file: () =>
      import('src/views/urlsForIntegration/UrlsForIntegrationListView'),
  },
  {
    path: '/:orgCode/(.*?/|)dashboard-data-rules',
    file: () => import('src/views/DashboardData/Rules/DashboardRulesView'),
  },
  {
    path: '/:orgCode/(.*?/|)dashboard-data-rules/create',
    file: () =>
      import('src/views/DashboardData/Rules/DashboardRulesCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)dashboard-data-rules/duplicate/:id',
    file: () =>
      import('src/views/DashboardData/Rules/DashboardRulesDuplicateView'),
  },
  {
    path: '/:orgCode/(.*?/|)dashboard-data-rules/:id',
    file: () => import('src/views/DashboardData/Rules/DashboardRulesEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)dashboard-data',
    file: () => import('src/views/DashboardData/DashboardView'),
  },
  {
    path: '/:orgCode/(.*?/|)dashboard-data/create',
    file: () => import('src/views/DashboardData/DashboardCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)dashboard-data/:id',
    file: () => import('src/views/DashboardData/DashboardEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)configurable-item-types',
    file: () =>
      import('src/views/ConfigurationItems/ConfigurationItemTypesView'),
  },
  {
    path: '/:orgCode/(.*?/|)devices-informations',
    file: () => import('src/views/deviceInformations/DeviceInformationView'),
  },
  {
    path: '/:orgCode/(.*?/|)devices-informations/create',
    file: () =>
      import('src/views/deviceInformations/DeviceInformationCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)devices-informations/:id',
    file: () =>
      import('src/views/deviceInformations/DeviceInformationEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)keycloak-users',
    file: () => import('src/views/keycloak/KeycloakUsersView'),
  },
  {
    path: '/:orgCode/(.*?/|)keycloak-users/create',
    file: () => import('src/views/keycloak/KeycloakUserCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)keycloak-users/:id',
    file: () => import('src/views/keycloak/KeycloakUserEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)keycloak-groups',
    file: () => import('src/views/keycloak/KeycloakGroupsView'),
  },
  {
    path: '/:orgCode/(.*?/|)keycloak-groups/:id',
    file: () => import('src/views/keycloak/KeycloakGroupEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)keycloak-pending-invites',
    file: () => import('src/views/keycloak/KeycloakInvitationsView'),
  },
  {
    path: '/:orgCode/(.*?/|)sla-threshold',
    file: () => import('src/views/slaThreshold/SlaThresholdListView'),
  },
  {
    path: '/:orgCode/(.*?/|)sla-threshold/create',
    file: () => import('src/views/slaThreshold/SlaThresholdCreateView'),
  },
  {
    path: '/:orgCode/(.*?/|)sla-threshold/:id',
    file: () => import('src/views/slaThreshold/SlaThresholdEditView'),
  },
  {
    path: '/:orgCode/(.*?/|)dev-tools',
    file: () => import('src/views/__devTools/DevTools'),
  },
];

function Routes() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from="/" to="/404" />
        <Route
          exact
          path="/404"
          component={lazy(() => import('src/views/pages/notfound'))}
        />
        {/*        <GuestRoute
          exact
          path="/:orgCode/login"
          //component={lazy(() => import('src/views/login'))}
          render={(props) => {
            //return <Login {...props} />;
            if (config.app.loginMode) {
              switch (config.app.loginMode) {
                case 'OAuth':
                  return <OAuth {...props} />;
                default:
                  return <Login {...props} />;
              }
            } else {
              return <Login {...props} />;
            }
          }}
        />*/}
        <GuestRoute
          exact
          path="/:orgCode/login"
          //component={lazy(() => import('src/views/login'))}
          render={(props) => <Login {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/login/two-factor-authentication"
          render={(props) => <OneTimePassword {...props} />}
        />
        {/*<GuestRoute
          exact
          path="/:orgCode/oauth"
          //component={lazy(() => import('src/views/login/OAuth'))}
          render={(props) => <OAuth {...props} />}
        />*/}
        <GuestRoute
          exact
          path="/:orgCode/oauthFail"
          //component={lazy(() => import('src/views/login/OAuthFail'))}
          render={(props) => <OAuthFail {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/oauthSuccess"
          //component={lazy(() => import('src/views/login/OAuthSuccess'))}
          render={(props) => <OAuthSuccess {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/disconnected"
          render={(props) => <Logout {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/request-reset-password"
          render={(props) => <RequestResetPassword {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/reset-password"
          render={(props) => <ResetPassword {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/request-reset-password-sent"
          render={(props) => <RequestResetPasswordSent {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/reset-password-success"
          render={(props) => <EditPasswordSuccess {...props} />}
        />

        <GuestRoute
          exact
          path="/:orgCode/keycloak/group-invitation/join"
          render={(props) => <KeycloakGroupInvitationJoinView {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/keycloak/group-invitation/create"
          render={(props) => <KeycloakGroupInvitationCreateView {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/keycloak/group-invitation/success"
          render={(props) => <KeycloakGroupInvitationSuccessView {...props} />}
        />
        <GuestRoute
          exact
          path="/:orgCode/keycloak/group-invitation/fail"
          render={(props) => <KeycloakGroupInvitationFail {...props} />}
        />

        <AuthRoute
          exact
          path="/:orgCode/change-password-success"
          render={(props) => <EditPasswordSuccess {...props} />}
        />
        <AuthRoute
          exact
          path="/:orgCode/change-password"
          render={(props) => <ChangePassword {...props} />}
        />
        <AuthRoute
          path="/:orgCode/"
          render={(props) => (
            <DashboardLayout {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Redirect exact from="/:orgCode/" to="/:orgCode/dashboard" />

                  {routes.map((item, index) => (
                    <Route
                      exact
                      lazy
                      path={item.path}
                      component={lazy(item.file)}
                      key={index}
                    />
                  ))}

                  {/*<Redirect
                    to={'/' + props.computedMatch.params.orgCode + '/404'}
                  />*/}

                  <Route
                    lazy
                    //component={lazy(() => import('src/views/pages/notfound'))}
                    render={() => <NotFound {...props} />}
                  />
                </Switch>
              </Suspense>
            </DashboardLayout>
          )}
        />
      </Switch>
    </Suspense>
  );
}

export default Routes;
