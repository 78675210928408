import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export default function useHashParamCustom() {
  const { pathname } = useLocation();

  const getHashSearchParams = (location: any) => {
    const hash = location.hash.slice(1);
    const [prefix, query] = hash.split('?');

    return [prefix, new URLSearchParams(query)];
  };

  const getHashParam = (key: string, defaultValue?: string) => {
    if (typeof window === 'undefined') {
      return defaultValue;
    }
    const [, searchParams] = getHashSearchParams(window.location);
    return searchParams.get(key);
  };

  const setHashParam = (key: string, value: string | undefined) => {
    if (typeof window !== 'undefined') {
      const type = window.location.search;
      const [prefix, searchParams] = getHashSearchParams(window.location);

      if (typeof value === 'undefined' || value === '') {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }

      const search = searchParams.toString();
      //window.location.hash = search ? `${prefix}?${search}` : prefix;
      window.history.replaceState(
        { hash: search ? `${prefix}?${search}` : prefix },
        '',
        `${pathname}${type}#${search ? `${prefix}?${search}` : prefix}`
      );
    }
  };

  const setHashParams = (params: Record<string, string | undefined>) => {
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        setHashParam(key, value);
      }
    }
  };

  const useHashParam = (key: string, defaultValue: string) => {
    const [innerValue, setInnerValue] = useState(
      getHashParam(key, defaultValue)
    );

    useEffect(() => {
      const handleHashChange = () =>
        setInnerValue(getHashParam(key, defaultValue));
      handleHashChange();
      window.addEventListener('hashchange', handleHashChange);
      return () => window.removeEventListener('hashchange', handleHashChange);
    }, [key]);

    const setValue = useCallback(
      (value) => {
        if (typeof value === 'function') {
          setHashParam(key, value(getHashParam(key, defaultValue)));
        } else {
          setHashParam(key, value);
        }
      },
      [key]
    );

    return [innerValue || defaultValue, setValue];
  };

  return { useHashParam, setHashParam, getHashParam, setHashParams };
}
