import React, { useState } from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { User } from 'src/api/api-gc/model';
import UsersListWithLazyLoading from 'src/components/Users/UsersListWithLazyLoading';

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflow: 'hidden',
  },
}));

interface UsersModalProps {
  open: boolean;
  onClose: (values: User[]) => void;
  onCancel: () => void;
  value?: string | number;
  multiple?: boolean;
  parentId?: string;
}

const UsersModal = ({
  onClose,
  onCancel,
  open,
  value,
  multiple = false,
  parentId,
}: UsersModalProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [selectedValues, setSelectedValues] = useState<User[]>([]);

  const handleCancel = () => {
    onCancel();
  };

  const onValuesChanged = (values: User[]) => {
    setSelectedValues(values);
  };

  const handleDoubleClick = (values: User[]) => {
    if (values && values.length > 0) {
      onClose(values);
    }
  };

  const handleClose = () => {
    if (selectedValues && selectedValues.length > 0) {
      onClose(selectedValues);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleCancel}
      open={open}
      // @ts-ignore
      parentid={parentId}>
      <DialogTitle>{t('modals.userSelection.title')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <UsersListWithLazyLoading
          open={open}
          onDoubleClick={handleDoubleClick}
          value={value}
          multiple={multiple}
          onValuesChanged={onValuesChanged}
          inPopin
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('modals.userSelection.buttons.cancel')}
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          {t('modals.userSelection.buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsersModal;
