/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  FormLnQuestion,
  QuestionLnAnswer,
  ScreeningForm,
  ScreeningQuestion,
  ScreeningAnswer,
  SearchScreeningAnswersResponse,
  SearchScreeningFormsResponse,
  SearchScreeningQuestionsResponse,
} from 'src/api/api-gc/model';
import { Configuration } from 'src/api/api-gc/configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIScreeningService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Add answers to question
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param questionId
   * @param body
   */
  public addAnswerToQuestion(
    token: string,
    orgCode: string,
    questionId: number,
    body?: QuestionLnAnswer
  ): Promise<AxiosResponse<QuestionLnAnswer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addAnswerToQuestion.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addAnswerToQuestion.'
      );
    }

    if (questionId === null || questionId === undefined) {
      throw new Error(
        'Required parameter questionId was null or undefined when calling addAnswerToQuestion.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<QuestionLnAnswer>(
      `${this.basePath}/screening/questions/${encodeURIComponent(
        String(questionId)
      )}/answers`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Add a question to form
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param formId
   * @param body
   */
  public addQuestionToForm(
    token: string,
    orgCode: string,
    formId: number,
    body?: FormLnQuestion
  ): Promise<AxiosResponse<FormLnQuestion>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling addQuestionToForm.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling addQuestionToForm.'
      );
    }

    if (formId === null || formId === undefined) {
      throw new Error(
        'Required parameter formId was null or undefined when calling addQuestionToForm.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<FormLnQuestion>(
      `${this.basePath}/screening/forms/${encodeURIComponent(
        String(formId)
      )}/questions`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates an answer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createAnswer(
    token: string,
    orgCode: string,
    body?: ScreeningAnswer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningAnswer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createAnswer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createAnswer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<ScreeningAnswer>(
      `${this.basePath}/screening/answers`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a form
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createForm(
    token: string,
    orgCode: string,
    body?: ScreeningForm,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningForm>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createForm.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createForm.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<ScreeningForm>(`${this.basePath}/screening/forms`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates a question
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createQuestion(
    token: string,
    orgCode: string,
    body?: ScreeningQuestion,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningQuestion>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createQuestion.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createQuestion.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<ScreeningQuestion>(
      `${this.basePath}/screening/questions`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete an answer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param answerId
   */
  public deleteAnswer(
    token: string,
    orgCode: string,
    answerId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteAnswer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteAnswer.'
      );
    }

    if (answerId === null || answerId === undefined) {
      throw new Error(
        'Required parameter answerId was null or undefined when calling deleteAnswer.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/screening/answers/${encodeURIComponent(
        String(answerId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a form
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param formId
   */
  public deleteForm(
    token: string,
    orgCode: string,
    formId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteForm.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteForm.'
      );
    }

    if (formId === null || formId === undefined) {
      throw new Error(
        'Required parameter formId was null or undefined when calling deleteForm.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/screening/forms/${encodeURIComponent(String(formId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Delete a question
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param questionId
   */
  public deleteQuestion(
    token: string,
    orgCode: string,
    questionId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteQuestion.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteQuestion.'
      );
    }

    if (questionId === null || questionId === undefined) {
      throw new Error(
        'Required parameter questionId was null or undefined when calling deleteQuestion.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/screening/questions/${encodeURIComponent(
        String(questionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get an answer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param answerId
   * @param mapLevel
   */
  public getAnswer(
    token: string,
    orgCode: string,
    answerId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningAnswer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getAnswer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getAnswer.'
      );
    }

    if (answerId === null || answerId === undefined) {
      throw new Error(
        'Required parameter answerId was null or undefined when calling getAnswer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<ScreeningAnswer>(
      `${this.basePath}/screening/answers/${encodeURIComponent(
        String(answerId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search answers
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param answerCode
   */
  public getAnswers(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    answerCode?: string
  ): Promise<AxiosResponse<SearchScreeningAnswersResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getAnswers.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getAnswers.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (answerCode !== undefined && answerCode !== null) {
      queryParameters.append('answerCode', answerCode as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchScreeningAnswersResponse>(
      `${this.basePath}/screening/answers`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a form
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param formId
   * @param mapLevel
   */
  public getForm(
    token: string,
    orgCode: string,
    formId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningForm>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getForm.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getForm.'
      );
    }

    if (formId === null || formId === undefined) {
      throw new Error(
        'Required parameter formId was null or undefined when calling getForm.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<ScreeningForm>(
      `${this.basePath}/screening/forms/${encodeURIComponent(String(formId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get forms
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param formCode
   * @param formVersion
   * @param gradeType
   */
  public getForms(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    formCode?: string,
    formVersion?: number,
    gradeType?: string
  ): Promise<AxiosResponse<SearchScreeningFormsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getForm1.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getForm1.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (formCode !== undefined && formCode !== null) {
      queryParameters.append('formCode', formCode as any);
    }
    if (formVersion !== undefined && formVersion !== null) {
      queryParameters.append('formVersion', formVersion as any);
    }
    if (gradeType !== undefined && gradeType !== null) {
      queryParameters.append('gradeType', gradeType as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchScreeningFormsResponse>(
      `${this.basePath}/screening/forms`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Search questions
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param questionCode
   */
  public getQuestion(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    questionCode?: string
  ): Promise<AxiosResponse<SearchScreeningQuestionsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getQuestion.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getQuestion.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (questionCode !== undefined && questionCode !== null) {
      queryParameters.append('questionCode', questionCode as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<SearchScreeningQuestionsResponse>(
      `${this.basePath}/screening/questions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a question
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param questionId
   * @param mapLevel
   */
  public getQuestion1(
    token: string,
    orgCode: string,
    questionId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningQuestion>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getQuestion1.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getQuestion1.'
      );
    }

    if (questionId === null || questionId === undefined) {
      throw new Error(
        'Required parameter questionId was null or undefined when calling getQuestion1.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<ScreeningQuestion>(
      `${this.basePath}/screening/questions/${encodeURIComponent(
        String(questionId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify an answer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param answerId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyAnswer(
    token: string,
    orgCode: string,
    answerId: number,
    body?: ScreeningAnswer,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ScreeningAnswer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyAnswer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyAnswer.'
      );
    }

    if (answerId === null || answerId === undefined) {
      throw new Error(
        'Required parameter answerId was null or undefined when calling modifyAnswer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<ScreeningAnswer>(
      `${this.basePath}/screening/answers/${encodeURIComponent(
        String(answerId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a form
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param formId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyForm(
    token: string,
    orgCode: string,
    formId: number,
    body?: ScreeningForm,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ScreeningForm>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyForm.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyForm.'
      );
    }

    if (formId === null || formId === undefined) {
      throw new Error(
        'Required parameter formId was null or undefined when calling modifyForm.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<ScreeningForm>(
      `${this.basePath}/screening/forms/${encodeURIComponent(String(formId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a question
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param questionId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyQuestion(
    token: string,
    orgCode: string,
    questionId: number,
    body?: ScreeningQuestion,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ScreeningQuestion>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyQuestion.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyQuestion.'
      );
    }

    if (questionId === null || questionId === undefined) {
      throw new Error(
        'Required parameter questionId was null or undefined when calling modifyQuestion.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<ScreeningQuestion>(
      `${this.basePath}/screening/questions/${encodeURIComponent(
        String(questionId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove an answer from a question
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param questionId
   * @param answerId
   */
  public removeAnswerFromQuestion(
    token: string,
    orgCode: string,
    questionId: number,
    answerId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeAnswerFromQuestion.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeAnswerFromQuestion.'
      );
    }

    if (questionId === null || questionId === undefined) {
      throw new Error(
        'Required parameter questionId was null or undefined when calling removeAnswerFromQuestion.'
      );
    }

    if (answerId === null || answerId === undefined) {
      throw new Error(
        'Required parameter answerId was null or undefined when calling removeAnswerFromQuestion.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/screening/questions/${encodeURIComponent(
        String(questionId)
      )}/answers/${encodeURIComponent(String(answerId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a question from form
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param formId
   * @param questionId
   */
  public removeQuestionFromForm(
    token: string,
    orgCode: string,
    formId: number,
    questionId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeQuestionFromForm.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeQuestionFromForm.'
      );
    }

    if (formId === null || formId === undefined) {
      throw new Error(
        'Required parameter formId was null or undefined when calling removeQuestionFromForm.'
      );
    }

    if (questionId === null || questionId === undefined) {
      throw new Error(
        'Required parameter questionId was null or undefined when calling removeQuestionFromForm.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${this.basePath}/screening/forms/${encodeURIComponent(
        String(formId)
      )}/questions/${encodeURIComponent(String(questionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update an answer
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param answerId
   * @param body
   * @param mapLevel
   */
  public replaceAnswer(
    token: string,
    orgCode: string,
    answerId: number,
    body?: ScreeningAnswer,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningAnswer>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceAnswer.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceAnswer.'
      );
    }

    if (answerId === null || answerId === undefined) {
      throw new Error(
        'Required parameter answerId was null or undefined when calling replaceAnswer.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<ScreeningAnswer>(
      `${this.basePath}/screening/answers/${encodeURIComponent(
        String(answerId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a form
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param formId
   * @param body
   * @param mapLevel
   */
  public replaceForm(
    token: string,
    orgCode: string,
    formId: number,
    body?: ScreeningForm,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningForm>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceForm.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceForm.'
      );
    }

    if (formId === null || formId === undefined) {
      throw new Error(
        'Required parameter formId was null or undefined when calling replaceForm.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<ScreeningForm>(
      `${this.basePath}/screening/forms/${encodeURIComponent(String(formId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a question
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param questionId
   * @param body
   * @param mapLevel
   */
  public replaceQuestion(
    token: string,
    orgCode: string,
    questionId: number,
    body?: ScreeningQuestion,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ScreeningQuestion>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceQuestion.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceQuestion.'
      );
    }

    if (questionId === null || questionId === undefined) {
      throw new Error(
        'Required parameter questionId was null or undefined when calling replaceQuestion.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<ScreeningQuestion>(
      `${this.basePath}/screening/questions/${encodeURIComponent(
        String(questionId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
