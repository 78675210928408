import config from 'src/config.js';

import {
  ListObject,
  ListParameter,
  ListValue,
  ListValueParameter,
  SearchListsResponse,
  SearchListValuesResponse,
} from '../model';
import { Configuration } from '../configuration';
import axios, { AxiosResponse } from 'axios';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIListService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates a list
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createList(
    token: string,
    orgCode: string,
    body?: ListObject,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ListObject>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createList.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createList.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<ListObject>(`${this.basePath}/lists`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
      params: queryParameters,
    });
  }

  /**
   * Creates a listValue
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   * @param body
   * @param mapLevel
   */
  public createListValue(
    token: string,
    orgCode: string,
    listId: number,
    body?: ListValue,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ListValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createListValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createListValue.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling createListValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    if (body && body.criteria) {
      if (!body.criteria.criteria) {
        // @ts-ignore
        body.criteria = null;
      } else {
        const criteria = JSON.parse(body.criteria?.criteria);

        if (
          criteria.rules[0].rules.length === 0 &&
          criteria.rules[1].rules.length === 0
        ) {
          // @ts-ignore
          body.criteria = null;
        }
      }
    }

    return axios.post<ListValue>(
      `${this.basePath}/lists/${encodeURIComponent(String(listId))}/values`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
        params: queryParameters,
      }
    );
  }

  /**
   * Get the list
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   * @param mapLevel
   */
  public getList(
    token: string,
    orgCode: string,
    listId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ListObject>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getList.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getList.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling getList.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<ListObject>(
      `${this.basePath}/lists/${encodeURIComponent(String(listId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the listValue
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   * @param valueId
   * @param mapLevel
   */
  public getListValue(
    token: string,
    orgCode: string,
    listId: number,
    valueId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ListValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getListValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getListValue.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling getListValue.'
      );
    }

    if (valueId === null || valueId === undefined) {
      throw new Error(
        'Required parameter valueId was null or undefined when calling getListValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<ListValue>(
      `${this.basePath}/lists/${encodeURIComponent(
        String(listId)
      )}/values/${encodeURIComponent(String(valueId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get listValues
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   * @param enable
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param valueCode
   */
  public getListValues(
    token: string,
    orgCode: string,
    listId: number,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    valueCode?: string
  ): Promise<AxiosResponse<SearchListValuesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getListValues.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getListValues.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling getListValues.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (enable !== undefined && enable !== null) {
      queryParameters.set('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.set('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.set('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (valueCode !== undefined && valueCode !== null) {
      queryParameters.set('valueCode', valueCode as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchListValuesResponse>(
      `${this.basePath}/lists/${encodeURIComponent(String(listId))}/values`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get lists
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param enable
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param listCode
   * @param listName
   * @param listDescription
   * @param listCatId
   * @param listCatItemId
   * @param listVisible
   * @param listEditable
   * @param listType
   */
  public getLists(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    listCode?: string,
    listName?: string,
    listDescription?: string,
    listCatId?: number,
    listCatItemId?: number,
    listVisible?: boolean,
    listEditable?: boolean,
    listType?: string
  ): Promise<AxiosResponse<SearchListsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getLists.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getLists.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (enable !== undefined && enable !== null) {
      queryParameters.set('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.set('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.set('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (listCode !== undefined && listCode !== null) {
      queryParameters.set('listCode', listCode as any);
    }
    if (listName !== undefined && listName !== null) {
      queryParameters.set('listName', listName as any);
    }
    if (listDescription !== undefined && listDescription !== null) {
      queryParameters.set('listDescription', listDescription as any);
    }
    if (listCatId !== undefined && listCatId !== null) {
      queryParameters.set('listCatId', listCatId as any);
    }
    if (listCatItemId !== undefined && listCatItemId !== null) {
      queryParameters.set('listCatItemId', listCatItemId as any);
    }
    if (listVisible !== undefined && listVisible !== null) {
      queryParameters.set('listVisible', listVisible as any);
    }
    if (listEditable !== undefined && listEditable !== null) {
      queryParameters.set('listEditable', listEditable as any);
    }
    if (listType !== undefined && listType !== null) {
      queryParameters.set('listType', listType as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchListsResponse>(`${this.basePath}/lists`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Modify a list
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyList(
    token: string,
    orgCode: string,
    listId: number,
    body?: ListObject,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ListObject>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyList.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyList.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling modifyList.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<ListObject>(
      `${this.basePath}/lists/${encodeURIComponent(String(listId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a listValue
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   * @param valueId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyListValue(
    token: string,
    orgCode: string,
    listId: number,
    valueId: number,
    body?: ListValue,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ListValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyListValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyListValue.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling modifyListValue.'
      );
    }

    if (valueId === null || valueId === undefined) {
      throw new Error(
        'Required parameter valueId was null or undefined when calling modifyListValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    if (body && body.criteria) {
      if (!body.criteria.criteria) {
        // @ts-ignore
        body.criteria = null;
      } else {
        const criteria = JSON.parse(body.criteria?.criteria);

        if (
          criteria.rules[0].rules.length === 0 &&
          criteria.rules[1].rules.length === 0
        ) {
          // @ts-ignore
          body.criteria = null;
        }
      }
    }

    return axios.patch<ListValue>(
      `${this.basePath}/lists/${encodeURIComponent(
        String(listId)
      )}/values/${encodeURIComponent(String(valueId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a list
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   */
  public removeList(
    token: string,
    orgCode: string,
    listId: number
  ): Promise<AxiosResponse<string>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeList.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeList.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling removeList.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<string>(
      `${this.basePath}/lists/${encodeURIComponent(String(listId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a listValue
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   * @param valueId
   */
  public removeListValue(
    token: string,
    orgCode: string,
    listId: number,
    valueId: number
  ): Promise<AxiosResponse<string>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeListValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeListValue.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling removeListValue.'
      );
    }

    if (valueId === null || valueId === undefined) {
      throw new Error(
        'Required parameter valueId was null or undefined when calling removeListValue.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<string>(
      `${this.basePath}/lists/${encodeURIComponent(
        String(listId)
      )}/values/${encodeURIComponent(String(valueId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a list
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   * @param body
   * @param mapLevel
   */
  public replaceList(
    token: string,
    orgCode: string,
    listId: number,
    body?: ListObject,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ListObject>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceList.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceList.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling replaceList.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<ListObject>(
      `${this.basePath}/lists/${encodeURIComponent(String(listId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a listValue
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   * @param valueId
   * @param body
   * @param mapLevel
   */
  public replaceListValue(
    token: string,
    orgCode: string,
    listId: number,
    valueId: number,
    body?: ListValue,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ListValue>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceListValue.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceListValue.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling replaceListValue.'
      );
    }

    if (valueId === null || valueId === undefined) {
      throw new Error(
        'Required parameter valueId was null or undefined when calling replaceListValue.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<ListValue>(
      `${this.basePath}/lists/${encodeURIComponent(
        String(listId)
      )}/values/${encodeURIComponent(String(valueId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get listParametres
   *
   * @param token Authentication token
   * @param orgCode Organization Code
   * @param listId
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param valueCode
   */
  public getListParams(
    token: string,
    orgCode: string,
    listId: number
  ): Promise<AxiosResponse<SearchListValuesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getListValues.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getListValues.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling getListValues.'
      );
    }

    const queryParameters = new URLSearchParams();

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchListValuesResponse>(
      `${this.basePath}/lists/${encodeURIComponent(String(listId))}/params`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a listValue
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param listId
   * @param body
   * @param mapLevel
   */
  public modifyListValues(
    token: string,
    orgCode: string,
    listId: number,
    body?: Array<ListValue>,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<Array<ListValue>>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyListValues.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyListValues.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling modifyListValues.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<Array<ListValue>>(
      `${this.basePath}/lists/${encodeURIComponent(String(listId))}/values`,

      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Creates a listParam
   *
   * @param token token
   * @param orgCode Organization Code
   * @param listId
   * @param body
   * @param mapLevel
   */
  public createListParameter(
    token: string,
    orgCode: string,
    listId: number,
    body?: ListParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ListParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createListParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createListParameter.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling createListParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<ListParameter>(
      `${this.basePath}/lists/${encodeURIComponent(String(listId))}/params`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
        params: queryParameters,
      }
    );
  }

  /**
   * Modify a listParam
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param listId
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public modifyListParameter(
    token: string,
    orgCode: string,
    listId: number,
    paramId: number,
    body?: ListParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ListParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyListParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyListParameter.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling modifyListParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyListParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<ListParameter>(
      `${this.basePath}/lists/${encodeURIComponent(
        String(listId)
      )}/params/${encodeURIComponent(String(paramId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
        params: queryParameters,
      }
    );
  }

  /**
   * Creates a listValueParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param listId
   * @param valueId
   * @param paramId
   * @param body
   * @param mapLevel
   */
  public createListValueParameter(
    token: string,
    orgCode: string,
    listId: number,
    valueId: number,
    paramId: number,
    body?: ListValueParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ListValueParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createListValueParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createListValueParameter.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling createListValueParameter.'
      );
    }

    if (valueId === null || valueId === undefined) {
      throw new Error(
        'Required parameter valueId was null or undefined when calling createListValueParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling createListValueParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<ListParameter>(
      `${this.basePath}/lists/${encodeURIComponent(
        String(listId)
      )}/values/${encodeURIComponent(
        String(valueId)
      )}/params/${encodeURIComponent(String(paramId))}/valueParams`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
        params: queryParameters,
      }
    );
  }

  /**
   * Modify a listValueParameter
   *
   * @param token token
   * @param orgCode Organization Code
   * @param listId
   * @param valueId
   * @param paramId
   * @param valueParamId
   * @param body
   * @param mapLevel
   */
  public modifyListValueParameter(
    token: string,
    orgCode: string,
    listId: number,
    valueId: number,
    paramId: number,
    valueParamId: number,
    body?: ListValueParameter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ListValueParameter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter sessionId was null or undefined when calling modifyListValueParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyListValueParameter.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling modifyListValueParameter.'
      );
    }

    if (valueId === null || valueId === undefined) {
      throw new Error(
        'Required parameter valueId was null or undefined when calling modifyListValueParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling modifyListValueParameter.'
      );
    }

    if (valueParamId === null || valueParamId === undefined) {
      throw new Error(
        'Required parameter valueParamId was null or undefined when calling modifyListValueParameter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<ListValueParameter>(
      `${this.basePath}/lists/${encodeURIComponent(
        String(listId)
      )}/values/${encodeURIComponent(
        String(valueId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/valueParams/${encodeURIComponent(String(valueParamId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
        params: queryParameters,
      }
    );
  }

  /**
   * Remove a listValueParameter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param listId
   * @param valueId
   * @param paramId
   * @param valueParamId
   */
  public removeListValueParameter(
    token: string,
    orgCode: string,
    listId: number,
    valueId: number,
    paramId: number,
    valueParamId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeListValueParameter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeListValueParameter.'
      );
    }

    if (listId === null || listId === undefined) {
      throw new Error(
        'Required parameter listId was null or undefined when calling removeListValueParameter.'
      );
    }

    if (valueId === null || valueId === undefined) {
      throw new Error(
        'Required parameter valueId was null or undefined when calling removeListValueParameter.'
      );
    }

    if (paramId === null || paramId === undefined) {
      throw new Error(
        'Required parameter paramId was null or undefined when calling removeListValueParameter.'
      );
    }

    if (valueParamId === null || valueParamId === undefined) {
      throw new Error(
        'Required parameter valueParamId was null or undefined when calling removeListValueParameter.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<string>(
      `${this.basePath}/lists/${encodeURIComponent(
        String(listId)
      )}/values/${encodeURIComponent(
        String(valueId)
      )}/params/${encodeURIComponent(
        String(paramId)
      )}/valueParams/${encodeURIComponent(String(valueParamId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
