import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Container,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { match, RouteProps } from 'react-router';
import PasswordTextField from 'src/components/UI/PasswordTextField';
import { APISessionService } from 'src/api/api-gc/service';
import Page from 'src/components/Page';
import { LoadingScreenContext } from 'src/context/LoadingScreenContext';
import { FieldType, FieldValidatorType } from 'src/interfaces';
import config from 'src/config';
import { history } from 'src/App';
import Copyright from 'src/views/login/Copyright';
import LogoutHelper from 'src/helpers/LogoutHelper';
import ErrorHelper from 'src/helpers/ErrorHelper';

const sessionService = new APISessionService();

interface LoginStateSchema {
  newPassword: FieldType;
  confirmPassword: FieldType;
}

interface LoginValidationStateSchema {
  newPassword: FieldValidatorType;
  confirmPassword: FieldValidatorType;
}

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  errorMessage: {
    width: '100%',
    textAlign: 'center',
    marginTop: '24px',
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    objectFit: 'contain',
    backgroundSize: 'contain',
  },

  form: {},
  submit: {},
  error: {
    color: 'red',
    fontSize: '13px',
  },
}));

const ResetPassword: React.FC<
  {
    computedMatch: match<{ orgCode: string }>;
  } & RouteProps
> = (props) => {
  const orgCode = useMemo(() => {
    return props.computedMatch.params.orgCode;
  }, [props]);
  const loadingScreenContext = useContext(LoadingScreenContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const useSearchParams = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const searchParams = useSearchParams();
  const tokenUrl = searchParams.get('token');
  //const [token, setToken] = useState<string | undefined | null>('');
  const [state, setState] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [error, setError] = useState('');

  const validationStateSchema: LoginValidationStateSchema = {
    newPassword: {
      required: true,
      error: t('resetPassword.error.requiredField'),
    },
    confirmPassword: {
      required: true,
      error: t('resetPassword.error.requiredField'),
    },
  };

  useEffect(() => {
    if (tokenUrl) {
      sessionService
        .checkResetPasswordToken(orgCode, tokenUrl)
        .catch((error: any) => {
          ErrorHelper.warn(error);
          redirectLogin();
        });
    } else {
      redirectLogin();
    }
  }, []);

  const validateState = () => {
    let hasErrorInState = false;
    const errors: Record<string, string> = {};
    for (const key in validationStateSchema) {
      const isInputFieldRequired: boolean =
        validationStateSchema[key as keyof LoginValidationStateSchema].required;

      if (isInputFieldRequired && !state[key as keyof LoginStateSchema]) {
        hasErrorInState = true;
        errors[key] =
          validationStateSchema[key as keyof LoginValidationStateSchema]
            .error || '';
      }
    }
    setErrors(errors);
    return !hasErrorInState;
  };

  const handleOnChange = (name: string, value: any) => {
    if ((state[name as keyof any] || '') !== value) {
      setState({ ...state, [name]: value });
    }
    setError('');
    setErrors('');
  };

  const redirectLogin = () => {
    LogoutHelper.logout(orgCode);
  };

  const redirectSuccess = () => {
    history.push({
      pathname: `/${orgCode}/reset-password-success`,
      state: {
        title: t('resetPassword.title'),
        success: t('resetPassword.success.successfulReset'),
      },
    });
  };

  const resetPassword = (newPassword: string) => {
    loadingScreenContext.setLoading(true);
    if (tokenUrl) {
      sessionService
        .resetPassword(orgCode, tokenUrl, newPassword)
        .then((response: AxiosResponse) => {
          loadingScreenContext.setLoading(false);
          if (response.status === 200) {
            redirectSuccess();
          } else {
            setError(response.data.message);
          }
        });
      /*.catch((error: any) => {
          console.warn('Something went wrong:', error);
        });*/
    }
  };

  const handleOnSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (validateState()) {
      if (state.newPassword !== state.confirmPassword) {
        setError(t('resetPassword.error.passwordsNotMatch'));
        setErrors({
          newPassword: '',
          confirmPassword: '',
        });
      } else {
        setError('');
        resetPassword(state.newPassword);
      }
    }
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Page
        className={classes.root}
        title={`GlobalCare Manager - ${config.env} - Reset your password`}>
        <Container maxWidth="md">
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <Avatar className={classes.icon}>
                <LockIcon fontSize="large" />
              </Avatar>
              <Typography variant="h2" color="textPrimary">
                {t('resetPassword.title')}
              </Typography>
              <br />
              <Typography variant="subtitle1" color="textSecondary">
                {t('resetPassword.subtitle')}
              </Typography>
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <PasswordTextField
                      required
                      variant="outlined"
                      fullWidth
                      name="newPassword"
                      label={t('resetPassword.fields.newPassword')}
                      type="password"
                      id="newPassword"
                      onChange={(e) => {
                        handleOnChange('newPassword', e.target.value);
                      }}
                      error={errors.newPassword !== undefined}
                      helperText={errors.newPassword}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordTextField
                      required
                      variant="outlined"
                      fullWidth
                      name="confirmPassword"
                      label={t('resetPassword.fields.confirmPassword')}
                      type="password"
                      id="confirmPassword"
                      onChange={(e) => {
                        handleOnChange('confirmPassword', e.target.value);
                      }}
                      error={errors.confirmPassword !== undefined}
                      helperText={errors.confirmPassword}
                    />
                  </Grid>
                  {error && (
                    <FormHelperText error className={classes.errorMessage}>
                      {error}
                    </FormHelperText>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleOnSubmit}>
                      {t('resetPassword.buttons.reset')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <CardMedia
              className={classes.media}
              image="/static/images/login.svg"
              title="GlobalCare Manager"
            />
          </Card>
          <Box mt={1}>
            <Copyright />
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default ResetPassword;
