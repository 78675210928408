import React from 'react';
import { CircularProgress, LinearProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {},
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingSmall: {
    '& > div': {
      width: '15px !important',
      height: '15px !important',
      marginTop: '12px',
    },
  },
}));

interface PropTypesInterface {
  children: React.ReactNode;
  loading: boolean;
  display?: string;
  className?: string;
  type?: 'linear' | 'circle' | 'hidden';
  size?: 'small' | 'normal';
  style?: any;
}

const LoadingComponent: React.FC<PropTypesInterface> = ({
  children,
  loading,
  display,
  className,
  type,
  size = 'normal',
  style,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        spacing={0}
        className={clsx(classes.root, className)}
        style={style}>
        {loading ? (
          <Grid
            item
            xs
            style={{
              display: 'flex',
            }}
            className={clsx(classes.loading, {
              [classes.loadingSmall]: size === 'small',
            })}>
            {(() => {
              switch (type) {
                case 'hidden':
                  return <></>;
                case 'linear':
                  return <LinearProgress />;
                default: {
                  return <CircularProgress />;
                }
              }
            })()}
          </Grid>
        ) : (
          <Grid
            item
            xs
            style={{
              display: display ? display : 'block',
            }}>
            {children}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default LoadingComponent;
