import React, { useContext, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Container,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { match, RouteProps } from 'react-router';
import { APISessionService } from 'src/api/api-gc/service';
import { history } from 'src/App';
import Page from 'src/components/Page';
import { LoadingScreenContext } from 'src/context/LoadingScreenContext';
import config from 'src/config';
import { TextFieldComponent } from 'src/components/UI';
import Copyright from 'src/views/login/Copyright';

const sessionService = new APISessionService();

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  errorMessage: {
    width: '100%',
    textAlign: 'center',
    marginTop: '24px',
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    objectFit: 'contain',
    backgroundSize: 'contain',
  },

  form: {},
  submit: {},
  error: {
    color: 'red',
    fontSize: '13px',
  },
}));

const RequestResetPassword: React.FC<
  {
    computedMatch: match<{ orgCode: string }>;
  } & RouteProps
> = (props) => {
  const orgCode = useMemo(() => {
    return props.computedMatch.params.orgCode;
  }, [props]);

  const loadingScreenContext = useContext(LoadingScreenContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, setState] = useState<any>({});
  const [error, setError] = useState('');
  const [errors, setErrors] = useState<any>({});

  const validateState = () => {
    let hasErrorInState = false;
    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (!regex.test(state.userEmail)) {
      hasErrorInState = true;
      setErrors({ userEmail: t('requestResetPassword.error.emailNotValid') });
    }
    return !hasErrorInState;
  };

  const handleOnChange = (name: string, value: any) => {
    if ((state[name as keyof any] || '') !== value) {
      setState({ ...state, [name]: value });
    }
    setError('');
    setErrors('');
  };

  const redirect = (userEmail: any, userName: any) => {
    history.push({
      pathname: `/${orgCode}/request-reset-password-sent`,
      state: { userEmail: userEmail, userName: userName },
    });
  };

  const requestResetPassword = (userEmail: string, userName: string) => {
    loadingScreenContext.setLoading(true);
    sessionService
      .requestUserPwdReset(orgCode, userEmail, userName)
      .then((response: AxiosResponse) => {
        loadingScreenContext.setLoading(false);
        if (response.status === 200) {
          redirect(userEmail, userName);
        } else {
          setError(response.data.message);
        }
      });
    /*.catch((error: any) => {
        console.warn('Something went wrong:', error);
      });*/
  };

  const handleOnSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (
      state.userEmail ||
      (state.userName && state.userName.trim().length != 0)
    ) {
      setError('');
      if (state.userEmail) {
        if (validateState()) {
          setErrors('');
          requestResetPassword(state.userEmail, state.userName);
        }
      } else {
        setErrors('');
        requestResetPassword(state.userEmail, state.userName);
      }
    } else {
      setError(t('requestResetPassword.error.userEmailOneOfTwoFieldsRequired'));
      setErrors({
        userName: '',
        userEmail: '',
      });
    }
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Page
        className={classes.root}
        title={`GlobalCare Manager - ${config.env} - Request to reset password`}>
        <Container maxWidth="md">
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <Avatar className={classes.icon}>
                <LockIcon fontSize="large" />
              </Avatar>
              <Typography variant="h2" color="textPrimary">
                {t('requestResetPassword.title')}
              </Typography>
              <br />
              <Typography variant="subtitle1" color="textSecondary">
                {t('requestResetPassword.subtitle')}
              </Typography>
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      required
                      fullWidth
                      name="userEmail"
                      label={t('requestResetPassword.fields.userEmail')}
                      onChange={(e) => {
                        handleOnChange('userEmail', e.target.value);
                      }}
                      error={errors.userEmail !== undefined}
                      helperText={errors.userEmail}
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="textPrimary">
                      {t('requestResetPassword.or')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      required
                      fullWidth
                      name="userName"
                      label={t('requestResetPassword.fields.userName')}
                      onChange={(e) => {
                        handleOnChange('userName', e.target.value);
                      }}
                      error={errors.userName !== undefined}
                      helperText={errors.userName}
                    />
                  </Grid>
                  {error && (
                    <FormHelperText error className={classes.errorMessage}>
                      {error}
                    </FormHelperText>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleOnSubmit}>
                      {t('requestResetPassword.buttons.send')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <CardMedia
              className={classes.media}
              image="/static/images/login.svg"
              title="GlobalCare Manager"
            />
          </Card>
          <Box mt={1}>
            <Copyright />
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default RequestResetPassword;
