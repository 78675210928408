import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { RenderTree } from 'src/interfaces';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: '34px',
  },
}));

const TreeView = ({
  items,
  onChange,
  selectedValues,
  onLoad,
  multipleSelection,
}: {
  items: RenderTree[];
  onChange?: (values: RenderTree[]) => void;
  selectedValues?: RenderTree[];
  onLoad?: () => void;
  multipleSelection?: boolean;
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(selectedValues || []);

  useEffect(() => {
    if (onLoad) {
      onLoad();
    }
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setSelected(selectedValues || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  const TreeItem = ({ value, path }: { value: RenderTree; path: string }) => {
    const [open, setOpen] = useState<boolean>(!!value.isOpened);
    const [node, setNode] = useState<RenderTree>(value);

    useEffect(() => {
      setOpen(value.isOpened || false);
      setNode(value);
    }, [value]);

    const handleClick = () => {
      setOpen(!open);
      node.isOpened = !open;
      setNode((oldNode) => {
        oldNode.isOpened = !open;
        return oldNode;
      });
    };

    const getTreeChanges = (checked: boolean, node: RenderTree) => {
      const clone: RenderTree = {
        id: node.id,
        type: node.type,
        label: node.label,
        code: node.code,
      };

      const array = checked
        ? multipleSelection
          ? [...selected, clone]
          : [clone]
        : selected.filter(
            (value) => value.id !== clone.id || value.type !== clone.type
          );

      setSelected(array);
    };

    if (!node) {
      return null;
    }

    if (node.children && node.children.length > 0) {
      return (
        <>
          <ListItem
          //button
          //onClick={(e) => handleSelect(e, node.id)}
          //selected={selectedValue === item.id}
          >
            <ListItemIcon className={classes.listItemIcon}>
              {open ? (
                <IconButton onClick={handleClick} size="small">
                  <ExpandMore />
                </IconButton>
              ) : (
                <IconButton onClick={handleClick} size="small">
                  <ChevronRight />
                </IconButton>
              )}
            </ListItemIcon>
            <Checkbox
              disabled={node.disabled}
              checked={selected.some(
                (item) => item.id === node.id && item.type === node.type
              )}
              onChange={(event) =>
                getTreeChanges(event.currentTarget.checked, node)
              }
              onClick={(e) => e.stopPropagation()}
            />
            <ListItemText
              primary={node.label}
              title={`${path} ${node.label}`}
            />
          </ListItem>
          <Collapse
            style={{ paddingLeft: '15px' }}
            in={open}
            timeout="auto"
            unmountOnExit
          >
            {node.children.map((child: any) => (
              <TreeItem
                value={child}
                path={`${path} ${node.label} /`}
                key={child.id}
              />
            ))}
          </Collapse>
        </>
      );
    }

    return (
      <ListItem
      //button
      //onClick={(e) => handleSelect(e, node.id)}
      //selected={selectedValue === item.id}
      >
        <ListItemIcon className={classes.listItemIcon} />
        <Checkbox
          disabled={node.disabled}
          checked={selected.some(
            (item) => item.id === node.id && item.type === node.type
          )}
          onChange={(event) =>
            getTreeChanges(event.currentTarget.checked, node)
          }
          onClick={(e) => e.stopPropagation()}
        />
        <ListItemText primary={node.label} title={`${path} ${node.label}`} />
      </ListItem>
    );
  };

  return (
    <List>
      {items
        ? (items || []).map((item) => (
            <TreeItem value={item} path="" key={item.id} />
          ))
        : null}
    </List>
  );
};

export default TreeView;
