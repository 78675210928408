import { ListValue, TranslationLine } from 'src/api/api-gc/model';

const TranslationHelper = {
  getTranslation: function (
    lang: string,
    strDefault?: string,
    translations?: { [key: string]: TranslationLine }
  ) {
    if (
      translations &&
      translations?.[lang] &&
      translations?.[lang].enable &&
      translations[lang].transText
    ) {
      return translations[lang].transText || '';
    } else {
      return strDefault || '';
    }
  },
  getTranslationByListValue: function (
    lang: string,
    strDefault?: string,
    listValue?: ListValue
  ) {
    if (
      listValue &&
      listValue.translations &&
      listValue.translations[lang] &&
      listValue.translations[lang].enable &&
      listValue.translations[lang].transText
    ) {
      return listValue.translations[lang].transText || '';
    } else {
      return strDefault || '';
    }
  },
  getTranslationByListValue2: function (lang: string, listValue?: ListValue) {
    if (
      listValue &&
      listValue.translations &&
      listValue.translations[lang] &&
      listValue.translations[lang].enable &&
      listValue.translations[lang].transText
    ) {
      return listValue.translations[lang].transText || '';
    } else {
      return (listValue || {}).valueText || '';
    }
  },
  getTranslationInListValues: function (
    lang: string,
    valueCode: string,
    listValues: ListValue[],
    strDefault?: string
  ) {
    for (const v of listValues) {
      if (v.valueCode === valueCode) {
        return this.getTranslationByListValue(lang, v.valueText, v);
      }
    }

    return strDefault || '';
  },
};

export default TranslationHelper;
