import React, { useEffect, useMemo, useRef } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import ReactDOM from 'react-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { APISessionService } from 'src/api/api-gc/service';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';
import Copyright from './Copyright';
import { ListValue } from 'src/api/api-gc';
import { TranslationHelper } from 'src/helpers';
import { ListHelper } from 'src/helpers';
import { useLocation } from 'react-router-dom';
import config from 'src/config';

const sessionService = new APISessionService();

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
    paddingTop: 25,
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    objectFit: 'contain',
    backgroundSize: 'contain',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  submit: {
    '& span': {
      textTransform: 'none',
    },
    display: 'block',
  },
  buttonIcon: {
    maxHeight: '45px',
    float: 'left',
  },
  buttonLabel: {
    height: '45px',
    display: 'block',
    lineHeight: '45px',
  },
}));

const OAuth: React.FC<{
  orgCode: string;
  ssoConfigurations: ListValue[];
}> = ({ orgCode, ssoConfigurations }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const container = useRef<HTMLDivElement>(null);
  const useSearchParams = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const searchParams = useSearchParams();

  useEffect(() => {
    const fixHeight = () => {
      if (container && container.current) {
        // eslint-disable-next-line react/no-find-dom-node
        const element = ReactDOM.findDOMNode(container.current) as HTMLElement;
        if (element) {
          const offsetTop = element.offsetTop;

          element.style.height = window.innerHeight - offsetTop - 125 + 'px';
        }
      }
    };

    fixHeight();

    window.addEventListener('resize', fixHeight);

    return () => {
      window.removeEventListener('resize', fixHeight);
    };
  }, []);

  const handleOnSubmit = (identityProvider: string) => {
    sessionService
      .getOAuthLoginUrl(orgCode, {
        entityCode: orgCode,
        //profileName: 'MANAGER_ADMIN',
        redirectUrlCode: config.sso.urlsCode.success,
        redirectErrorUrlCode: config.sso.urlsCode.fail,
        identityProvider: identityProvider,
      })
      .then(({ data: { url } }) => {
        const currentPath = searchParams.get('currentPath');
        if (currentPath) {
          sessionStorage.setItem('currentPath', currentPath);
        }
        document.location.href = url as string;
      });
  };

  return (
    <Container maxWidth="md">
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Avatar className={classes.icon}>
            <LockIcon fontSize="large" />
          </Avatar>
          <Typography variant="h2" color="textPrimary">
            {t('login.title')}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {t('login.subtitle')}
          </Typography>
          <Box mt={3}>
            <Grid container spacing={3}>
              {/*<Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleOnSubmit}
                  disabled={disable}>
                  {t('login.signIn')}
                </Button>
              </Grid>*/}
              {(ssoConfigurations || []).map((conf, index) => (
                <Grid item xs={12} key={index}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                      handleOnSubmit(conf.valueCode as string);
                    }}
                    style={{
                      backgroundColor: ListHelper.getParameterValue(
                        conf,
                        'BUTTON_COLOR_CODE'
                      ),
                    }}
                  >
                    <img
                      alt="Service icon"
                      className={classes.buttonIcon}
                      src={ListHelper.getParameterValue(conf, 'URL_LOGO')}
                    />
                    <span className={classes.buttonLabel}>
                      {TranslationHelper.getTranslation(
                        i18n.language,
                        conf.valueText,
                        conf.translations
                      )}
                    </span>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
        <CardMedia
          className={classes.media}
          image="/static/images/login.svg"
          title="GlobalCare Manager"
        />
      </Card>
      <Box mt={1}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default OAuth;
