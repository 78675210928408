import { ColumnType, GridType, ValidationRulesType } from 'src/interfaces';
import {
  DisplayedColumnInterface,
  DisplayedTableInterface,
} from 'src/api/api-preferences/model/DisplayedTableInterface';
import { Column } from '@syncfusion/ej2-react-grids';
import i18next from 'i18next';

const defaultStringColumn: ColumnType = {
  field: '',
  headerText: '',
  isIdentity: true,
  isVisible: false,
  //width: 230,
  editable: false,
  filterable: true,
  groupable: true,
  reorderable: true,
  resizable: true,
  searchable: false,
  sortable: true,
  displayAsCheckbox: false,
  type: 'string',
  editType: 'stringedit',
  textAlign: 'Left',
  validationRules: { required: false },
};

const defaultBooleanColumn: ColumnType = {
  field: '',
  headerText: '',
  defaultValue: 'false',
  isVisible: false,
  //width: 50,
  editable: false,
  filterable: true,
  groupable: true,
  reorderable: true,
  resizable: true,
  searchable: false,
  sortable: true,
  displayAsCheckbox: true,
  type: 'boolean',
  editType: 'booleanedit',
  textAlign: 'Center',
  validationRules: { required: false },
};

const defaultNumberColumn: ColumnType = {
  field: '',
  headerText: '',
  isVisible: false,
  //width: 50,
  editable: false,
  filterable: true,
  groupable: true,
  reorderable: true,
  resizable: true,
  searchable: false,
  sortable: true,
  displayAsCheckbox: false,
  type: 'number',
  editType: 'stringedit',
  textAlign: 'Right',
  validationRules: { required: false },
  defaultValue: '0',
};

const defaultSwitchColumn: ColumnType = {
  field: '',
  headerText: '',
  defaultValue: 'false',
  isVisible: false,
  //width: 50,
  editable: true,
  filterable: true,
  groupable: true,
  reorderable: true,
  resizable: true,
  searchable: true,
  sortable: true,
  displayAsCheckbox: true,
  type: 'switch',
  editType: 'booleanedit',
  textAlign: 'Center',
  validationRules: { required: false },
};

const GridHelper = {
  getColumn: (value: DisplayedColumnInterface) => {
    let column = {} as ColumnType;
    if (value.type === 'switch') {
      column = JSON.parse(JSON.stringify(defaultSwitchColumn));
    } else if (value.type === 'number') {
      column = JSON.parse(JSON.stringify(defaultNumberColumn));
    } else if (value.type === 'boolean') {
      column = JSON.parse(JSON.stringify(defaultBooleanColumn));
    } else {
      column = JSON.parse(JSON.stringify(defaultStringColumn));
    }

    column.field = value.value;
    column.headerText =
      value.objectType === 'LIST_ITEM' ? i18next.t(value.name) : value.name;
    column.searchable = value.index!;
    column.isVisible = value.display!;
    column.sortComparer = value.sortComparer;

    return column;
  },

  convertColumnToColumnType: (value: Column) => {
    const columnType: ColumnType = {
      defaultValue: value.defaultValue,
      displayAsCheckbox: value.displayAsCheckBox,
      editType: value.editType,
      editable: value.allowEditing,
      field: value.field,
      filterable: value.allowFiltering,
      groupable: value.allowGrouping,
      headerText: value.headerText,
      isIdentity: value.isIdentity,
      isVisible: value.visible,
      reorderable: value.allowReordering,
      resizable: value.allowResizing,
      searchable: value.allowSearching,
      showInColumnChooser: value.showInColumnChooser,
      sortable: value.allowSorting,
      textAlign: value.textAlign,
      tooltip: value.toolTip,
      type: value.type,
      validationRules: value.validationRules as ValidationRulesType,
      //width: value.width
    };

    return columnType;
  },

  convertGridSchemaToDisplayedTable: (grid: GridType) => {
    return {
      code: grid.gridId,
      columns: grid.columns
        //.filter((item) => item.field !== 'valueId')
        .map((value) => {
          const column: DisplayedColumnInterface = {
            display: value.isVisible,
            index: value.searchable,
            isGroup: grid.groupSettings.columns!.includes(value.field),
            name: value.headerText!,
            objectType: '', //value.field.startsWith("@Param::") ? "PARAMETER" : (value.field.startsWith("@Trans::") ? "TRANSLATION" : "LIST_ITEM"),
            type: value.type,
            value: value.field,
          };

          return column;
        }),
    } as DisplayedTableInterface;
  },
};

export default GridHelper;
