export function getToken(): string {
  return sessionStorage.getItem('token') as string;
}

export function setToken(token: string) {
  sessionStorage.setItem('token', token);
}

export function removeToken() {
  sessionStorage.removeItem('token');
}
