import React, { useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { ButtonComponent } from 'src/components/UI/Button/ButtonComponent';
import { FormWidget } from 'src/components/Form/FormWidget';
import { FormFieldType, ValidationStateSchema } from 'src/interfaces';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  yesnoButtons: {
    display: 'block',
  },
}));

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  variant?: 'danger' | 'info' | 'form' | 'yesno';
  title?: string;
  description?: string;
  fields?: FormFieldType[];
  validationStateSchema?: ValidationStateSchema;
  formData?: Record<string, any>;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: (value: any) => void;
  onClose: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  fields,
  validationStateSchema,
  formData,
  children,
}) => {
  const classes = useStyles();
  const formRef = useRef<FormWidget>(null);
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {variant === 'form' ? (
          <>
            <FormWidget
              withDescription={!!description}
              ref={formRef}
              validationStateSchema={validationStateSchema || {}}
              fields={fields || []}
              formData={formData}
            />
          </>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions
        className={clsx({ [classes.yesnoButtons]: variant === 'yesno' })}
      >
        {variant === 'danger' && (
          <>
            <ButtonComponent
              label={t('modals.confirmation.buttons.cancel')}
              onClick={onClose}
              autoFocus
            />
            <ButtonComponent
              label={t('modals.confirmation.buttons.confirm')}
              color="primary"
              onClick={onSubmit}
            />
          </>
        )}

        {variant === 'info' && (
          <ButtonComponent
            label={t('modals.confirmation.buttons.ok')}
            color="primary"
            onClick={onSubmit}
          />
        )}

        {variant === 'form' && (
          <>
            <ButtonComponent
              label={t('modals.confirmation.buttons.cancel')}
              onClick={onClose}
              autoFocus
            />
            <ButtonComponent
              label={t('modals.confirmation.buttons.confirm')}
              color="primary"
              onClick={() => {
                if (formRef.current!.validateState()) {
                  onSubmit(formRef.current!.getFormData());
                }
              }}
            />
          </>
        )}

        {variant === 'yesno' && (
          <>
            <ButtonComponent
              label={t('button.cancel')}
              style={{ float: 'left' }}
              onClick={onClose}
              autoFocus
            />
            <ButtonComponent
              label={t('button.yes')}
              color="primary"
              style={{ float: 'right' }}
              onClick={() => onSubmit(true)}
            />
            <ButtonComponent
              label={t('button.no')}
              color="primary"
              style={{ float: 'right' }}
              onClick={() => onSubmit(false)}
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
