import React, { useContext } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { User } from 'src/api/api-keycloak/model';
import PasswordTextField from 'src/components/UI/PasswordTextField';
import Details from 'src/components/Form/Details';
import { DetailContext, UPDATE_DESCRIPTION } from '../context';
import { SwitchComponent, TextFieldComponent } from 'src/components/UI';
import { ValidationStateSchema } from 'src/interfaces';
import { hasAttributes } from '../UserForm';
import ValidationStateSchemaHelper from 'src/helpers/ValidationStateSchemaHelper';

interface Props {
  user: User;
  validationStateSchema: ValidationStateSchema;
  invitationMode?: boolean;
}

const Description = ({
  user,
  validationStateSchema,
  invitationMode,
}: Props) => {
  const { t, i18n } = useTranslation();
  const translate = (key: string, context?: Record<string, string>) =>
    t(`keycloak.users.form.description.fields.${key}`, context);
  const { dispatch, data, errors } = useContext(DetailContext);

  const formData: User = { ...user, ...data };

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} md={10}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" color="textPrimary">
              {translate('informations')}
            </Typography>
            <Box mt={1}>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldComponent
              disabled={hasAttributes(user)}
              name="userLastName"
              label={translate('lastname')}
              error={!!errors.lastName}
              helperText={errors.lastName}
              defaultValue={formData.lastName}
              onBlur={(e) =>
                dispatch({
                  payload: {
                    field: 'lastName',
                    value: e.target.value,
                  },
                  type: UPDATE_DESCRIPTION,
                })
              }
              fullWidth
              required={
                validationStateSchema.lastName
                  ? validationStateSchema.lastName.required
                  : false
              }
              inputProps={{
                maxLength: ValidationStateSchemaHelper.maxLength(
                  validationStateSchema,
                  'lastName'
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldComponent
              disabled={hasAttributes(user)}
              name="firstName"
              label={translate('firstname')}
              error={!!errors.firstName}
              helperText={errors.firstName}
              defaultValue={formData.firstName}
              onBlur={(e) =>
                dispatch({
                  payload: {
                    field: 'firstName',
                    value: e.target.value,
                  },
                  type: UPDATE_DESCRIPTION,
                })
              }
              fullWidth
              required={
                validationStateSchema.firstName
                  ? validationStateSchema.firstName.required
                  : false
              }
              inputProps={{
                maxLength: ValidationStateSchemaHelper.maxLength(
                  validationStateSchema,
                  'firstName'
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={!invitationMode ? 6 : 12}>
            <TextFieldComponent
              disabled={hasAttributes(user)}
              name="email"
              label={translate('email')}
              error={!!errors.email}
              helperText={errors.email}
              defaultValue={formData.email}
              onBlur={(e) =>
                dispatch({
                  payload: {
                    field: 'email',
                    value: e.target.value,
                  },
                  type: UPDATE_DESCRIPTION,
                })
              }
              fullWidth
              required={
                validationStateSchema.email
                  ? validationStateSchema.email.required
                  : false
              }
              inputProps={{
                maxLength: ValidationStateSchemaHelper.maxLength(
                  validationStateSchema,
                  'email'
                ),
              }}
            />
          </Grid>
          {!invitationMode && (
            <Grid item xs={12} sm={6}>
              <SwitchComponent
                disabled={hasAttributes(user)}
                name="enable"
                label={translate('enable')}
                checked={formData.enabled}
                onChange={(e) =>
                  dispatch({
                    payload: {
                      field: 'enabled',
                      value: e.target.checked,
                    },
                    type: UPDATE_DESCRIPTION,
                  })
                }
              />
            </Grid>
          )}
          {!hasAttributes(user) ? (
            <>
              {' '}
              <Grid item xs={12}>
                <Typography variant="h4" color="textPrimary">
                  {translate('connection')}
                </Typography>
                <Box mt={1}>
                  <Divider />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextFieldComponent
                  disabled={!!user.id}
                  name="username"
                  label={translate('username')}
                  error={!!errors.username}
                  helperText={errors.username}
                  defaultValue={formData.username}
                  onBlur={(e) =>
                    dispatch({
                      payload: {
                        field: 'username',
                        value: e.target.value,
                      },
                      type: UPDATE_DESCRIPTION,
                    })
                  }
                  fullWidth
                  required={
                    validationStateSchema.username
                      ? validationStateSchema.username.required
                      : false
                  }
                  inputProps={{
                    maxLength: ValidationStateSchemaHelper.maxLength(
                      validationStateSchema,
                      'username'
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PasswordTextField
                  name="userPwd"
                  label={translate('password')}
                  type="password"
                  fullWidth
                  /*inputProps={{
                  minLength: 6,
                }}*/
                  error={!!errors.userPwd}
                  helperText={errors.userPwd}
                  defaultValue={formData.userPwd}
                  onBlur={(e) =>
                    dispatch({
                      payload: {
                        field: 'userPwd',
                        value: e.target.value,
                      },
                      type: UPDATE_DESCRIPTION,
                    })
                  }
                  required={
                    validationStateSchema.userPwd
                      ? validationStateSchema.userPwd.required
                      : false
                  }
                  inputProps={{
                    maxLength: ValidationStateSchemaHelper.maxLength(
                      validationStateSchema,
                      'userPwd'
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PasswordTextField
                  name="userPwdRepeat"
                  label={translate('confirmPassword')}
                  type="password"
                  fullWidth
                  error={!!errors.userPwdRepeat}
                  helperText={errors.userPwdRepeat}
                  defaultValue={formData.userPwdRepeat}
                  onBlur={(e) =>
                    dispatch({
                      payload: {
                        field: 'userPwdRepeat',
                        value: e.target.value,
                      },
                      type: UPDATE_DESCRIPTION,
                    })
                  }
                  required={
                    validationStateSchema.userPwdRepeat
                      ? validationStateSchema.userPwdRepeat.required
                      : false
                  }
                  inputProps={{
                    maxLength: ValidationStateSchemaHelper.maxLength(
                      validationStateSchema,
                      'userPwdRepeat'
                    ),
                  }}
                />
              </Grid>
              {!invitationMode && (
                <Grid item xs={12} sm={6}>
                  <SwitchComponent
                    name="renewPwd"
                    label={translate('renewPwd')}
                    checked={formData.renewPwd}
                    onChange={(e) =>
                      dispatch({
                        payload: {
                          field: 'renewPwd',
                          value: e.target.checked,
                        },
                        type: UPDATE_DESCRIPTION,
                      })
                    }
                  />
                </Grid>
              )}
            </>
          ) : null}

          {user.id ? (
            <Grid item xs={12}>
              <Details
                id={user.id}
                createDate={
                  user.createdTimestamp
                    ? new Date(user.createdTimestamp)
                    : undefined
                }
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Description;
