import React, { createContext, Dispatch, useReducer } from 'react';
import { Prompt } from 'react-router-dom';
import { LeaveWarningDialog } from 'src/components/LeaveWarningDialog/LeaveWarningDialog';
import { history } from 'src/App';
import 'setimmediate';

export const RESET_ROUTE_LEAVING_GUARD = 'RESET_ROUTE_LEAVING_GUARD';
export const UPDATE_ROUTE_LEAVING_GUARD = 'UPDATE_ROUTE_LEAVING_GUARD';
export const UPDATE_NEXT_LOCATION_PATHNAME = 'UPDATE_NEXT_LOCATION_PATHNAME';

interface ActionInterface {
  payload?: any;
  type: string;
}

export interface RouteContextInterface {
  dispatch: Dispatch<ActionInterface>;
  enableLeaveWarning: boolean;
  nextLocationPathname?: string;
  onClickSave?: () => void;
}

const defaultContext: RouteContextInterface = {
  dispatch: () => null,
  enableLeaveWarning: false,
};

export const RouteLeavingGuardContext = createContext(defaultContext);

const reducer = (
  state: RouteContextInterface,
  { payload, type }: ActionInterface
) => {
  switch (type) {
    case RESET_ROUTE_LEAVING_GUARD: {
      return {
        ...state,
        enableLeaveWarning: false,
        onClickSave: undefined,
        nextLocationPathname: undefined,
      };
    }
    case UPDATE_ROUTE_LEAVING_GUARD: {
      return {
        ...state,
        enableLeaveWarning: payload.enable,
        onClickSave: payload.onClickSave,
      };
    }
    case UPDATE_NEXT_LOCATION_PATHNAME: {
      return {
        ...state,
        nextLocationPathname: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const RouteLeavingGuardProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultContext);

  const handleBlockedNavigation = (nextLocation: any, action: any) => {
    if (nextLocation && nextLocation.pathname && state.enableLeaveWarning) {
      dispatch({
        type: UPDATE_NEXT_LOCATION_PATHNAME,
        payload: nextLocation.pathname,
      });
      return false;
    }

    dispatch({ type: RESET_ROUTE_LEAVING_GUARD });
    return true;
  };

  const confirmNavigation = () => {
    const nextLocationPathname = state.nextLocationPathname;
    if (nextLocationPathname) {
      dispatch({ type: RESET_ROUTE_LEAVING_GUARD });

      // Since state update is async, we need the showPrompt state to be
      // updated first in order to show the prompt
      setImmediate(() => history.push(nextLocationPathname));
    }
  };

  const handleSaveAndContinue = () => {
    if (state.onClickSave) {
      state.onClickSave();
    }
    confirmNavigation();
  };

  return (
    <RouteLeavingGuardContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
      <Prompt
        when={state.enableLeaveWarning} // <-- blocking condition
        message={handleBlockedNavigation}
      />
      <LeaveWarningDialog
        nextLocationPathname={state.nextLocationPathname}
        onCancel={() =>
          dispatch({ type: UPDATE_NEXT_LOCATION_PATHNAME, payload: undefined })
        }
        onSaveAndContinue={
          state.onClickSave ? handleSaveAndContinue : undefined
        }
        onContinueWithoutSaving={confirmNavigation}
      />
    </RouteLeavingGuardContext.Provider>
  );
};
