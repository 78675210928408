import React from 'react';
import { Redirect } from 'react-router-dom';
import { getToken } from 'src/utils/token';
import config from 'src/config';

const getRedirectionPath = () => {
  if (config.app.loginMode) {
    switch (config.app.loginMode) {
      case 'OAuth':
        return 'oauth';
      default:
        return 'login';
    }
  } else {
    return 'login';
  }
};

function AuthRoute({ component: Component, render, ...rest }) {
  if (!getToken()) {
    const currentPath = window.location.href.split(
      `/${rest.computedMatch.params.orgCode}/`
    )[1];
    if (currentPath) {
      return (
        <Redirect
          to={`/${
            rest.computedMatch.params.orgCode
          }/${getRedirectionPath()}?currentPath=${encodeURIComponent(
            String(currentPath)
          )}`}
        />
      );
    } else {
      return (
        <Redirect
          to={
            '/' + rest.computedMatch.params.orgCode + `/${getRedirectionPath()}`
          }
        />
      );
    }
  }

  return render ? render({ ...rest }) : <Component {...rest} />;
}

export default AuthRoute;
