import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

export interface OptionType {
  key: string | number;
  label: string;
}

interface DatePickerInterface {
  format?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  value?: any;
  onChange?: (value: Date | null) => void;
  error?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  required?: boolean;
  name?: string;
  //validationSchema?: ValidationStateSchema;
  //renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  openTo?: any;
  view?: readonly any[];
  disableOpenPicker?: boolean;
}

export const DatePickerComponent: React.FC<
  DatePickerInterface & React.RefAttributes<HTMLDivElement>
> = ({
  label,
  value,
  onChange,
  format,
  openTo,
  className,
  error,
  helperText,
  disabled,
  size,
  required,
  name,
  //validationSchema,
  view,
  disableOpenPicker,
}) => {
  const { t } = useTranslation();
  const [v, setV] = useState<Date | null>(null);

  useEffect(() => {
    setV(value ? new Date(value) : null);
  }, [value]);

  return (
    <DatePicker
      //clearable
      format={format ?? t('main.date.format.date')}
      // cancelLabel={t('main.date.buttons.cancel')}
      // clearLabel={t('main.date.buttons.clear')}
      // okLabel={t('main.date.buttons.ok')}
      label={label}
      disableOpenPicker={disableOpenPicker}
      value={v}
      openTo={openTo}
      views={view}
      onChange={(data: Date | null) => {
        if (onChange) {
          setV(data);
          onChange(data);
        }
      }}
      /*renderInput={(params: any) => (
        <TextFieldComponent
          fullWidth
          {...(params as StandardTextFieldProps)}
          {...{
            className,
            error,
            helperText,
            disabled,
            size,
            required,
            name,
            validationSchema,
          }}
        />
      )}*/
      slotProps={{
        textField: {
          ...{
            className,
            error,
            helperText,
            disabled,
            size,
            required,
            name,
            //validationSchema,
          },
          fullWidth: true,
        },
      }}
    />
  );
};
