import React, { useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { match, RouteProps } from 'react-router';
import { GetOrganizationInfoResponse, ListObject } from 'src/api/api-gc';
import { APISessionService } from 'src/api/api-gc/service';
import { history } from 'src/App';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import config from 'src/config';
import LoadingComponent from 'src/components/LoadingComponent';
import LoginClassic from './LoginClassic';
import OAuth from './OAuth';
import { useLocation } from 'react-router-dom';

const sessionService = new APISessionService();

const defaultListObject: ListObject = {
  values: [],
};

export const defaultOrganisationInfos: GetOrganizationInfoResponse = {
  listLang: defaultListObject,
  listLocale: defaultListObject,
  listCountry: defaultListObject,
  listSsoConfiguration: defaultListObject,
};

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
}));

const Login: React.FC<
  {
    computedMatch: match<{ orgCode: string }>;
  } & RouteProps
> = (props) => {
  const isMountedRef = useIsMountedRef();

  const orgCode = useMemo(() => {
    return props.computedMatch.params.orgCode;
  }, [props]);

  const { t } = useTranslation();
  const classes = useStyles();
  const useSearchParams = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const searchParams = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [organisationInfos, setOrganisationInfos] = useState(
    defaultOrganisationInfos
  );

  useEffect(() => {
    const mapLevel = [
      'LIST_VALUE_ENABLE',
      'TRANSLATIONS',
      'LIST_PARAM',
      'LIST_VALUE_PARAM',
    ];
    sessionService
      .getOrganizationInfo(orgCode, mapLevel)
      .then(function (response: AxiosResponse<GetOrganizationInfoResponse>) {
        if (response.data && isMountedRef.current) {
          if (
            response.data.listSsoConfiguration &&
            response.data.listSsoConfiguration.values &&
            response.data.listSsoConfiguration.values.length === 1
          ) {
            sessionService
              .getOAuthLoginUrl(orgCode, {
                entityCode: orgCode,
                //profileName: 'MANAGER_ADMIN',
                redirectUrlCode: config.sso.urlsCode.success,
                redirectErrorUrlCode: config.sso.urlsCode.fail,
                identityProvider:
                  response.data.listSsoConfiguration.values[0].valueCode,
              })
              .then(({ data: { url } }) => {
                const currentPath = searchParams.get('currentPath');
                if (currentPath) {
                  sessionStorage.setItem('currentPath', currentPath);
                }
                document.location.href = url as string;
              });
          } else {
            setOrganisationInfos(response.data);
            setLoading(false);
          }
        }
      })
      .catch((error): void => {
        console.warn(error);
        history.push('/404');
      });
  }, [orgCode, isMountedRef]);

  const getContent = () => {
    if (
      organisationInfos.listSsoConfiguration &&
      organisationInfos.listSsoConfiguration.values &&
      organisationInfos.listSsoConfiguration.values.length > 1
    ) {
      return (
        <OAuth
          orgCode={orgCode}
          ssoConfigurations={organisationInfos.listSsoConfiguration.values}
        />
      );
    } else {
      return (
        <LoginClassic orgCode={orgCode} organisationInfos={organisationInfos} />
      );
    }
  };

  return (
    <Page
      className={classes.root}
      title={`GlobalCare Manager - ${config.env} - Login`}
    >
      <LoadingComponent loading={loading}>{getContent()}</LoadingComponent>
    </Page>
  );
};

export default Login;
