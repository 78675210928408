import config from 'src/config.js';

import { Configuration } from '../configuration';

import axios, { AxiosResponse } from 'axios';
import { ConfigurableItem } from '../model/configurableItem';
import { searchConfigurableItemsResponse } from '../model/searchConfigurableItemsResponse';
import { searchConfigurableItemsTypesResponse } from '../model/searchConfigurableItemsTypesResponse';
import { ConfigurableItemsType } from '../model/configurableItemsType';
import { ConfigurableItemsTypesAttribute } from '../model/configurableItemsTypesAttribute';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APIConfigurableService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates a configurableItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createConfigurableItem(
    token: string,
    orgCode: string,
    body?: ConfigurableItem,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ConfigurableItem>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createConfigurableItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createConfigurableItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<ConfigurableItem>(
      `${this.basePath}/configurableItems/configurableItems`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  public getConfigurableItem(
    token: string,
    orgCode: string,
    itemId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ConfigurableItem>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getConfigurableItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getConfigurableItem.'
      );
    }

    if (itemId === null || itemId === undefined) {
      throw new Error(
        'Required parameter itemId was null or undefined when calling getConfigurableItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get(
      `${
        this.basePath
      }/configurableItems/configurableItems/${encodeURIComponent(
        String(itemId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    ) as Promise<AxiosResponse<ConfigurableItem>>;
  }

  public getConfigurableItems(
    token: string,
    orgCode: string,
    enable?: boolean,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    itemParentId?: number,
    itemTypeId?: number,
    itemTypeName?: string,
    itemCode?: string,
    onlyGrantedItems?: boolean
  ): Promise<AxiosResponse<searchConfigurableItemsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getConfigurableItems.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getConfigurableItems.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }
    if (itemTypeId !== undefined && itemTypeId !== null) {
      queryParameters.append('itemTypeId', itemTypeId as any);
    }
    if (itemParentId !== undefined && itemParentId !== null) {
      queryParameters.append('itemParentId', itemParentId as any);
    }
    if (itemTypeName !== undefined && itemTypeName !== null) {
      queryParameters.append('itemTypeName', itemTypeName as any);
    }

    if (itemCode !== undefined && itemCode !== null) {
      queryParameters.append('itemCode', itemCode as any);
    }

    if (onlyGrantedItems !== undefined && onlyGrantedItems !== null) {
      queryParameters.append('onlyGrantedItems', onlyGrantedItems as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get(`${this.basePath}/configurableItems/configurableItems`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
      params: queryParameters,
    }) as Promise<AxiosResponse<searchConfigurableItemsResponse>>;
  }

  /**
   * Modify a configurableItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param itemId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyConfigurableItem(
    token: string,
    orgCode: string,
    itemId: number,
    body?: ConfigurableItem,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ConfigurableItem>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyConfigurableItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyConfigurableItem.'
      );
    }

    if (itemId === null || itemId === undefined) {
      throw new Error(
        'Required parameter itemId was null or undefined when calling modifyConfigurableItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<ConfigurableItem>(
      `${
        this.basePath
      }/configurableItems/configurableItems/${encodeURIComponent(
        String(itemId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a ConfigurableItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param itemId
   */
  public removeConfigurableItem(
    token: string,
    orgCode: string,
    itemId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeConfigurableItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeConfigurableItem.'
      );
    }

    if (itemId === null || itemId === undefined) {
      throw new Error(
        'Required parameter itemId was null or undefined when calling removeConfigurableItem.'
      );
    }

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${
        this.basePath
      }/configurableItems/configurableItems/${encodeURIComponent(
        String(itemId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a menuItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param itemId
   * @param body
   * @param mapLevel
   */
  public replaceConfigurableItem(
    token: string,
    orgCode: string,
    itemId: number,
    body?: ConfigurableItem,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ConfigurableItem>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceConfigurableItem.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceConfigurableItem.'
      );
    }

    if (itemId === null || itemId === undefined) {
      throw new Error(
        'Required parameter itemId was null or undefined when calling replaceConfigurableItem.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.put<ConfigurableItem>(
      `${
        this.basePath
      }/configurableItems/configurableItems/${encodeURIComponent(
        String(itemId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  //get configurableItemsTypes

  /**
   * Remove a menuItem
   *
   * @param token Token
   * @param orgCode Organization Code
   */

  public getConfigurableItemsTypes(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<searchConfigurableItemsTypesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getConfigurableItems.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getConfigurableItems.'
      );
    }

    const queryParameters = new URLSearchParams();

    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get(
      `${this.basePath}/configurableItems/configurableItemsTypes`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
        params: queryParameters,
      }
    ) as Promise<AxiosResponse<searchConfigurableItemsTypesResponse>>;
  }

  /**
   * Remove a configurableItem
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param configurableItemTypeId

   */

  public getconfigurableItemsTypeAttributes(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    configurableItemTypeId?: number
  ): Promise<AxiosResponse<searchConfigurableItemsTypesResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getConfigurableItems.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getConfigurableItems.'
      );
    }

    const queryParameters = new URLSearchParams();

    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', element as any);
      });
    }

    if (
      configurableItemTypeId !== undefined &&
      configurableItemTypeId !== null
    ) {
      queryParameters.append('configurableItemTypeId', limitCount as any);
    }
    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get(
      `${
        this.basePath
      }/configurableItems/configurableItemsTypes/${encodeURIComponent(
        String(configurableItemTypeId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
        params: queryParameters,
      }
    ) as Promise<AxiosResponse<searchConfigurableItemsTypesResponse>>;
  }

  // Confiurable Items Types

  /**
   * Create a configurableItemType
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel

   */
  public createConfigurableItemType(
    token: string,
    orgCode: string,
    body?: ConfigurableItemsType,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<ConfigurableItemsType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createConfigurableItemType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createConfigurableItemType.'
      );
    }

    const queryParameters = new URLSearchParams();

    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.post<ConfigurableItemsType>(
      `${this.basePath}/configurableItems/configurableItemsTypes`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a configurableItemType
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param typeId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyConfigurableItemType(
    token: string,
    orgCode: string,
    typeId: number,
    body?: ConfigurableItemsType,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ConfigurableItemsType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyConfigurableItemType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyConfigurableItemType.'
      );
    }

    if (typeId === null || typeId === undefined) {
      throw new Error(
        'Required parameter typeId was null or undefined when calling modifyConfigurableItemType.'
      );
    }

    const queryParameters = new URLSearchParams();
    mapLevel?.forEach((element) => {
      queryParameters.append('mapLevel', element as any);
    });

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<ConfigurableItemsType>(
      `${
        this.basePath
      }/configurableItems/configurableItemsTypes/${encodeURIComponent(
        String(typeId)
      )}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get a configurableItemType
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param typeId
   */
  public getConfigurableItemType(
    token: string,
    orgCode: string,
    typeId: number
  ): Promise<AxiosResponse<ConfigurableItemsType>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getConfigurableItemType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getConfigurableItemType.'
      );
    }

    if (typeId === null || typeId === undefined) {
      throw new Error(
        'Required parameter typeId was null or undefined when calling getConfigurableItemType.'
      );
    }

    const queryParameters = new URLSearchParams();

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.get<ConfigurableItemsType>(
      `${
        this.basePath
      }/configurableItems/configurableItemsTypes/${encodeURIComponent(
        String(typeId)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a ConfigurableItemTypeAttribute
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param typeId
   * @param idAtt

   */
  public removeConfigurableItemTypeAttributes(
    token: string,
    orgCode: string,
    typeId: number,
    idAtt: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeConfigurableItemTypeAttribute.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeConfigurableItemTypeAttribute.'
      );
    }

    if (typeId === null || typeId === undefined) {
      throw new Error(
        'Required parameter typeId  was null or undefined when calling removeConfigurableItemTypeAttribute.'
      );
    }
    if (idAtt === null || idAtt === undefined) {
      throw new Error(
        'Required parameter idAtt  was null or undefined when calling removeConfigurableItemTypeAttribute.'
      );
    }
    const headers: { [key: string]: string } = {};
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    return axios.delete<any>(
      `${
        this.basePath
      }/configurableItems/configurableItemsTypes/${encodeURIComponent(
        String(typeId)
      )}/attributes/${encodeURIComponent(String(idAtt))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a configurableItemTypeAttributes
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param typeId
   * @param attId
   * @param body
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyConfigurableItemTypeAttributes(
    token: string,
    orgCode: string,
    typeId: number,
    attId: number,
    body?: ConfigurableItemsTypesAttribute,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<ConfigurableItemsTypesAttribute>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyConfigurableItemTypeAttributes.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyConfigurableItemTypeAttributes.'
      );
    }

    if (typeId === null || typeId === undefined) {
      throw new Error(
        'Required parameter typeId was null or undefined when calling modifyConfigurableItemTypeAttributes.'
      );
    }

    if (attId === null || attId === undefined) {
      throw new Error(
        'Required parameter attId was null or undefined when calling modifyConfigurableItemTypeAttributes.'
      );
    }
    const queryParameters = new URLSearchParams();

    const headers = {} as any;
    if (token) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = httpHeaderAcceptSelected;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = httpContentTypeSelected;
    }

    return axios.patch<ConfigurableItemsTypesAttribute>(
      `${
        this.basePath
      }/configurableItems/configurableItemsTypes/${encodeURIComponent(
        String(typeId)
      )}/attributes/${encodeURIComponent(String(attId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a ciType
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param accId
   */
  public removeConfigurableItemType(
    token: string,
    orgCode: string,
    typeId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeConfigurableItemType.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeConfigurableItemType.'
      );
    }

    if (typeId === null || typeId === undefined) {
      throw new Error(
        'Required parameter typeId was null or undefined when calling removeConfigurableItemType.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${
        this.basePath
      }/configurableItems/configurableItemsTypes/${encodeURIComponent(
        String(typeId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
