import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { match, RouteProps, useLocation } from 'react-router';
import { CardMedia, CircularProgress, Grid } from '@mui/material';
import Page from 'src/components/Page';
import ReactDOM from 'react-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ApiContext, useSettings } from 'src/context';
import { history } from 'src/App';
import { APIKeycloakService } from 'src/api/api-keycloak/apiKeycloak.service';

const keycloakService = new APIKeycloakService();

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
    paddingTop: 25,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    objectFit: 'contain',
    backgroundSize: 'contain',
    height: '100%',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const KeycloakGroupInvitationJoinView: React.FC<
  {
    computedMatch: match<{ orgCode: string }>;
  } & RouteProps
> = ({ ...rest }) => {
  const orgCode = useMemo(() => {
    return rest.computedMatch.params.orgCode;
  }, [rest]);
  const apiContext = useContext(ApiContext);
  const { saveSettings } = useSettings();

  const classes = useStyles();
  const container = useRef<HTMLDivElement>(null);

  const params = new URLSearchParams(useLocation().search);
  const token = params.get('token');

  useEffect(() => {
    if (orgCode && token) {
      keycloakService
        .groupInvitationJoin(orgCode, {
          token: token as string,
        })
        .then(() => {
          history.push(`/${orgCode}/keycloak/group-invitation/success`);
        })
        .catch(() => {
          history.push(`/${orgCode}/keycloak/group-invitation/fail`);
        });
    } else {
      history.push(`/${orgCode}/keycloak/group-invitation/fail`);
    }
  }, [orgCode]);

  useEffect(() => {
    const fixHeight = () => {
      if (container && container.current) {
        // eslint-disable-next-line react/no-find-dom-node
        const element = ReactDOM.findDOMNode(container.current) as HTMLElement;
        if (element) {
          const offsetTop = element.offsetTop;

          element.style.height = window.innerHeight - offsetTop - 125 + 'px';
        }
      }
    };

    fixHeight();

    window.addEventListener('resize', fixHeight);

    return () => {
      window.removeEventListener('resize', fixHeight);
    };
  }, []);

  return (
    <Page title="GlobalCare Manager - Invitation" className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.container} ref={container}>
          <CardMedia
            className={classes.media}
            image="/static/images/login.svg"
            title="GlobalCare Manager"
          />
        </Grid>
        <Grid
          item
          xs
          style={{
            display: 'flex',
          }}
          className={classes.loading}
        >
          <CircularProgress />
        </Grid>
      </Grid>
    </Page>
  );
};

export default KeycloakGroupInvitationJoinView;
