import { history } from 'src/App';
import { Settings } from 'src/interfaces';
import { removeToken } from 'src/utils/token';

const LogoutHelper = {
  logout: (orgCode: string, currentPath?: string, type?: string) => {
    removeToken();

    sessionStorage.removeItem('loginInfos');

    switch (type) {
      case 'oauthDisconnected': {
        history.push(`/${orgCode}/disconnected`);
        break;
      }
      default: {
        history.push(
          currentPath
            ? `/${orgCode}/login?currentPath=${encodeURIComponent(
                String(currentPath)
              )}`
            : `/${orgCode}/login`
        );
        break;
      }
    }
  },
};

export default LogoutHelper;
