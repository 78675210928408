import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { User } from 'src/api/api-gc/model';
import { getToken } from 'src/utils/token';
import { useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactDOM from 'react-dom';
import GcApiHelper, { GetUsersRequest } from 'src/helpers/GcApiHelper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import { TextFieldComponent } from 'src/components/UI';
import { EnableSelect } from 'src/components/Form/SelectHelper';
import useHashParamCustom from 'src/hooks/useHashParamCustom';
import ObjectHelper from 'src/helpers/ObjectHelper';
import jsonHelper from 'src/helpers/JsonHelper';

const regexValidPageNumber = /^[1-9\b]+$/;

const useStyles = makeStyles((theme) => ({
  searchPanel: {
    borderColor: theme.palette.divider,
    borderWidth: '0 1px 0 0',
    borderStyle: 'solid',
  },

  table: {
    '& th': {
      //cursor: 'pointer',
    },
    '& td': {
      wordBreak: 'break-word',
      cursor: 'pointer',
    },
  },
  searchDrawer: {
    width: '300px',
    position: 'relative',
    padding: '20px',
    marginRight: '20px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    border: 0,
  },
  searchDrawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  searchDrawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    /*width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },*/
    width: '50px',
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: 'inherit',
  },
  searchToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface UsersListWithLazyLoadingProps {
  open?: boolean;
  onDoubleClick: (values: User[]) => void;
  onValuesChanged?: (values: User[]) => void;
  value?: string | number;
  multiple?: boolean;
  inPopin?: boolean;
  resetValue?: () => void;
}

const UsersListWithLazyLoading = ({
  onDoubleClick,
  onValuesChanged,
  open,
  value,
  multiple = false,
  inPopin,
  resetValue,
}: UsersListWithLazyLoadingProps) => {
  const { useHashParam } = useHashParamCustom();

  const [search, setSearch] = useHashParam('search', '');
  const [selectedPage, setSelectedPage] = useHashParam('page', '');

  const { orgCode } = useParams<{ orgCode: string }>();
  const { t } = useTranslation();
  const translate = (key: string, context?: Record<string, string>) =>
    t(`modals.userSelection.array.${key}`, context);

  const theme = useTheme();
  const [searchPanelOpen, setSearchPanelOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setSearchPanelOpen(true);
  };

  const handleDrawerClose = () => {
    setSearchPanelOpen(false);
  };

  const defaultRequest: GetUsersRequest = {
    token: getToken(),
    orgCode: orgCode,
    mapLevel: ['USER_DETAILS', 'ENTITY_PROFILE', 'TOTAL_COUNT'],
    limitOffset: 0,
    limitCount: 20,
  };

  const perfectScrollbarSearchPanel = useRef<PerfectScrollbar>(null);
  const perfectScrollbar = useRef<PerfectScrollbar>(null);
  const tfPageNumber = useRef<HTMLInputElement>(null);

  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<User[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [searchState, setSearchState] = useState<
    Record<string, string | number | boolean | undefined>
  >(
    search && search.length > 0 && jsonHelper.checkIfStringIsJson(search)
      ? JSON.parse(search)
      : {}
  );

  const [scrollable, setScrollable] = useState<boolean>(false);
  const [request, setRequest] = useState<GetUsersRequest>(
    search && search.length > 0 && jsonHelper.checkIfStringIsJson(search)
      ? { ...defaultRequest, ...JSON.parse(search) }
      : defaultRequest
  );
  const [values, setValues] = useState<User[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const classes = useStyles();

  // const [orderBy, setOrderBy] = React.useState<
  //   Record<string, string> | undefined
  // >(undefined);

  const requestGetUser = () => {
    setLoading(true);
    setScrollable(false);
    setValues([]);

    GcApiHelper.getUsers(request)
      .then(({ data: { values: users, totalCount } }) => {
        if (!users || users.length === 0) {
          setScrollable(false);
        } else {
          //if (users.length === 20) {
          setScrollable(true);
        }
        setTotalCount(totalCount || 0);
        setValues(users || []);
        fixHeight();
      })
      .finally(() => {
        setLoading(false);
        fixHeight();
      });
  };

  useEffect(() => {
    setInitialized(true);
    if (selectedPage === '') {
      requestGetUser();
    } else {
      onPageChange(Number(selectedPage));
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      requestGetUser();
    }
  }, [request]);

  const fixHeight = () => {
    if (perfectScrollbar?.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const element = ReactDOM.findDOMNode(
        perfectScrollbar.current
      ) as HTMLElement;
      if (element) {
        const offsetTop = element.offsetTop;

        if (inPopin) {
          element.style.height =
            window.innerHeight -
            offsetTop -
            (window.innerWidth > 1199 ? 174 : 218) +
            'px';
        } else {
          element.style.height =
            window.innerHeight -
            offsetTop -
            (window.innerWidth > 1199 ? 74 : 118) +
            'px';
        }
      }
    }

    if (perfectScrollbarSearchPanel?.current) {
      // eslint-disable-next-line react/no-find-dom-node
      const element = ReactDOM.findDOMNode(
        perfectScrollbarSearchPanel.current
      ) as HTMLElement;
      if (element) {
        const offsetTop = element.offsetTop;

        element.style.height =
          window.innerHeight - offsetTop - (inPopin ? 443 : 343) + 'px';
      }
    }
  };

  useEffect(() => {
    fixHeight();

    window.addEventListener('resize', fixHeight);
    return () => {
      window.removeEventListener('resize', fixHeight);
    };
  }, []);

  const handleOnChange = (
    field: string,
    value: string | number | boolean | undefined
  ) => {
    setSearchState(
      ObjectHelper.removeEmpty({ ...searchState, [field]: value }) as Record<
        string,
        string
      >
    );
  };

  const handleClick = (value: User) => {
    if (multiple) {
      if (selectedValues.includes(value)) {
        setSelectedValues(selectedValues.filter((v) => v !== value));
      } else {
        setSelectedValues(selectedValues.concat(value));
      }
    } else {
      setSelectedValues([value]);
    }
  };

  // const handleSort = (value: string) => {
  //   if (orderBy) {
  //     if (orderBy['asc'] === value) {
  //       setOrderBy({ desc: value });
  //     } else {
  //       setOrderBy({ asc: value });
  //     }
  //   } else {
  //     setOrderBy({ asc: value });
  //   }
  // };
  // useEffect(() => {
  //   setScrollable(false);
  //   setValues([]);
  //   setRequest((prevState) => ({
  //     ...prevState,
  //     ...defaultRequest,
  //     asc: orderBy && orderBy['asc'] ? [orderBy['asc']] : undefined,
  //     desc: orderBy && orderBy['desc'] ? [orderBy['desc']] : undefined,
  //   }));
  // }, [orderBy]);

  useEffect(() => {
    if (onValuesChanged) {
      onValuesChanged(selectedValues);
    }
  }, [selectedValues]);

  const handleDoubleClick = () => {
    if (!multiple) {
      onDoubleClick(selectedValues);
    }
  };

  const onPageChange = (pageIndex: number) => {
    setPageNumber(pageIndex);
    setRequest((prevState) => ({
      ...prevState,
      limitOffset: (pageIndex - 1) * 20,
    }));
  };

  useEffect(() => {
    if (open === true && initialized) {
      setRequest(defaultRequest);
      setValues([]);
      setScrollable(false);
      setSearchState({});
      setSelectedValues([]);

      setPageNumber(1);

      requestGetUser();
    }
  }, [open]);

  const setPageNumber = (pageIndex: number) => {
    setPage(pageIndex);
    if (open === undefined) {
      setSelectedPage(String(pageIndex));
    }
  };

  const handleSearch = () => {
    if (resetValue) {
      resetValue();
    }
    setPageNumber(1);
    setSelectedValues([]);

    if (open === undefined) {
      setSearch(
        Object.keys(searchState).length > 0 ? JSON.stringify(searchState) : ''
      );
    }

    setRequest({ ...defaultRequest, ...searchState });
  };

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const goToPage = () => {
    if (
      tfPageNumber.current &&
      regexValidPageNumber.test(tfPageNumber.current.value) &&
      page !== Number(tfPageNumber.current.value)
    ) {
      onPageChange(Number(tfPageNumber.current.value));
    }
  };

  /*const onChangeGoToPage = (event: any) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value == '' || regex.test(event.target.value)) {
      if (event.target.value > Math.floor(totalCount / 20)) {
        setGoToPageNumber(Math.floor(totalCount / 20));
      } else {
        setGoToPageNumber(event.target.value);
      }
    }
  };*/

  const handleOnKeyUpGoToPage = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();

    if (event.key === 'Enter') {
      goToPage();
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Drawer
        anchor="left"
        classes={{
          paper: clsx(classes.searchDrawer, {
            [classes.searchDrawerOpen]: searchPanelOpen,
            [classes.searchDrawerClose]: !searchPanelOpen,
          }),
        }}
        open
        variant="persistent">
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.searchToolbar}>
            <IconButton
              onClick={searchPanelOpen ? handleDrawerClose : handleDrawerOpen}
              size="large">
              {!searchPanelOpen ? (
                <SearchIcon />
              ) : theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '-12px' }}>
            <Grid
              container
              style={{ display: searchPanelOpen ? 'inherit' : 'none' }}>
              <Grid item xs={12}>
                <PerfectScrollbar
                  ref={perfectScrollbarSearchPanel}
                  options={{
                    suppressScrollX: true,
                    suppressScrollY: scrollable,
                  }}
                  style={{
                    height: window.innerHeight - 423,
                    display: 'flex',
                  }}>
                  <Grid
                    container
                    spacing={3}
                    style={{ display: 'block', marginBottom: 0, marginTop: 0 }}>
                    <Grid item xs={12}>
                      <TextFieldComponent
                        fullWidth
                        label={translate('fields.lastname')}
                        name="userLastName"
                        value={searchState.userLastName || ''}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                        onKeyUp={handleOnKeyUp}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldComponent
                        fullWidth
                        label={translate('fields.firstname')}
                        name="userFirstName"
                        value={searchState.userFirstName || ''}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                        onKeyUp={handleOnKeyUp}
                        inputProps={{
                          maxLength: 600,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldComponent
                        fullWidth
                        label={translate('fields.email')}
                        name="userEmail"
                        value={searchState.userEmail || ''}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                        onKeyUp={handleOnKeyUp}
                        inputProps={{
                          maxLength: 600,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldComponent
                        fullWidth
                        label={translate('fields.username')}
                        name="userName"
                        value={searchState.userName || ''}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                        onKeyUp={handleOnKeyUp}
                        inputProps={{
                          maxLength: 600,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <EnableSelect
                        value={searchState.enable as boolean}
                        onChange={(value) => {
                          handleOnChange('enable', value);
                        }}
                        label={translate('fields.enable')}
                      />
                    </Grid>
                  </Grid>
                </PerfectScrollbar>
              </Grid>
              {/*<Grid item xs={12}>
              {selected.map((data: SelectData) => (
                <Chip
                  key={data.value}
                  label={data.label}
                  //className={classes.chip}
                  //onDelete={handleDelete(data)}
                />
              ))}
            </Grid>*/}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button onClick={handleSearch} color="primary">
                  {t('modals.userSelection.buttons.search')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PerfectScrollbar
            ref={perfectScrollbar}
            options={{ suppressScrollX: true, suppressScrollY: scrollable }}
            style={
              window.innerWidth > 900
                ? {
                    height: window.innerHeight - 318,
                    display: 'flex',
                  }
                : { height: window.innerHeight - 318, display: 'flex' }
            }>
            <Table className={classes.table} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    onClick={() => {
                      //handleSort('userLastName');
                    }}>
                    {translate('fields.lastname')}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      //handleSort('userFirstName');
                    }}>
                    {translate('fields.firstname')}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      //handleSort('userEmail');
                    }}>
                    {translate('fields.email')}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      //handleSort('userName');
                    }}>
                    {translate('fields.username')}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      //handleSort('userLocale');
                    }}>
                    {translate('fields.locale')}
                  </TableCell>
                  <TableCell align="center">
                    {translate('fields.enable')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{
                  backgroundColor: theme.palette.background.dark,
                }}>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : values.length > 0 ? (
                  values.map((v) => {
                    return (
                      <TableRow
                        hover
                        key={v.userId}
                        selected={
                          selectedValues.length > 0
                            ? selectedValues.includes(v)
                            : v.userId === value
                        }
                        onClick={() => handleClick(v)}
                        onDoubleClick={() => handleDoubleClick()}>
                        <TableCell
                          align="center"
                          //style={{ whiteSpace: 'nowrap' }}
                        >
                          {v.userLastName}
                        </TableCell>
                        <TableCell align="center">{v.userFirstName}</TableCell>
                        <TableCell align="center">{v.userEmail}</TableCell>
                        <TableCell align="center">{v.userName}</TableCell>
                        <TableCell align="center">{v.userLocale}</TableCell>

                        <TableCell align="right">
                          <Checkbox checked={v.enable} disabled />
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      unselectable={'on'}
                      style={{ cursor: 'default' }}>
                      <Typography
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                          fontWeight: theme.typography.fontWeightBold,
                        }}>
                        {translate('noResult')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: 0,
          }}>
          {!loading && (
            <Grid
              container
              style={{
                backgroundColor: theme.palette.background.default,
                padding: '1px 5px 5px 5px',
              }}>
              <Grid item xs={12} lg>
                <Pagination
                  style={{
                    paddingTop: '8px',
                    paddingBottom: '4px',
                  }}
                  sx={{ minWidth: '400px' }}
                  color={'primary'}
                  page={page}
                  defaultPage={1}
                  count={Math.ceil(totalCount / 20)}
                  onChange={(event, p: number) => {
                    if (page !== p) {
                      onPageChange(p);
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <TextFieldComponent
                  inputRef={tfPageNumber}
                  style={{
                    marginTop: '4px',
                    marginLeft: '4px',
                    width: '100px',
                  }}
                  label={translate('goToPage')}
                  size={'small'}
                  onKeyUp={(event: any) => {
                    handleOnKeyUpGoToPage(event);
                  }}
                  /*onChange={(event: any) => {
                    onChangeGoToPage(event);
                  }}*/
                  defaultValue={''}
                />
                <Button
                  onClick={goToPage}
                  variant={'contained'}
                  style={{
                    marginTop: '4px',
                    marginLeft: '4px',
                    marginRight: '10px',
                    height: '40px',
                  }}>
                  {t('modals.userSelection.buttons.goToPage')}
                </Button>
              </Grid>
              <Grid item xs lg md>
                <span>
                  <Typography
                    style={{
                      marginTop: '10px',
                      marginRight: '10px',
                      float: 'right',
                    }}>
                    {translate('totalCount', {
                      totalCount: totalCount.toString(),
                    })}
                  </Typography>
                </span>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default UsersListWithLazyLoading;
