import { TreeItem } from '@nosferatu500/react-sortable-tree';

const ObjectHelper = {
  removeProperty: function (obj: any, propertyName: string): any {
    const newObj = { ...obj };
    delete newObj[propertyName];
    return newObj;
  },
  removePropertyRecursively: function (obj: any, propertyName: string) {
    const newObj = { ...obj };
    delete newObj[propertyName];

    for (const key of Object.keys(newObj)) {
      if (Array.isArray(newObj[key])) {
        newObj[key] = (newObj[key] as any[]).map((obj: any) =>
          this.removePropertyRecursively(obj, propertyName)
        );
      }
    }

    return newObj;
  },
  removePropertiesRecursively: function (obj: any, propertyNames: string[]) {
    const newObj = { ...obj };
    for (const propertyName of propertyNames || []) {
      delete newObj[propertyName];
    }

    for (const key of Object.keys(newObj)) {
      if (Array.isArray(newObj[key])) {
        newObj[key] = (newObj[key] as any[]).map((obj: any) =>
          this.removePropertiesRecursively(obj, propertyNames)
        );
      }
    }

    return newObj;
  },
  renameKeysRecursive: function (
    baseArray: Record<string, any>[],
    keysToRename: Record<string, string>,
    childrenKeyName?: string
  ): TreeItem[] {
    const arrCopy = [...baseArray];

    arrCopy.forEach((item) => {
      for (const key in keysToRename) {
        if (item[key]) {
          item[keysToRename[key]] = item[key];
          this.removeProperty(item, key);
        }

        if (childrenKeyName && item[childrenKeyName]) {
          this.renameKeysRecursive(
            item[childrenKeyName],
            keysToRename,
            childrenKeyName
          );
        }
      }
    });

    return arrCopy;
  },
  isObjectEmpty: function (object: object) {
    return Object.keys(object).length === 0;
  },
  removeEmpty: function (obj: any): any {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, v]) => v !== null && v !== undefined && v !== ''
      )
    );
  },
  removeEmptyRecursively: function (obj: any): any {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v !== null && v !== undefined && v !== '')
        .map(([k, v]) => [
          k,
          v === Object(v) ? ObjectHelper.removeEmptyRecursively(v) : v,
        ])
    );
  },
  isObject: function (obj: any): boolean {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
  },
  isString: function (obj: any): boolean {
    return typeof obj === 'string';
  },
};

export default ObjectHelper;
