import * as React from 'react';
import {
  ValidationStateSchema,
  ErrorInterface,
  FormFieldType,
} from 'src/interfaces';
import { Grid } from '@mui/material';
import {
  InputGuesser,
  ASYNC_SELECT_TREE_INPUT,
  SWITCH_INPUT,
  ASYNC_SELECT_INPUT,
} from './InputGuesser';
import ValidationStateSchemaHelper from '../../helpers/ValidationStateSchemaHelper';

export interface FormProps {
  withDescription?: boolean;
  className?: string;
  validationStateSchema: ValidationStateSchema;
  formData?: Record<string, any>;
  fields: FormFieldType[];
}

export interface StateInterface {
  errors: Record<string, string>;
  formData: Record<string, any>;
}

export class FormWidget extends React.Component<FormProps> {
  //formData: Record<string, any>;

  public state: StateInterface = {
    errors: {},
    formData: {},
  };

  constructor(props: FormProps) {
    super(props);

    this.state = {
      errors: {},
      formData: this.props.formData ? this.props.formData : {},
    };

    /*if (this.props.formData) {
      this.setState({...this.state, formData: this.props.formData});
    }*/
  }

  validateState() {
    /*let hasErrorInState = false;
    const errors: ErrorInterface = {};
    for (const key in this.props.validationStateSchema) {
      const isInputFieldRequired: boolean =
        this.props.validationStateSchema[key].required;

      if (isInputFieldRequired && !this.state.formData[key]) {
        hasErrorInState = true;
        errors[key] = this.props.validationStateSchema[key].error || '';
      } else if (
        this.state.formData[key] !== undefined &&
        this.props.validationStateSchema[key].validator !== undefined &&
        this.props.validationStateSchema[key].validator?.regEx !== undefined &&
        !this.props.validationStateSchema[key].validator?.regEx.test(
          this.state.formData[key]
        )
      ) {
        hasErrorInState = true;
        errors[key] =
          this.props.validationStateSchema[key].validator?.error || '';
      }
    }*/

    const { errors, hasError } = ValidationStateSchemaHelper.validateState(
      this.props.validationStateSchema,
      this.state.formData
    );

    //this.errors = errors;
    this.setState({
      ...this.state,
      errors: errors,
    });

    return !hasError;
  }

  getFormData() {
    return this.state.formData;
  }

  render() {
    const getValue = (type: string, e: any) => {
      switch (type) {
        case ASYNC_SELECT_INPUT:
        case ASYNC_SELECT_TREE_INPUT:
          return e;
        case SWITCH_INPUT:
          return e.target.checked;
        default:
          return e.target.value;
      }
    };

    return (
      <Grid
        container
        className={this.props.className}
        spacing={3}
        style={{ marginTop: this.props.withDescription ? '0' : '-18px' }}>
        {(this.props.fields || []).map((item, key) => (
          <Grid item key={key} xs={12}>
            <InputGuesser
              isCode={item.isCode}
              label={item.label}
              name={item.name}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  /*formData: {
                    ...this.state.formData,
                    [item.name]:
                      item.type ===
                      (ASYNC_SELECT_INPUT || ASYNC_SELECT_TREE_INPUT)
                        ? e
                        : item.type === SWITCH_INPUT
                        ? e.target.checked
                        : e.target.value,
                  },*/
                  formData: {
                    ...this.state.formData,
                    [item.name]: getValue(item.type, e),
                  },
                });
              }}
              type={item.type}
              value={this.state.formData[item.name] || ''}
              values={item.values || []}
              error={!!this.state.errors[item.name]}
              helperText={this.state.errors[item.name]}
              required={item.required}
              accessorField={item.accessorField}
              childrenField={item.childrenField}
              labelField={item.labelField}
              promise={item.promise}
              getResult={item.getResult}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}
