import { CategoryItem } from 'src/api/api-gc/model';
import { APICategoryService } from 'src/api/api-gc/service';
import { getToken } from 'src/utils/token';
import { TranslationHelper } from './index';

const categoryService = new APICategoryService();

const CategoryHelper = {
  buildCategoryItemsTree: (
    parentId: number,
    items: CategoryItem[]
  ): CategoryItem[] => {
    return items
      .filter((item) => item.itemParentId === parentId)
      .map((item) => {
        item.itemChildrens = CategoryHelper.buildCategoryItemsTree(
          item.itemId as number,
          items
        );

        if (item.itemChildrens && item.itemChildrens.length === 0) {
          delete item.itemChildrens;
        }

        return item;
      });
  },
  getCategoryItemsByCatCode: async (
    token: string,
    orgCode: string,
    categoryCode: string,
    preventTreeBuilding?: boolean
  ): Promise<{ catId: number; items: CategoryItem[] } | undefined> => {
    const { data } = await categoryService.searchCategoriesItems(
      getToken(),
      orgCode,
      undefined,
      categoryCode,
      undefined,
      undefined,
      ['TRANSLATIONS']
    );

    if (data) {
      return {
        catId: data.catId as number,
        items: preventTreeBuilding
          ? data.values || []
          : CategoryHelper.buildCategoryItemsTree(0, data.values || []) || [],
      };
    } else {
      return undefined;
    }
  },
  getPartCategory: (
    categoryItems: CategoryItem[],
    catItemId: number,
    lang: string
  ): string => {
    let result: string = '';
    if (categoryItems) {
      for (const categoryItem of categoryItems) {
        if (categoryItem.itemId === catItemId) {
          result = TranslationHelper.getTranslation(
            lang,
            categoryItem.itemName,
            categoryItem.translations
          );
        } else if (categoryItem.itemChildrens) {
          const temp = CategoryHelper.getPartCategory(
            categoryItem.itemChildrens,
            catItemId,
            lang
          );
          if (temp !== '') {
            result = temp;
          }
        }
      }
    }

    return result;
  },
};

export default CategoryHelper;
