import React, { useEffect, useState } from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Category as CategoryIcon,
  ExpandLess,
  ExpandMore,
  Folder as FolderIcon,
  FolderOpen as FolderOpenIcon,
} from '@mui/icons-material';

const TreeItem = ({
  item,
  path,
  accessorField,
  labelField,
  childrenField,
  handleSelect,
  selectedValue,
}: {
  item: any;
  path: string;
  accessorField: string;
  labelField?: string;
  childrenField: string;
  handleSelect: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: string | number
  ) => void;
  selectedValue: string | number;
}) => {
  const [open, setOpen] = useState<boolean>(!!item.isOpened);

  useEffect(() => {
    setOpen(item.isOpened || false);
  }, [item.isOpened]);

  const handleClick = () => {
    setOpen(!open);
  };

  if (!item) {
    return null;
  }

  if (item[childrenField]) {
    return (
      <>
        <ListItem
          button
          onClick={(e) => handleSelect(e, item[accessorField])}
          selected={selectedValue === item[accessorField]}>
          <ListItemIcon>
            {open ? <FolderOpenIcon /> : <FolderIcon />}
          </ListItemIcon>
          <ListItemText
            primary={item[labelField || 'itemName']}
            title={`${path} ${item[labelField || 'itemName']}`}
          />
          {open ? (
            <ExpandLess onClick={handleClick} />
          ) : (
            <ExpandMore onClick={handleClick} />
          )}
        </ListItem>
        <Collapse
          style={{ paddingLeft: '15px' }}
          in={open}
          timeout="auto"
          unmountOnExit>
          {item[childrenField].map((child: any) => (
            <TreeItem
              item={child}
              path={`${path} ${item[labelField || 'itemName']} /`}
              key={child[accessorField]}
              handleSelect={handleSelect}
              selectedValue={selectedValue}
              accessorField={accessorField}
              childrenField={childrenField}
              labelField={labelField}
            />
          ))}
        </Collapse>
      </>
    );
  }

  return (
    <ListItem
      button
      onClick={(e) => handleSelect(e, item[accessorField])}
      selected={selectedValue === item[accessorField]}>
      <ListItemIcon>
        <CategoryIcon />
      </ListItemIcon>
      <ListItemText
        primary={item[labelField || 'itemName']}
        title={`${path} ${item[labelField || 'itemName']}`}
      />
    </ListItem>
  );
};

const AnotherTreeView = ({
  items,
  handleSelect,
  value,
  accessorField,
  childrenField,
  labelField,
  onLoad,
}: {
  items: any[];
  handleSelect: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: string | number
  ) => void;
  value?: string | number;
  accessorField: string;
  childrenField: string;
  labelField?: string;
  onLoad?: () => void;
}) => {
  useEffect(() => {
    if (onLoad) {
      onLoad();
    }
  }, []);

  return (
    <List>
      {items
        ? (items || []).map((item) => (
            <TreeItem
              item={item}
              path=""
              accessorField={accessorField}
              childrenField={childrenField}
              key={item[accessorField]}
              handleSelect={handleSelect}
              selectedValue={value || ''}
              labelField={labelField}
            />
          ))
        : null}
    </List>
  );
};

export default AnotherTreeView;
