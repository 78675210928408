import config from './config.js';

import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { CustomLangageDetector } from './classes';

const availableTranslations = ['fr_FR', 'en_UK', 'en_US', 'en_CA', 'fr_CA'];

/*interface CustomDetector {
  name: string;
  cacheUserLanguage?(lng: string, options: DetectorOptions): void;
  lookup(options: DetectorOptions): string | undefined;
}*/

/*const myDetector = {
  name: 'myCustomDetector',
  lookup: (options) => {
    console.log(options);

    return 'en';
  },
}
languageDetector.addDetector(myDetector);*/

const languageDetector = new CustomLangageDetector();
languageDetector.detect = (detectionOrder) => {
  if (!detectionOrder)
    detectionOrder = [
      'querystring',
      'cookie',
      'localStorage',
      'navigator',
      'htmlTag',
    ];

  let detected = [];
  detectionOrder.forEach((detectorName) => {
    if (languageDetector.detectors[detectorName]) {
      let lookup = languageDetector.detectors[detectorName].lookup(
        languageDetector.options
      );

      if (lookup && typeof lookup === 'string')
        lookup = [lookup.replace('-', '_')];
      if (lookup && Array.isArray(lookup)) {
        lookup = lookup.map((value) => {
          if (value && typeof value === 'string')
            return value.replace('-', '_');
          else return value;
        });
      }
      if (lookup) detected = detected.concat(lookup);
    }
  });

  detected = detected.filter((l) => {
    return availableTranslations.includes(l);
  });

  if (detected.length === 0) {
    detected.push(config.app.defaultLang);
  }

  //if (languageDetector.services.languageUtils.getBestMatchFromCodes) return detected; // new i18next v19.5.0
  return detected.length > 0 ? detected[0] : null; // a little backward compatibility
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: config.app.defaultLang,
    debug: false,
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    /*detection: {
      // order and from where user language should be detected
      order: ['myCustomDetector', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag']
    },*/
  });

export default i18n;
