import {
  colors,
  responsiveFontSizes,
  createTheme as createMuiTheme,
  ThemeOptions,
} from '@mui/material';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
import THEMES from 'src/constants';
import { Settings } from 'src/interfaces/Settings';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    dark: string;
    card: string;
  }
}

const baseConfig: ThemeOptions = {
  direction: 'ltr',
  typography,
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
          paddingTop: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.075)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: 'inherit',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { backgroundImage: 'none' },
      },
    },
  },
};

const getTheme = (theme: keyof typeof THEMES): ThemeOptions => {
  return theme === 'LIGHT'
    ? {
        components: {
          ...baseConfig.components,
          MuiFormControlLabel: {
            styleOverrides: {
              label: {
                color: colors.blueGrey[900],
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              input: {
                '&::placeholder': {
                  opacity: 1,
                  color: colors.blueGrey[600],
                },
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              body1: {
                color: colors.blueGrey[900],
              },
            },
          },
        },
        palette: {
          mode: 'light',
          action: {
            active: colors.blueGrey[600],
          },
          background: {
            default: '#ffffff',
            dark: '#f4f6f8',
            paper: '#ffffff',
            card: '#eaeef2',
          },
          primary: {
            main: '#0D529F',
          },
          secondary: {
            main: '#5850EC',
          },
          text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
          },
        },
        shadows: softShadows,
      }
    : {
        components: {
          ...baseConfig.components,
          MuiFormControlLabel: {
            styleOverrides: {
              label: {
                color: '#e6e5e8',
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              body1: {
                color: '#e6e5e8',
              },
            },
          },
        },
        palette: {
          mode: 'dark',
          action: {
            active: 'rgba(255, 255, 255, 0.54)',
            hover: 'rgba(255, 255, 255, 0.04)',
            selected: 'rgba(255, 255, 255, 0.08)',
            disabled: 'rgba(255, 255, 255, 0.26)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)',
            focus: 'rgba(255, 255, 255, 0.12)',
          },
          background: {
            default: '#282C34',
            dark: '#1c2025',
            paper: '#282C34',
            card: '#333842',
          },
          primary: {
            main: '#8a85ff',
          },
          secondary: {
            main: '#8a85ff',
          },
          text: {
            primary: '#e6e5e8',
            secondary: '#adb0bb',
          },
        },
        shadows: strongShadows,
      };
};

export function createTheme(settings: Settings) {
  const mergedTheme: ThemeOptions = {
    ...baseConfig,
    ...getTheme(settings.theme),
    ...{ direction: settings.direction },
  };

  let theme = createMuiTheme(mergedTheme);

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
