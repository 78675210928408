import React, {
  createContext,
  Dispatch,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { User, Group } from 'src/api/api-keycloak/model';
import { APIKeycloakService } from 'src/api/api-keycloak/apiKeycloak.service';
import { getToken } from 'src/utils/token';
import { useParams } from 'react-router-dom';

const keycloakService = new APIKeycloakService();

export const LOAD = 'LOAD';
export const SET_STATIC = 'SET_STATIC';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';
export const ERRORS = 'ERRORS';

interface ActionInterface {
  payload:
    | any
    | User
    | {
        field: string;
        value: string;
      }
    | Group[];
  type: string;
}

interface DetailContextInterface {
  data: User;
  edited: boolean;
  dispatch: Dispatch<ActionInterface>;
  errors: Record<string, string>;
  firstErrorOn: string;
  static: {
    groups: Group[];
  };
}

const defaultContext: DetailContextInterface = {
  data: {},
  edited: false,
  dispatch: () => null,
  errors: {},
  firstErrorOn: '',
  static: {
    groups: [],
  },
};

export const DetailContext = createContext(defaultContext);

const detailReducer = (
  state: any | DetailContextInterface,
  { payload, payload: { field, value }, type }: ActionInterface
) => {
  switch (type) {
    case LOAD: {
      return {
        ...state,
        User: {
          ...payload,
        },
        data: {},
      };
    }
    case SET_STATIC: {
      return {
        ...state,
        static: {
          ...state.static,
          ...payload,
        },
      };
    }
    case UPDATE_DESCRIPTION: {
      return {
        ...state,
        data: {
          ...state.data,
          [field.toString()]: value,
        },
        edited: true,
      };
    }
    case UPDATE_GROUPS: {
      return {
        ...state,
        data: {
          ...state.data,
          groups: payload,
        },
        edited: true,
      };
    }

    case ERRORS: {
      return {
        ...state,
        errors: payload.errors,
        firstErrorOn: payload.firstErrorOn,
      };
    }
    default: {
      return state;
    }
  }
};

export const DetailProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(detailReducer, defaultContext);
  const { orgCode } = useParams<{ orgCode: string }>();
  const memoizedOrgCode = useMemo(() => orgCode ?? '', [orgCode]);

  useEffect(() => {
    if (getToken()) {
      Promise.all([
        keycloakService.getGroups(
          getToken(),
          memoizedOrgCode
          //PermissionsEnum.update
        ),
      ]).then(([{ data: groups }]) => {
        dispatch({
          payload: {
            groups: groups || [],
          },
          type: SET_STATIC,
        });
      });
    }
  }, []);

  return (
    <DetailContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </DetailContext.Provider>
  );
};
