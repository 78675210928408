import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useEffect, useState } from 'react';
import { InputValueInterface } from 'src/interfaces';
import { FormHelperText } from '@mui/material';

export interface RowRadioButtonsGroupProps {
  values?: InputValueInterface[];
  title?: string;
  value?: string | number;
  onChange?: (value: string | number) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
}

const RowRadioButtonsGroup: React.FC<RowRadioButtonsGroupProps> = ({
  values,
  title,
  value: pValue,
  onChange,
  required,
  disabled,
  error,
  helperText,
  fullWidth,
}) => {
  const [options, setOptions] = React.useState<InputValueInterface[]>([]);
  const [value, setValue] = useState<string | number | undefined>();

  React.useEffect(() => {
    setOptions(values || []);
  }, [values]);

  useEffect(() => {
    setValue(pValue);
  }, [pValue]);

  const handleChange = (value: string | number) => {
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <FormControl error={error} disabled={disabled} fullWidth={fullWidth}>
      <FormLabel
        //id="demo-row-radio-buttons-group-label"
        required={required}>
        {title}
      </FormLabel>
      <RadioGroup
        row
        //aria-labelledby="demo-row-radio-buttons-group-label"
        //name="row-radio-buttons-group"
        //defaultValue={value}
        onChange={(_, value) => {
          handleChange(value);
        }}>
        {options.map((v, index) => (
          <FormControlLabel
            checked={value === v.value}
            key={index}
            value={v.value}
            disabled={disabled || v.disable}
            control={<Radio />}
            label={v.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default RowRadioButtonsGroup;
