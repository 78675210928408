import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Group, User } from 'src/api/api-keycloak/model';
import { DetailContext, UPDATE_GROUPS } from '../context';
import { ButtonComponent, TextFieldComponent } from 'src/components/UI';
import { RenderTree } from 'src/interfaces';
import TreeView from 'src/components/UI/TreeView/TreeView';
import LoadingComponent from 'src/components/LoadingComponent';

const useStyles = makeStyles(() => ({
  hide: {
    display: 'none',
  },
  errorMessage: {
    width: '100%',
    textAlign: 'center',
    color: 'red',
  },
}));

interface Props {
  user: User;
}

const Groups = ({ user }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const translate = (key: string, context?: Record<string, string>) =>
    t(`keycloak.users.form.groups.${key}`, context);
  const {
    dispatch,
    data,
    static: { groups },
    errors,
  } = useContext(DetailContext);

  const formData: User = { ...user, ...data };

  const [search, setSearch] = useState<string>('');
  //const [selected, setSelected] = React.useState<RenderTree[]>([]);
  const [values, setValues] = useState<RenderTree[] | undefined>(undefined);
  //const [valuesToDisplay, setValuesToDisplay] = useState<RenderTree[]>([]);

  const getRenderTreeValues = useCallback(
    (groups: Group[], parentDisabled?: boolean) => {
      return (groups || []).map((g) => {
        const disabled =
          parentDisabled !== undefined
            ? parentDisabled
            : !g.permissions ||
              !g.permissions.aclUpdate ||
              g.permissions.aclUpdate !== 1;

        const item: RenderTree = {
          id: g.id as string,
          code: g.name as string,
          label: g.name as string,
          disabled: disabled,
        };
        if (g.subGroups && g.subGroups.length > 0) {
          item.children = getRenderTreeValues(g.subGroups, disabled);
        }

        return item;
      });
    },
    [groups]
  );

  const getSelectedValues = useCallback(
    (groups: Group[]) => {
      const findItem = (id: string, values: RenderTree[]) => {
        for (const v of values) {
          if (v.id === id) {
            return v;
          } else if (v.children) {
            const result: any = findItem(id, v.children);
            if (result) {
              return result;
            }
          }
        }
        return undefined;
      };

      const selectedValues: RenderTree[] = [];
      for (const g of groups || []) {
        const result = findItem(g.id as string, values || []);
        if (result) {
          selectedValues.push(result);
        }
      }

      return selectedValues;
    },
    [values]
  );

  useEffect(() => {
    setValues(getRenderTreeValues(groups));
  }, [groups]);

  /*useEffect(() => {
    console.log(formData.groups);
  }, [formData.groups]);*/

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value || '');
  };

  const removeAllGroup = () => {
    dispatch({
      payload: [],
      type: UPDATE_GROUPS,
    });
  };

  var timer: any;
  useEffect(() => {
    const find = (item: any, search: string): any => {
      if (
        item.label &&
        (item.label as string).toLowerCase().includes(search.toLowerCase())
      ) {
        return item;
      } else if (item.children) {
        const children = [];
        for (const i of item.children) {
          const tmp = find(i, search);
          if (tmp !== undefined) {
            children.push(tmp);
          }
        }

        if (children.length > 0) {
          const tmp: any = { ...item };
          tmp.isOpened = true;
          tmp.children = children;
          return tmp;
        }
      }
    };

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (search === '') {
        setValues(getRenderTreeValues(groups));
      } else {
        setValues(
          getRenderTreeValues(groups)
            .map((i) => find(i, search))
            .filter((i) => {
              return i !== undefined;
            })
        );
      }
    }, 400);

    return () => {
      clearTimeout(timer);
    };
  }, [search, groups]);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Box>
            <ButtonComponent
              //disabled={(formData.groups || []).length < 1}
              color="primary"
              //onClick={expandAllGroup}
              label={translate('buttons.expandAll')}
            />
          </Box>
          <Box display="flex">
            <TextFieldComponent
              disabled={(groups || []).length < 1}
              label={translate('buttons.search')}
              onChange={handleChange}
              size="small"
              style={{ marginRight: '10px' }}
            />
            <ButtonComponent
              disabled={(formData.groups || []).length < 1}
              color="primary"
              onClick={removeAllGroup}
              label={translate('buttons.removeAllGroups')}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <LoadingComponent loading={values === undefined}>
            {(values || []).length === 0 ? (
              <Box mt={3} textAlign="center">
                <Typography color="textPrimary">
                  {translate('noGroup')}
                </Typography>
              </Box>
            ) : (
              /*<Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('group')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {(groups || []).map((group, index) => (
                  <TableRow
                    className={
                      filterField(`${group.name}}`) ? classes.hide : undefined
                    }
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                      {group.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>*/
              <TreeView
                items={values as RenderTree[]}
                selectedValues={getSelectedValues(formData.groups || [])}
                onChange={(selectedValues) => {
                  const result = (selectedValues || []).map((v) => ({
                    id: v.id,
                  }));
                  if (
                    JSON.stringify(formData.groups) !== JSON.stringify(result)
                  ) {
                    dispatch({
                      payload: result,
                      type: UPDATE_GROUPS,
                    });
                  }
                }}
                multipleSelection={true}
                //onLoad={scrollTo}
              />
            )}
          </LoadingComponent>
        </Grid>
        <Grid item xs={12}>
          {errors.groups && (
            <FormHelperText error className={classes.errorMessage}>
              {errors.groups}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Groups;
