/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.3-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  NewsFeed,
  NewsFeedFilter,
  SearchNewsFeedFiltersResponse,
  SearchNewsFeedsResponse,
} from '../model';
import { Configuration } from 'src/api/api-gc/configuration';
import axios, { AxiosResponse } from 'axios';
import config from 'src/config';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APINewsService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Creates a newsFeed
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param body
   * @param mapLevel
   */
  public createNewsFeed(
    token: string,
    orgCode: string,
    body?: NewsFeed,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<NewsFeed>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createNewsFeed.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createNewsFeed.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<NewsFeed>(`${this.basePath}/news/newsFeeds`, body, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      validateStatus: validateStatus,
    });
  }

  /**
   * Creates a newsFeedFilter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param newsId
   * @param body
   * @param mapLevel
   */
  public createNewsFeedFilter(
    token: string,
    orgCode: string,
    newsId: number,
    body?: NewsFeedFilter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<NewsFeedFilter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling createNewsFeedFilter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling createNewsFeedFilter.'
      );
    }

    if (newsId === null || newsId === undefined) {
      throw new Error(
        'Required parameter newsId was null or undefined when calling createNewsFeedFilter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.post<NewsFeedFilter>(
      `${this.basePath}/news/newsFeedFilters/${encodeURIComponent(
        String(newsId)
      )}/filters`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the newsFeed
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param newsId
   * @param mapLevel
   */
  public getNewsFeed(
    token: string,
    orgCode: string,
    newsId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<NewsFeed>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getNewsFeed.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getNewsFeed.'
      );
    }

    if (newsId === null || newsId === undefined) {
      throw new Error(
        'Required parameter newsId was null or undefined when calling getNewsFeed.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<NewsFeed>(
      `${this.basePath}/news/newsFeeds/${encodeURIComponent(String(newsId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get the newsFeedFilter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param newsId
   * @param filterId
   * @param mapLevel
   */
  public getNewsFeedFilter(
    token: string,
    orgCode: string,
    newsId: number,
    filterId: number,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<NewsFeedFilter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getNewsFeedFilter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getNewsFeedFilter.'
      );
    }

    if (newsId === null || newsId === undefined) {
      throw new Error(
        'Required parameter newsId was null or undefined when calling getNewsFeedFilter.'
      );
    }

    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling getNewsFeedFilter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<NewsFeedFilter>(
      `${this.basePath}/news/newsFeedFilters/${encodeURIComponent(
        String(newsId)
      )}/filters/${encodeURIComponent(String(filterId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get newsFeedFilters
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param newsId
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param filterExclude
   */
  public getNewsFeedFilters(
    token: string,
    orgCode: string,
    newsId: number,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    filterExclude?: boolean
  ): Promise<AxiosResponse<SearchNewsFeedFiltersResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getNewsFeedFilters.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getNewsFeedFilters.'
      );
    }

    if (newsId === null || newsId === undefined) {
      throw new Error(
        'Required parameter newsId was null or undefined when calling getNewsFeedFilters.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (filterExclude !== undefined && filterExclude !== null) {
      queryParameters.append('filterExclude', filterExclude as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchNewsFeedFiltersResponse>(
      `${this.basePath}/news/newsFeedFilters/${encodeURIComponent(
        String(newsId)
      )}/filters`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Get newsFeeds
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param limitCount
   * @param limitOffset
   * @param mapLevel
   * @param enable
   * @param newsLocale
   */
  public getNewsFeeds(
    token: string,
    orgCode: string,
    limitCount?: number,
    limitOffset?: number,
    mapLevel?: Array<string>,
    enable?: boolean,
    newsLocale?: string
  ): Promise<AxiosResponse<SearchNewsFeedsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getNewsFeeds.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getNewsFeeds.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (limitCount !== undefined && limitCount !== null) {
      queryParameters.append('limitCount', limitCount as any);
    }
    if (limitOffset !== undefined && limitOffset !== null) {
      queryParameters.append('limitOffset', limitOffset as any);
    }
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters.append('enable', enable as any);
    }
    if (newsLocale !== undefined && newsLocale !== null) {
      queryParameters.append('newsLocale', newsLocale as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchNewsFeedsResponse>(
      `${this.basePath}/news/newsFeeds`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a newsFeed
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param newsId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyNewsFeed(
    token: string,
    orgCode: string,
    newsId: number,
    body?: NewsFeed,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<NewsFeed>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyNewsFeed.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyNewsFeed.'
      );
    }

    if (newsId === null || newsId === undefined) {
      throw new Error(
        'Required parameter newsId was null or undefined when calling modifyNewsFeed.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<NewsFeed>(
      `${this.basePath}/news/newsFeeds/${encodeURIComponent(String(newsId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Modify a newsFeedFilter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param newsId
   * @param filterId
   * @param body
   * @param mapLevel
   * @param disableLoadingScreen To disable loading screen
   */
  public modifyNewsFeedFilter(
    token: string,
    orgCode: string,
    newsId: number,
    filterId: number,
    body?: NewsFeedFilter,
    mapLevel?: Array<string>,
    disableLoadingScreen?: boolean
  ): Promise<AxiosResponse<NewsFeedFilter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling modifyNewsFeedFilter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling modifyNewsFeedFilter.'
      );
    }

    if (newsId === null || newsId === undefined) {
      throw new Error(
        'Required parameter newsId was null or undefined when calling modifyNewsFeedFilter.'
      );
    }

    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling modifyNewsFeedFilter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }
    if (disableLoadingScreen) {
      headers['disableLoadingScreen'] = true;
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.patch<NewsFeedFilter>(
      `${this.basePath}/news/newsFeedFilters/${encodeURIComponent(
        String(newsId)
      )}/filters/${encodeURIComponent(String(filterId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a newsFeed
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param newsId
   */
  public removeNewsFeed(
    token: string,
    orgCode: string,
    newsId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeNewsFeed.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeNewsFeed.'
      );
    }

    if (newsId === null || newsId === undefined) {
      throw new Error(
        'Required parameter newsId was null or undefined when calling removeNewsFeed.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/news/newsFeeds/${encodeURIComponent(String(newsId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Remove a newsFeedFilter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param newsId
   * @param filterId
   */
  public removeNewsFeedFilter(
    token: string,
    orgCode: string,
    newsId: number,
    filterId: number
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling removeNewsFeedFilter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling removeNewsFeedFilter.'
      );
    }

    if (newsId === null || newsId === undefined) {
      throw new Error(
        'Required parameter newsId was null or undefined when calling removeNewsFeedFilter.'
      );
    }

    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling removeNewsFeedFilter.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.delete<any>(
      `${this.basePath}/news/newsFeedFilters/${encodeURIComponent(
        String(newsId)
      )}/filters/${encodeURIComponent(String(filterId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a newsFeed
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param newsId
   * @param body
   * @param mapLevel
   */
  public replaceNewsFeed(
    token: string,
    orgCode: string,
    newsId: number,
    body?: NewsFeed,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<NewsFeed>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceNewsFeed.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceNewsFeed.'
      );
    }

    if (newsId === null || newsId === undefined) {
      throw new Error(
        'Required parameter newsId was null or undefined when calling replaceNewsFeed.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<NewsFeed>(
      `${this.basePath}/news/newsFeeds/${encodeURIComponent(String(newsId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * Update a newsFeedFilter
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param newsId
   * @param filterId
   * @param body
   * @param mapLevel
   */
  public replaceNewsFeedFilter(
    token: string,
    orgCode: string,
    newsId: number,
    filterId: number,
    body?: NewsFeedFilter,
    mapLevel?: Array<string>
  ): Promise<AxiosResponse<NewsFeedFilter>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling replaceNewsFeedFilter.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling replaceNewsFeedFilter.'
      );
    }

    if (newsId === null || newsId === undefined) {
      throw new Error(
        'Required parameter newsId was null or undefined when calling replaceNewsFeedFilter.'
      );
    }

    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling replaceNewsFeedFilter.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (mapLevel) {
      mapLevel.forEach((element) => {
        queryParameters.append('mapLevel', <any>element);
      });
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers['Content-Type'] = String(httpContentTypeSelected);
    }

    return axios.put<NewsFeedFilter>(
      `${this.basePath}/news/newsFeedFilters/${encodeURIComponent(
        String(newsId)
      )}/filters/${encodeURIComponent(String(filterId))}`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
