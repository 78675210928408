import React, { useContext, useEffect, useState } from 'react';
import TopBar from 'src/layout/DashboardLayout/TopBar';
import NavBar from './NavBar';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { ApiContext, LoginInformationsInterface } from 'src/context/ApiContext';
import ImplementHelper from 'src/helpers/ImplementHelper';
import { LoadingScreenContext } from 'src/context/LoadingScreenContext';
import { useSnackbar } from 'notistack';
import LogoutHelper from 'src/helpers/LogoutHelper';
import { getToken, setToken } from 'src/utils/token';
import { RouteLeavingGuardProvider } from 'src/context/RouteLeavingGuardContext';
import { useTranslation } from 'react-i18next';
import LoadingComponent from 'src/components/LoadingComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 64,
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.dark,
    },
  })
);

const DashboardLayout: React.FC = ({ children, ...rest }) => {
  const getOrgCode = () => {
    // @ts-ignore
    return rest.computedMatch.params.orgCode;
  };
  const { enqueueSnackbar } = useSnackbar();

  const apiContext = useContext(ApiContext);
  const { t, i18n } = useTranslation();

  const logout = (currentPath?: string) => {
    LogoutHelper.logout(getOrgCode(), currentPath);
  };

  useEffect(() => {
    if (sessionStorage.getItem('loginInfos')) {
      const loginInformations: LoginInformationsInterface = JSON.parse(
        sessionStorage.getItem('loginInfos')!
      ) as LoginInformationsInterface;
      if (loginInformations.orgCode === getOrgCode()) {
        apiContext.setLoginInfos(loginInformations);
      } else {
        logout();
      }
    } else {
      logout();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();
  const [isNavOpen, setNavOpen] = useState(false);
  const loadingScreenContext = useContext(LoadingScreenContext);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const myRequestInterceptor = axios.interceptors.request.use((request) => {
      if (
        request.method &&
        ['POST', 'PUT', 'PATCH', 'DELETE'].includes(
          request.method.toUpperCase()
        ) &&
        !request.headers['disableLoadingScreen']
      ) {
        loadingScreenContext.setLoading(true);
      }
      // Do something with request data
      return request;
    });

    const myResponseInterceptor = axios.interceptors.response.use(
      (response) => {
        loadingScreenContext.setLoading(false);

        if (response.headers['token']) {
          setToken(response.headers['token']);
        }
        return response;
      },
      (error) => {
        loadingScreenContext.setLoading(false);

        if (getToken() && getToken() !== null) {
          if (
            error?.response?.data &&
            ImplementHelper.isErrorType(error.response.data)
          ) {
            enqueueSnackbar(
              i18n.exists(`apiGc.errors.${error.response.data.code}`)
                ? t(`apiGc.errors.${error.response.data.code}`)
                : error.response.data.message || error.response.data.code,
              { variant: 'error' }
            );
          } else {
            enqueueSnackbar(String(error), { variant: 'error' });
          }
          if (error?.response?.status) {
            switch (error.response.status) {
              case 401:
                axios.interceptors.response.eject(myResponseInterceptor);
                logout(window.location.href.split(`/${getOrgCode()}/`)[1]);
                break;
              default:
                break;
            }
          }
        }

        // Do something with response error
        return Promise.reject(error);
      }
    );

    setLoading(false);
    return () => {
      axios.interceptors.request.eject(myRequestInterceptor);
      axios.interceptors.response.eject(myResponseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <TopBar onNavOpen={() => setNavOpen(!isNavOpen)} {...rest} />
      <NavBar onClose={() => setNavOpen(false)} open={isNavOpen} {...rest} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Grid container spacing={0}>
              <Grid item xs>
                <RouteLeavingGuardProvider>
                  <LoadingComponent loading={loading}>
                    {children}
                  </LoadingComponent>
                </RouteLeavingGuardProvider>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
