/**
 * gc-ws-backoffice
 * GC WS BackOffice API.
 *
 * OpenAPI spec version: 5.0.0-SNAPSHOT
 * Contact: acuvillier@sbeglobalservice.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Configuration } from 'src/api/api-gc/configuration';
import config from 'src/config';
import axios, { AxiosResponse } from 'axios';
import {
  HeaderRule,
  SearchCatalogProductsResponse,
} from 'src/api/api-gc/model';
import { ConnectorType, NodeType } from 'src/interfaces/Workflow';

const validateStatus = (status: number) => status >= 200 && status < 300;

export class APICustomService {
  public configuration = new Configuration();
  protected basePath = config.api.apiGc.URL;

  constructor(configuration?: Configuration | null) {
    if (configuration) {
      this.configuration = configuration;
      if (configuration.basePath != null) {
        this.basePath = configuration.basePath;
      }
    }
  }

  /**
   * Get catalogProducts
   *
   * @param token Token
   * @param orgCode Organization Code
   */
  public getCatalogProducts(
    token: string,
    orgCode: string
  ): Promise<AxiosResponse<SearchCatalogProductsResponse>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getCatalogProducts.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getCatalogProducts.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    return axios.get<SearchCatalogProductsResponse>(
      `${this.basePath}/custom/catalog/products`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * refresh headerRules
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param ruleType type
   * @param criteria
   */
  public refreshHeaderRules(
    token: string,
    orgCode: string,
    ruleType: string,
    criteria?: Record<string, any>
  ): Promise<AxiosResponse<HeaderRule[]>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling refreshHeaderRules.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling refreshHeaderRules.'
      );
    }

    if (ruleType === null || ruleType === undefined) {
      throw new Error(
        'Required parameter ruleType was null or undefined when calling refreshHeaderRules.'
      );
    }

    const queryParameters = new URLSearchParams();
    if (ruleType !== undefined && ruleType !== null) {
      queryParameters.append('ruleType', ruleType as any);
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<HeaderRule[]>(
      `${this.basePath}/custom/rules/refreshHeaders`,
      criteria,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * get workflow details
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param type Type
   * @param code Code
   */
  public getWorkflowDetails(
    token: string,
    orgCode: string,
    type: string,
    code: string
  ): Promise<
    AxiosResponse<{
      positions: Record<string, any>;
      points: Record<string, any[]>;
      transitionCodes: Record<string, any>;
    }>
  > {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getWorkflowNodesPositions.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getWorkflowNodesPositions.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling getWorkflowNodesPositions.'
      );
    }

    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling getWorkflowNodesPositions.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.get<{
      positions: Record<string, any>;
      points: Record<string, any[]>;
      transitionCodes: Record<string, any>;
    }>(
      `${this.basePath}/custom/workflows/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(code))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * save workflow nodes positions
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param type Type
   * @param code Code
   * @param positions Positions
   */
  public saveWorkflowNodesPositions(
    token: string,
    orgCode: string,
    type: string,
    code: string,
    positions?: Record<string, any>
  ): Promise<AxiosResponse<{ positions: Record<string, any> }>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling saveWorkflowNodesPositions.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling saveWorkflowNodesPositions.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling saveWorkflowNodesPositions.'
      );
    }

    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling saveWorkflowNodesPositions.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<{ positions: Record<string, any> }>(
      `${this.basePath}/custom/workflows/nodes/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(code))}`,
      positions,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * save workflow links points
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param type Type
   * @param code Code
   * @param points Points
   */
  public saveWorkflowLinksPoints(
    token: string,
    orgCode: string,
    type: string,
    code: string,
    points?: Record<string, any[]>
  ): Promise<AxiosResponse<{ points: Record<string, any[]> }>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling saveWorkflowLinksPoints.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling saveWorkflowLinksPoints.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling saveWorkflowLinksPoints.'
      );
    }

    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling saveWorkflowLinksPoints.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<{ points: Record<string, any[]> }>(
      `${this.basePath}/custom/workflows/links/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(code))}`,
      points,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * delete workflow node position
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param type Type
   * @param processCode Process code
   * @param code Code
   */
  public deleteWorkflowNodePosition(
    token: string,
    orgCode: string,
    type: string,
    processCode: string,
    code: string
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteWorkflowNodePosition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteWorkflowNodePosition.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling deleteWorkflowNodePosition.'
      );
    }

    if (processCode === null || processCode === undefined) {
      throw new Error(
        'Required parameter processCode was null or undefined when calling deleteWorkflowNodePosition.'
      );
    }

    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling deleteWorkflowNodePosition.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.delete<{ positions: Record<string, any> }>(
      `${this.basePath}/custom/workflows/nodes/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(processCode))}/${encodeURIComponent(
        code
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * delete workflow link points
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param type Type
   * @param processCode Process code
   * @param code Code
   */
  public deleteWorkflowLinkPoints(
    token: string,
    orgCode: string,
    type: string,
    processCode: string,
    code: string
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteWorkflowLinkPoints.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteWorkflowLinkPoints.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling deleteWorkflowLinkPoints.'
      );
    }

    if (processCode === null || processCode === undefined) {
      throw new Error(
        'Required parameter processCode was null or undefined when calling deleteWorkflowLinkPoints.'
      );
    }

    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling deleteWorkflowLinkPoints.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.delete<{ positions: Record<string, any> }>(
      `${this.basePath}/custom/workflows/links/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(processCode))}/${encodeURIComponent(
        code
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * save workflow transitionCode infos
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param type Type
   * @param processCode Process code
   * @param value Transition code infos
   */
  public saveWorkflowTransitionCodeInfos(
    token: string,
    orgCode: string,
    type: string,
    processCode: string,
    value: Record<string, any>
  ): Promise<AxiosResponse<{ transitionCodes: Record<string, any> }>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling saveWorkflowTransitionCodeInfos.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling saveWorkflowTransitionCodeInfos.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling saveWorkflowTransitionCodeInfos.'
      );
    }

    if (processCode === null || processCode === undefined) {
      throw new Error(
        'Required parameter processCode was null or undefined when calling saveWorkflowTransitionCodeInfos.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<{ transitionCodes: Record<string, any> }>(
      `${this.basePath}/custom/workflows/transitionCodes/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(processCode))}`,
      value,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * delete workflow transitionCode infos
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param type Type
   * @param processCode Process code
   * @param code Code
   */
  public deleteWorkflowTransitionCodeInfos(
    token: string,
    orgCode: string,
    type: string,
    processCode: string,
    code: string
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteWorkflowTransitionCodeInfos.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteWorkflowTransitionCodeInfos.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling deleteWorkflowTransitionCodeInfos.'
      );
    }

    if (processCode === null || processCode === undefined) {
      throw new Error(
        'Required parameter processCode was null or undefined when calling deleteWorkflowTransitionCodeInfos.'
      );
    }

    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling deleteWorkflowTransitionCodeInfos.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.delete(
      `${this.basePath}/custom/workflows/transitionCodes/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(processCode))}/${encodeURIComponent(
        code
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * get workflow diagrams
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param type Type
   * @param code Code
   */
  public getWorkflowDiagrams(
    token: string,
    orgCode: string,
    type: string,
    code: string
  ): Promise<
    AxiosResponse<{
      nodes: NodeType[];
      connectors: ConnectorType[];
    }>
  > {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling getWorkflowNodesPositions.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling getWorkflowNodesPositions.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling getWorkflowNodesPositions.'
      );
    }

    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling getWorkflowNodesPositions.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.get<{
      nodes: NodeType[];
      connectors: ConnectorType[];
    }>(
      `${this.basePath}/custom/workflows/diagrams/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(code))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * save workflow diagrams node
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param type Type
   * @param code Code
   * @param nodes Nodes
   * @param connectors Connectors
   */
  public saveWorkflowDiagrams(
    token: string,
    orgCode: string,
    type: string,
    code: string,
    nodes?: NodeType[],
    connectors?: ConnectorType[]
  ): Promise<AxiosResponse<{ positions: Record<string, any> }>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling saveWorkflowNodesPositions.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling saveWorkflowNodesPositions.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling saveWorkflowNodesPositions.'
      );
    }

    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling saveWorkflowNodesPositions.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<{ positions: Record<string, any> }>(
      `${this.basePath}/custom/workflows/diagrams/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(code))}`,
      { nodes: nodes, connectors: connectors },
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }

  /**
   * delete workflow diagrams
   *
   * @param token Token
   * @param orgCode Organization Code
   * @param type Type
   * @param processCode Process code
   * @param code Code
   */
  public deleteWorkflowDiagrams(
    token: string,
    orgCode: string,
    type: string,
    processCode: string,
    code: string,
    nodeIds?: string[],
    connectorIds?: string[]
  ): Promise<AxiosResponse<any>> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling deleteWorkflowNodePosition.'
      );
    }

    if (orgCode === null || orgCode === undefined) {
      throw new Error(
        'Required parameter orgCode was null or undefined when calling deleteWorkflowNodePosition.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling deleteWorkflowNodePosition.'
      );
    }

    if (processCode === null || processCode === undefined) {
      throw new Error(
        'Required parameter processCode was null or undefined when calling deleteWorkflowNodePosition.'
      );
    }

    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling deleteWorkflowNodePosition.'
      );
    }

    const headers = {} as any;
    if (token !== undefined && token !== null) {
      headers['authorization'] = 'Bearer ' + String(token);
    }
    if (orgCode !== undefined && orgCode !== null) {
      headers['org-code'] = String(orgCode);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers['Accept'] = String(httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    headers['Content-Type'] =
      this.configuration.selectHeaderContentType(['application/json']) || '';

    return axios.post<{ positions: Record<string, any> }>(
      `${this.basePath}/custom/workflows/diagrams/delete/${encodeURIComponent(
        String(type)
      )}/${encodeURIComponent(String(processCode))}/${encodeURIComponent(
        code
      )}`,
      { nodeIds: nodeIds, connectorIds: connectorIds },
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        validateStatus: validateStatus,
      }
    );
  }
}
