import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextFieldComponent } from 'src/components/UI';
import { ValidationStateSchema } from 'src/interfaces';
import ValidationStateSchemaHelper from 'src/helpers/ValidationStateSchemaHelper';
import { useConfirmation } from 'src/components/ConfirmDialog';

export interface OptionType {
  key: string | number;
  label: string;
}

interface ComboBoxInterface {
  label?: string;
  options: OptionType[];
  value?: string | number;
  onChange: (e: any, v: any) => void;
  className?: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
  required?: boolean;
  name?: string;
  validationSchema?: ValidationStateSchema;
  title?: string;
  showConfirmationPopin?: boolean;
  confirmationDialogTitle?: string;
  confirmationDialogDescription?: string;
}

/*const useStyles = makeStyles(() =>
  createStyles({
    clearIndicator: {
      '& button': {
        color: 'inherit',
      },
    },
    popupIndicator: {
      '& button': {
        color: 'inherit',
      },
    },
  })
);*/

export const ComboBoxComponent: React.FC<ComboBoxInterface> = ({
  label,
  options,
  value,
  onChange,
  className,
  error,
  helperText,
  disabled,
  size,
  required,
  name,
  validationSchema,
  title,
  showConfirmationPopin,
  confirmationDialogTitle,
  confirmationDialogDescription,
}) => {
  //const classes = useStyles();
  const [v, setV] = useState<number | string | null | undefined>(value);
  const confirm = useConfirmation();

  useEffect(() => {
    setV(value);
  }, [value]);

  const tryToChange = (e: any, va: any) => {
    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: confirmationDialogTitle,
      description: confirmationDialogDescription,
    }).then(() => {
      setV(va !== null ? va.key : null);
      onChange(e, va);
    });
  };

  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => (option ? option.label : '')}
      isOptionEqualToValue={(option: any, value: any) =>
        option.key === value.key
      }
      //title={value as string}
      value={options.find((option) => option.key == v) || null}
      renderInput={(params) => (
        <TextFieldComponent
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          // @ts-ignore
          title={
            title
              ? title
              : params.inputProps
              ? // @ts-ignore
                params.inputProps.value || null
              : null
          }
          //required={required}
          required={
            validationSchema && name
              ? ValidationStateSchemaHelper.isRequired(validationSchema, name)
              : required
          }
        />
      )}
      onChange={(e: React.ChangeEvent<{}>, va: OptionType | null) => {
        if (showConfirmationPopin) {
          tryToChange(e, va);
        } else {
          setV(va !== null ? va.key : null);
          onChange(e, va);
        }
      }}
      className={className}
      size={size}
    />
  );
};
